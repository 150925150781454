$theme-color: #e33a3a;
$theme-color-SVG: '%23e33a3a';
$form-label-color: #ff7f8c;
$form-label-float-color: #e33a3a;
$result-background-color: #ffd1d0;
$checkbox-color: #f34141;

@keyframes enter-resources-modal {
  0% {transform: scale(0);}
  100% {transform: scale(1);}
}

@keyframes exit-resources-modal {
  0% {transform: scale(1);}
  100% {transform: scale(0);}
}

@keyframes enter-resources-modal-background {
  0% {background-color: rgba(0,0,0,0);}
  100% {background-color: rgba(0,0,0,0.9);}
}

@keyframes exit-resources-modal-background {
  0% {background-color: rgba(0,0,0,.9);}
  100% {background-color: rgba(0,0,0,0);}
}

#resources-modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(0,0,0,0);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
  transform: translateZ(1000px); /* Necessary for Safari */
  transition: .4s;
  pointer-events: auto;
  
  #resources-modal-container {
    position: absolute;
    top: 0;
    width: 900px;
    max-width: calc(100% - 40px); 
    /* border: 20px solid #e33a3a; */
    border-radius: 50px;
    /* background: #ffeff2; */
    pointer-events: auto;
    transition: .4s;
    
    @media screen and (min-width: 1320px) {
      width: 1320px;
    }

    @media screen and (max-width: 912px) {
      left: 0;
      margin: 20px;
    }
  }
 
  &.show {
    /* background-color: rgba(0,0,0,.9); /* rgba(255, 189, 189, .8); */
    animation: enter-resources-modal-background  .4s both;

    #resources-modal-container {
      animation: enter-resources-modal 0.4s both;
    }
  }
   
  &.hide {
    /*background-color: rgba(0,0,0,0); */
    animation: exit-resources-modal-background 0.4s both;

    #resources-modal-container {
      animation: exit-resources-modal 0.4s both;
    }  
  }
}

#resources-modal-content {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 50px;
  width: 100%;  
  height: 100%;
  box-sizing: border-box;
  font-family: PoppinsMedium;
  white-space: pre-line;
  z-index: 1;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    bottom: -40px;
    height: 20px;
  }
  
  @media screen and (max-width: 700px) {
      padding: 30px 15px 150px 15px;
  }
}


#resources-modal-header {
  flex: none;
  align-self: center;
  color: #fcfcfc; /* #e33a3a; */
  font-family: ArticoExtraBold;
  font-size: 2.6rem;
  margin-bottom: 40px;
  text-shadow: 15px 15px 15px rgba(0, 0, 0, .6);
  pointer-events: auto;

  @media screen and (max-width: 600px) {
    margin-top: 100px;
  }
}


.resources-offices {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-right: -80px;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    margin-right: 0;
  }
  
  > div {
    flex: none;
    position: relative;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background: #e33a3a; /* #ff7f8c; *//* ca2a39 */
    color: #ffd1d0; /* #e1e1e1; /* #333;  */
    margin: 0 80px 80px 0;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, .6);
    pointer-events: auto;

    @media screen and (min-width: 1320px) {

      &:nth-of-type(5n+1) {
        margin-left: 80px;
      }

      &:nth-of-type(5n+2) {
        margin-right: 160px;
      }
    }

    @media screen and (max-width: 600px) {
      margin: 0 0 80px 0;
    }

    .office {
      position: absolute;
      width: 250px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
      
      .office-name {
        font-family: ArticoExtraBold;
        font-size: 1.2rem;
        font-weight: 400;
        color: #fff; /* rgba(0,0,0,.8); */
        line-height: 1.25;
        margin-bottom: .5rem;
      }

      .office-address {
        margin-bottom: .5rem;
      }

      .office-hours {
        margin-bottom: .5rem;
      }

      .office-address,
      .office-phone {

        a,
        a:visited,
        a:focus {
          color: #ffd1d0;
          text-decoration: none;
          transition: .2s;
        }

        a:hover {
          color: #fff;
        }  
      }

      .office-phone span {
        margin: 0 2px;
      }
    }
  }
}  

.things-to-do-heading {
  width: 100%;
  margin-top: -40px;
  margin-bottom: 20px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 120px;
  }

  @media screen and (max-width: 919px) {
    margin-top: 0;
  }

  display: flex;
  justify-content: center;

  > div {
    width: 180px;
    height: 180px;
    background: #ff4545;
    border-radius: 50%;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, .6);
    color: #fff;
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
    line-height: 1.2;
    text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    padding-top: 50px;
  }
}

.things-to-do {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: calc(100% + 80px);
  max-width: 900px;
  height: calc(100% + 80px);
  margin: 0 -80px 80px 0;
  pointer-events: none;
  
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    margin: 0 0 80px 0;
  }

  @media screen and (max-width: 460px) {
    transform: scale(0.9);
  }
}

.thing-to-do {
  position: relative;
  transition: 0.2s;
}

.thing-to-do-clip {
  width: 350px;
  height: 350px;
  margin: 0 80px 80px 0;
  pointer-events: auto;
  filter: drop-shadow(2.5px 2.5px 0px #1c1c1c) 
          drop-shadow(2.5px -2.5px 0px #1c1c1c) 
          drop-shadow(-2.5px 2.5px 0px #1c1c1c) 
          drop-shadow(-2.5px -2.5px 0px #1c1c1c) 
          drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.6));
  transition: 0.2s;
  
  @media screen and (max-width: 600px) {
    margin: 0 0 80px 0;
  }

  > div,
  a > div {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    > div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: inherit;
      background-position: inherit;
      background-repeat: no-repeat;
      opacity: 1;
      transition: 0.2s;
    }
  }


}

.thing-to-do.clickable {
  cursor: pointer;
}

.thing-to-do.clickable:hover {
  transform: scale(1.05);
  transition: 0.6s;


  .thing-to-do-clip {
    filter: drop-shadow(2.5px 2.5px 0px lighten(#1c1c1c, 5%))
            drop-shadow(2.5px -2.5px 0px lighten(#1c1c1c, 5%)) 
            drop-shadow(-2.5px 2.5px 0px lighten(#1c1c1c, 5%)) 
            drop-shadow(-2.5px -2.5px 0px lighten(#1c1c1c, 5%)) 
            drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.6));
  }

 /*
  svg textPath {
    fill: #ff4545;
    transition: 0.3s;
  }
 */
}

.thing-to-do:hover {
  .thing-to-do-clip {
    transition: 0.6s;
    
    > div > div,
    a > div > div {
      opacity: 0;
      transition: 0.6s;
    }
  }
}

.thing-to-do-name {
  position: absolute;
  left: 175px;
  top: 175px;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 500px;
  height: 500px;
  pointer-events: none;

  svg {
    width: 100% !important;
    height: 100% !important;
  }
}

div.thing-to-do:nth-of-type(1) .thing-to-do-name {
  transform: translate(-50%, -50%) rotate(-135deg);
}

div.thing-to-do:nth-of-type(3) {
  .thing-to-do-clip {
    margin-bottom: 0;
  }
  .thing-to-do-name {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
}

@media screen and (max-width: 1024px) {
  div.thing-to-do:nth-of-type(4) {
    margin-top: 80px;
  }
}

#resources-modal-wrapper {
  .icon-close-modal-top,
  .icon-close-modal-bottom {
    position: absolute;
    width: 50px;
    height: 50px;
    background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50px" height="50px" viewBox="0 0 100 100"> \
      <path fill="#{$theme-color-SVG}" d="M50,5L50,5C25.1,5,5,25.1,5,50l0,0c0,24.9,20.1,45,45,45h0c24.9,0,45-20.1,45-45l0,0C95,25.1,74.9,5,50,5z M72.9,65.2 \
        c2.1,2.1,2.1,5.5,0,7.6c-1.1,1.1-2.4,1.6-3.8,1.6c-1.4,0-2.8-0.5-3.8-1.6L50.3,57.8L35.2,72.8c-1.1,1.1-2.4,1.6-3.8,1.6 \
        c-1.4,0-2.8-0.5-3.8-1.6c-2.1-2.1-2.1-5.5,0-7.6l15.1-15.1L27.6,35.1c-2.1-2.1-2.1-5.5,0-7.6c2.1-2.1,5.5-2.1,7.6,0l15.1,15.1 \
        l15.1-15.1c2.1-2.1,5.5-2.1,7.6,0c2.1,2.1,2.1,5.5,0,7.6L57.9,50.2L72.9,65.2z"/> \
    </svg>') no-repeat;
    background-size: 100%;
    pointer-events: auto;
    filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.6));
    cursor: pointer;
  }

  .icon-close-modal-top {
    top: 30px;
    right: 30px;
  }

  .icon-close-modal-bottom {
    bottom: 30px;
    right: 30px;

    @media screen and (max-width: 700px) {
      bottom: 120px;
    }
  }
}
