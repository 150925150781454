.red-lion-head {
  position: absolute;
  left: 50%;
  width: 200px;
  height: 200px;
  transform: translateX(-50%);
  border-radius: 50%;
  /*
  .st0 = mane
  .st2 = face outline
  .st3 = face outline
  .st4 = ear
  .st7 = facial line
  .st8 = facial line
  .st9 = nose
  .st10 = circle
  */
  background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 544 544" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"> \
  <style type="text/css"> \
    .st0{fill:rgba(202,41,57,.6);stroke:rgba(202,41,57,1);stroke-width:2;stroke-miterlimit:10;} \
    .st1{fill:none;} \
    .st2{fill:none;stroke:%23CA2939;stroke-miterlimit:10;} \
    .st3{fill:none;stroke:%23CA2939;stroke-width:2;stroke-miterlimit:10;} \
    .st4{fill:none;stroke:%23CA2939;stroke-width:4;stroke-miterlimit:10;} \
    .st5{fill:%23CA2939;} \
    .st6{fill:%23E6E7E8;} \
    .st7{fill:%23CA2939;stroke:%23CA2939;stroke-miterlimit:10;} \
    .st8{fill:none;stroke:%23CA2939;stroke-width:3;stroke-miterlimit:10;} \
    .st9{fill:%23CA2939;stroke:%23CA2939;stroke-width:3;stroke-miterlimit:10;} \
    .st10{fill:none;stroke:%23CA2939;stroke-width:15;stroke-miterlimit:10;} \
    .st11{fill:rgba(202,41,57,.7);stroke:rgba(202,41,57,.7);} \
    .st12{fill:rgba(0,0,0,.6);stroke:rgba(0,0,0,.6);} \
    .st13{fill:rgba(202,41,57,1);stroke:rgba(202,41,57,1);} \
    .st14{fill:rgba(202,41,57,.4);stroke:rgba(202,41,57,.8);stroke-width:2;stroke-miterlimit:10;} \
  </style> \
  <path class="st0" d="M320.5,473c0.46,0.34,2.54,2.66,3,3c2.87-5.68,3.63-12.82,6.5-18.5c6-12,7-12,15-2  c12.4-0.69,1.93-27.46,18.11-12.19c4.59-11.48,33.52-44.2,33.58-56.53c0,0-62.33,17.22-83.63-11.48  c-26.69-35.87-26.8-38.91-49.36-55.1c-9.41-6.72-12.17-16.42,11.48-49.02c-15.44,4.42-31.51-6.31-31.51-13.55  c0-28.7-5.74-22.5,14.87-59.58c17.68-30.94,89.82-46.84,127.94,9.13c16.76-20.95,35.7-45.57,22.1-68.88  c-6.59,6.94-12.57,14.45-17.85,22.44c-1.79-20.15-6.39-39.95-13.66-58.83c-0.75,7.55-2.57,14.95-5.4,21.98  c-12.05-25.48-36.79-45.92-59.18-63.14c26.17,35.59,17.22,37.88,27.21,56.54c-37.93-25.09-82.71-37.81-128.17-36.39l32.49,12.8  c-32.14,1.09-61.07,18.83-88.28,36.04c7.2-2.84,15.39-1.25,21.01,4.08c-16.26,5.95-31.69,13.96-45.92,23.82  c-14.3,9.96-24.29,24.98-27.95,42.01c4.97-6.52,12.02-11.16,19.97-13.14c-14.53,27.15-26.79,55.44-36.68,84.6  c8.8-11.1,18.79-21.2,29.79-30.13c-2.7,36.85-37.25,66.92-35.13,103.83l16.53-23.47c0.67,27.25,6.51,54.13,17.22,79.21  c2.07,4.82,7.4,10.5,11.48,7.12c0.98-7.23,0.23-14.58-2.18-21.47c9.87,26.3,24,50.79,41.84,72.49c-3.42-11.23-5.77-22.76-7-34.44  c18.77,7.52,28.02,31.22,44.27,43.25c-0.98-6.32,0.66-8.87,1.82-9.98c5.03-4.84,19.21,1.66,35.68,13.48c0.34-0.57,1-2,2-5  c1-2,0.62-5.74,1-6c3-2,27.76,15.76,32,18c1.51-2.64,1.31-9.81,2.5-10.5c5.43-3.14,13.4-1.55,20,4c3.5,3.5,3.84,5.26,6.5,6.5  c0.26-0.85,4.45-12.29,9.27-12.6c0.38-0.02,0.79,0,1.23,0.1C316.67,468.17,318.83,470.33,320.5,473z"/> \
  <path class="st1" d="M405.76,338.56c4.36,21.81,0.86,35.76-5.22,42.76s-25.31,20.03-60.21,9.59s-35.84-30.35-48.33-43.4  c-5.14-5.38-30.13-25.47-35.3-34.19c-5.17-8.72,8.72-28.7,15.67-36.62c6.95-7.92-1.72-4.36-11.48-4.36s-15.73-7-19.17-20.09  c-3.44-13.09,6.08-33.12,6.08-33.12s12.34-32.89,38.05-43.45c25.71-10.56,53.21-2.41,58.49-1.03c9.59,2.53,40.18,21.7,40.18,29.79  c0,8.09-4.08,23.7-3.56,34.78"/> \
  <path class="st2" d="M364.5,394c-8.49-0.12-16.53,0.26-24.63-2.29c-27.29-13.01-22.79-14.47-40.37-31.71l-7-9  c-6.68-11.88-18.04-20.95-28.85-29.26c-3.14-2.15-5.85-4.88-7.98-8.04c-5.74-9.64,9.81-30.94,15.78-37.59  c2.41-2.76,2.3-3.44,2.3-3.5c-1.9-0.35-3.84-0.35-5.74,0c-2.18,0-4.71,0.46-7.29,0.46c-9.76,0-16.3-6.77-20.03-20.72  c-3.73-13.95,5.74-32.89,6.14-33.75c2.55-6.25,5.72-12.23,9.47-17.85c6.95-11.3,17.05-20.33,29.04-26  c11.48-4.71,30.71-8.72,58.26-1.26h0.8c9.36,2.41,40.52,21.7,40.52,30.59c-0.24,4.58-0.76,9.14-1.55,13.66  c-1.32,6.94-1.99,14-2.01,21.06c0.03,0.47-0.33,0.89-0.8,0.92c0,0,0,0,0,0c-0.44,0.06-0.85-0.24-0.91-0.68  c-0.01-0.04-0.01-0.08-0.01-0.12c0.01-7.18,0.68-14.35,2.01-21.41c0.81-4.44,1.33-8.92,1.55-13.43c0-3.16-6.14-9.41-16.01-16.3  c-7.05-5.22-14.78-9.46-22.96-12.63h-0.8c-27.15-7.29-45.92-3.39-57.4,1.21c-25.08,10.33-37.48,42.65-37.59,42.93  c-0.11,0.29-9.47,20.03-6.08,32.6c3.39,12.57,9.47,19.46,18.37,19.46c2.53,0,4.99-0.29,7.12-0.46c4.13-0.46,6.6-0.69,7.4,0.86  c0.8,1.55,0,2.7-2.58,5.4c-6.89,7.75-20.43,27.55-15.55,35.59c2.01,2.97,4.59,5.53,7.58,7.52c10.96,8.44,20.02,17.7,26.82,29.75l8,9  c12.74,21.06,9.89,21.69,40.83,30.99c4.13,1.21,40.98,11.48,59.23-9.36c6.94-7.92,8.78-23.25,5.05-42.01  c-0.1-0.43,0.17-0.86,0.61-0.96c0.03-0.01,0.06-0.01,0.08-0.01c0.39-0.13,0.81,0.08,0.94,0.47c0.02,0.05,0.03,0.1,0.03,0.16  c5.28,26.4-1.09,38.57-5.4,43.51C391.43,391.37,377.94,394.72,364.5,394z"/> \
  <path class="st3" d="M380.56,239.21c-0.52-11.08,3.56-26.75,3.56-34.78s-30.31-27.26-40.18-29.79c-5.28-1.38-32.77-9.59-58.49,1.03  c-25.71,10.62-38.05,43.45-38.05,43.45s-9.59,20.03-6.08,33.12c3.5,13.09,9.59,20.09,19.17,20.09l14.06-0.86"/> \
  <path class="st4" d="M226.51,194.61c0,0-39.72,21.64-49.36,20.43s-2.41,39.78,16.87,48.21c-2.11-8.69-3.32-17.58-3.62-26.52  l13.26,8.44l-9.64-19.28l12.05-4.82L226.51,194.61z"/> \
  <path class="st11" d="M340.5,179l-7.17,14.75c0,0-13.66-14.29-50.74,0C302.73,171.88,320.98,175.78,340.5,179z"/> \
  <path class="st11" d="M364.49,193.12l-2.93,11.48c0,0,10.79-5.74,20.38,10.79C380.96,203.68,381.59,202.99,364.49,193.12z"/> \
  <path class="st5" d="M316.16,197.54c4.84,6.83,5.71,15.71,2.3,23.36c-9.76-2.85-19.92-4.09-30.08-3.67  C288.38,217.22,290.97,197.25,316.16,197.54z"/> \
  <path class="st6" d="M314.73,220.38c0-7.17,0-21.98-8.32-21.29c-5.28,0.4-11.48,11.48-11.48,18.6"/> \
  <path class="st11" d="M315.59,220.38h-1.66c0-5.74,0-15.73-3.85-19.28c-0.97-0.88-2.26-1.29-3.56-1.15c-4.53,0.34-10.85,10.68-10.85,17.74h-1.72  c0-7.46,6.6-19,12.46-19.46c1.78-0.19,3.56,0.4,4.88,1.61C315.59,203.91,315.59,214.58,315.59,220.38z"/> \
  <path class="st5" d="M374.99,239.67l-16.59-10.73c0,0,0.98-14.92,6.77-17.22s11.19,6.49,11.19,6.49L374.99,239.67z"/> \
  <path class="st6" d="M370.92,210.97c0,0,5.74,3.16,0.4,25.66c-3.03-2.48-6.21-4.76-9.53-6.83  C361.79,229.79,362.88,209.76,370.92,210.97z"/> \
  <path class="st1" d="M354.91,240.07c0,0,55.39-12.11,82.42,24.51c7.69,17.91-14.41,63.54-31.85,73.98  c-6.08-6.08-8.55-25.66-19.92-27.38c-11.36-1.72-47.24,31.74-65.55,17.79c-13.02-10.55-22.94-24.44-28.7-40.18"/> \
  <path class="st7" d="M405.76,339.42c-0.2,0.08-0.43,0.08-0.63,0c-2.47-3.25-4.41-6.87-5.74-10.73c-3.16-7.23-6.72-15.38-13.43-16.42  c-3.85-0.57-11.19,3.44-19.69,8.09c-15.21,8.32-34.44,18.71-46.26,9.53c-13.15-10.65-23.18-24.65-29.04-40.52  c-0.15-0.44,0.08-0.93,0.52-1.09c0.45-0.15,0.94,0.1,1.08,0.55c0,0.01,0,0.01,0.01,0.02c5.85,15.54,15.76,29.24,28.7,39.66  c11.48,8.49,29.62-1.55,44.42-9.7c8.78-4.76,16.36-8.95,20.78-8.27c7.63,1.21,11.48,9.81,14.69,17.22c1.14,3.33,2.68,6.51,4.59,9.47  c17.22-10.96,38.17-55.33,30.99-72.49c-26.4-35.64-80.93-24.22-81.5-24.05c-0.48,0.1-0.94-0.21-1.03-0.69  c-0.1-0.48,0.21-0.94,0.69-1.03l0,0c0.52,0,56.25-11.82,83.28,24.85l0,0c3.56,8.32,1.15,23.47-6.54,40.46  c-7.17,16.01-17.22,29.56-25.66,34.44C406.04,338.99,405.95,339.25,405.76,339.42z"/> \
  <path class="st8" d="M274.84,271.46c0,0,9.47-5.74,19,21.29c15.04,42.99,40.64,49.82,74.61,25.48c4.08-2.93,11.88-9.53,18.94-7.63  c2.98,0.8,8.04,4.88,14.29,20.15c1.14,2.29,2.51,4.46,4.08,6.49c18.9-16.75,30.53-40.2,32.43-65.37c0-11.88-13.77,3.96-30.02,0.92  c-16.2-3.92-31.78-10.11-46.26-18.37c-15.88-10.46-35.69-13.04-53.72-7C295.06,252.19,283.51,260.51,274.84,271.46z"/> \
  <path class="st1" d="M295.39,298.21c0,0,10.68,65.72,43.22,72.32c17.91,3.56,22.96-11.02,31.05-12.86  c8.54-0.97,17.18-0.74,25.66,0.69c0,0-16.76-27.84-15.44-45.92C336.37,333.57,324.31,350.5,295.39,298.21z"/> \
  <path class="st1" d="M378.61,356.7c13.6,1.89-13.72-13.55-21.52-4.76c-17.22-4.65-23.65,16.82-23.65,16.82s12.17,4.36,18.25,1.26  c4.54-2.6,8.66-5.87,12.23-9.7C368.17,357.27,373.43,355.98,378.61,356.7z"/> \
  <path id="mouth" class="st0" d="M396,357.79c-0.17-0.29-16.59-27.84-15.32-45.57  c0.03-0.3-0.11-0.58-0.35-0.75c-0.27-0.14-0.59-0.14-0.86,0c-5.91,2.93-11.31,5.8-16.13,8.26c-31.05,16.42-42.64,22.5-67.27-21.92  c-0.18-0.39-0.63-0.57-1.03-0.4c-0.4,0.11-0.65,0.51-0.57,0.92c2.06,11.55,5.22,22.89,9.41,33.86c8.9,22.9,20.84,36.45,34.44,39.14  c1.89,0.41,3.81,0.62,5.74,0.63c7.02-0.35,13.63-3.46,18.36-8.66c2.02-2.21,4.54-3.88,7.35-4.88c8.45-0.97,16.98-0.76,25.37,0.63  c0.33,0.08,0.68-0.06,0.86-0.34C396.18,358.43,396.18,358.07,396,357.79z M383,356c-4.52-0.16-9.03,0.06-13.52,0.64  c-3.09,1.1-5.88,2.9-8.15,5.28c-2.51,2.89-5.63,5.07-9.07,6.44c-0.88,0.35-1.79,0.64-2.71,0.88c-3.46,0.91-7.15,1.03-10.77,0.26  c-2.16-0.43-4.22-1.14-6.18-2.08c-21.88-10.41-32.2-49.57-35.32-64.06c-0.02-0.03-0.04-0.07-0.06-0.1v-0.17  c0.02,0.09,0.04,0.18,0.06,0.27c23.72,40.7,36.81,33.87,66.92,17.75c4.42-2.35,9.42-4.93,14.75-7.57  c-0.34,15.89,11.31,37.76,14.76,43.5C390.16,356.46,386.58,356.11,383,356z"/> \
  <path class="st0" d="M393.71,357.04c-3.55-0.58-7.13-0.93-10.71-1.04c0.03-0.96-1.04-2.02-3.2-3.29  c-4.74-2.87-17.32-7.38-23.09-1.75c-15.59-3.76-22.74,12.81-24.11,16.46c-21.88-10.41-32.2-49.57-35.32-64.06  c23.72,40.7,36.81,33.87,66.92,17.75c4.42-2.35,9.42-4.93,14.75-7.57C378.61,329.43,390.26,351.3,393.71,357.04z"/> \
  <path id="tongue" class="st0" d="M379.8,352.71c-4.74-2.87-17.32-7.38-23.09-1.75  c-15.59-3.76-22.74,12.81-24.11,16.46c-0.17,0.45-0.25,0.7-0.25,0.7v0.74l0.81,0.28c4.17,1.39,8.52,2.19,12.93,2.36  c1.92,0.01,3.83-0.37,5.6-1.13c4.6-2.58,8.79-5.82,12.41-9.62c4.1-2.84,9.16-4.05,14.14-3.37c2.89,0.39,4.27,0.11,4.68-0.96  c0.06-0.14,0.08-0.28,0.08-0.42C383.03,355.04,381.96,353.98,379.8,352.71z M351.4,368.86c-0.61,0.15-1.23,0.27-1.85,0.38  c-5.04,0.87-10.23,0.38-15.01-1.45c1.39-3.66,8.14-19.08,22.57-15.08h0.52l0.35-0.4c2.94-3.15,8.83-2.47,12.12-1.74  c4.22,0.77,8.18,2.53,11.55,5.12c-0.44,0.07-0.89,0.1-1.33,0.1c-0.44,0-0.89-0.03-1.33-0.1c-5.49-0.76-11.07,0.61-15.53,3.82  c-3.28,3.45-7.06,6.43-11.2,8.85C351.98,368.53,351.69,368.7,351.4,368.86z"/> \
  <path class="st9" d="M351.17,254.42c0,0,8.72-8.72,27.03,0c18.31,8.72,27.03,16.53,39.2,16.53c6.14,0,12.28-6.49,15.32-7.58  c1.82-0.56,3.76,0.36,4.48,2.12c0,0,3.96,7.06-6.72,19.4c-9.47,11.02-13.09,13.09-31.4,8.72c-18.31-4.36-8.67-11.48-19.17-19.17  c-10.5-7.69-18.31-4.36-23.53-7.86C353.02,263.45,351.12,259.03,351.17,254.42z"/> \
  <path class="st11" d="M319.03,222.85h-0.4c-0.42-0.22-0.59-0.74-0.37-1.16c0.01-0.02,0.02-0.03,0.03-0.05c3.79-7.85,2.91-17.17-2.3-24.16  c-0.33-0.33-0.33-0.87,0-1.21c0.33-0.33,0.87-0.33,1.21,0c5.58,7.6,6.53,17.66,2.47,26.17  C319.52,222.67,319.29,222.81,319.03,222.85z"/> \
  <path class="st11" d="M287.81,218.26L287.81,218.26c-0.44-0.03-0.78-0.41-0.75-0.85c0-0.02,0-0.05,0.01-0.07c0.68-5.48,3.3-10.53,7.4-14.23  c11.48-9.93,28.7-6.83,29.39-6.72c0.47,0.08,0.78,0.53,0.7,1c0,0.01,0,0.02-0.01,0.03c-0.09,0.45-0.52,0.76-0.98,0.69  c0,0-17.22-3.1-27.95,6.26c-3.73,3.46-6.15,8.1-6.83,13.14C288.7,217.97,288.28,218.3,287.81,218.26z"/> \
  <ellipse class="st12" transform="matrix(0.1711 -0.9853 0.9853 0.1711 46.925 483.003)" cx="310.51" cy="213.61" rx="6.89" ry="4.53"/> \
  <ellipse class="st12" transform="matrix(0.1711 -0.9853 0.9853 0.1711 80.707 554.0778)" cx="369.64" cy="229.08" rx="5.74" ry="3.79"/> \
  <path class="st11" d="M371.61,239.26L371.61,239.26c-0.44-0.07-0.73-0.49-0.66-0.93c0.01-0.04,0.01-0.07,0.03-0.11  c4.25-17.62,2.41-23.59,0.92-25.54c-0.31-0.5-0.81-0.85-1.38-0.98c0,0-7.75,0-8.44,18.88c-0.03,0.45-0.41,0.8-0.86,0.8l0,0  c-0.44,0-0.81-0.35-0.81-0.8c0-0.04,0-0.08,0.01-0.12c0.4-11.48,3.33-16.36,5.74-18.54c1.21-1.21,2.83-1.91,4.53-1.95  c1.09,0.13,2.06,0.74,2.64,1.66c2.01,2.7,3.56,9.59-0.63,26.98c-0.11,0.46-0.57,0.75-1.03,0.65  C371.65,239.28,371.63,239.27,371.61,239.26z"/> \
  <path class="st11" d="M371.32,239.67c-0.29,0.01-0.57-0.12-0.75-0.34c-4.06-4.47-9.11-7.93-14.75-10.1c-0.44-0.18-0.65-0.68-0.47-1.12  c0-0.01,0.01-0.02,0.01-0.02c0.18-0.42,0.66-0.62,1.09-0.46c5.97,2.28,11.31,5.96,15.55,10.73c0.33,0.33,0.33,0.87,0,1.21  L371.32,239.67z"/> \
  <path class="st11" d="M374.65,239.21L374.65,239.21c-0.46-0.11-0.74-0.57-0.63-1.03c3.21-13.55,1.95-19.46-0.98-24.11  c-2.24-3.12-5.42-5.46-9.07-6.66c-0.46-0.14-0.72-0.61-0.58-1.07c0-0.01,0-0.01,0.01-0.02c0.1-0.43,0.54-0.7,0.97-0.59  c0.02,0.01,0.04,0.01,0.06,0.02c4.09,1.29,7.64,3.89,10.1,7.4c3.16,5.05,4.53,11.48,1.15,25.43c-0.07,0.44-0.49,0.73-0.93,0.66  C374.72,239.23,374.68,239.22,374.65,239.21z"/> \
  <circle class="st13" cx="326.32" cy="302.92" r="2.47"/> \
  <path class="st0" d="M315.99,304.52c-13.94,3.29-27.61,7.58-40.92,12.86c-11.3,5.25-21.66,12.33-30.65,20.95  c-5.3,4.97-10.25,10.3-14.81,15.96c-0.92,1.03,0.63,2.58,1.49,1.49c7.84-9.79,16.91-18.52,26.98-26  c10.57-7.42,22.34-12.97,34.78-16.42l23.7-6.77c0.54-0.26,0.77-0.92,0.5-1.46C316.87,304.73,316.44,304.48,315.99,304.52  L315.99,304.52z"/> \
  <path class="st11" d="M357.78,228.99L357.78,228.99c-0.46-0.06-0.81-0.45-0.8-0.92c1.09-15.32,10.85-19.17,11.48-19.34  c0.44-0.12,0.91,0.1,1.09,0.52c0.16,0.46-0.07,0.96-0.52,1.15c-0.34,0-9.07,3.62-10.1,17.79c0,0.48-0.39,0.86-0.87,0.85  C357.96,229.04,357.86,229.02,357.78,228.99z"/> \
  <path class="st11" d="M326.21,226.06h-0.46c-18.37-10.16-42.76-6.49-43.05-6.43c-0.48,0-0.86-0.38-0.87-0.85c0-0.48,0.38-0.86,0.85-0.87  c0.01,0,0.01,0,0.02,0c0,0,25.2-3.85,44.14,6.6c0.41,0.25,0.56,0.78,0.34,1.21C326.97,226.04,326.57,226.18,326.21,226.06z"/> \
  <circle class="st13" cx="314.33" cy="287.53" r="2.47"/> \
  <circle class="st13" cx="325.98" cy="273.3" r="2.47"/> \
  <path class="st0" d="M319.66,269.11c-11.72-7.01-23.85-13.3-36.33-18.83c-12.05-4.52-24.78-6.96-37.65-7.23  c-7.51-0.19-15.03,0.13-22.5,0.98c-1.38,0-1.38,2.3,0,2.18c16.35-2.12,32.94-1.21,48.96,2.7c16.64,4.42,31.45,13.89,46.43,22.04  C319.78,271.64,320.87,269.74,319.66,269.11z"/> \
  <path class="st11" d="M282.59,289.89c-3.3,0.01-6.59-0.34-9.81-1.03c-0.46-0.11-0.76-0.56-0.69-1.03c0.11-0.46,0.57-0.74,1.03-0.63  c0,0,22.96,4.88,26-8.09c0.06-0.47,0.49-0.8,0.97-0.74c0.47,0.06,0.8,0.5,0.74,0.97c-0.01,0.06-0.02,0.12-0.04,0.18  C298.66,288.05,289.76,289.89,282.59,289.89z"/> \
  <path class="st0" d="M301.81,285.58c-13.53-2.66-27.22-4.45-40.98-5.34c-13.04-0.14-26.02,1.98-38.34,6.26  c-6.92,2.33-13.67,5.13-20.2,8.38c-1.26,0.63,0,2.47,1.09,1.89c14.62-7.54,30.53-12.29,46.89-14c17.22-1.49,34.15,2.3,50.97,4.88  C302.56,287.65,303.19,285.81,301.81,285.58L301.81,285.58z"/> \
  <circle class="st13" cx="406.96" cy="322.03" r="2.47"/> \
  <circle class="st13" cx="406.62" cy="306.3" r="2.47"/> \
  <circle class="st13" cx="425.9" cy="297.52" r="2.47"/> \
  <path class="st0" d="M412.24,316.52c7.68,10.52,12.62,22.79,14.35,35.7c0.89,9.78,0.14,19.63-2.24,29.16  c-1.03,4.59-2.3,9.13-3.39,13.72c-0.05,0.6,0.39,1.13,0.99,1.18c0.45,0.04,0.88-0.2,1.07-0.61c2.46-8.65,4.37-17.45,5.74-26.34  c1.35-11.05,0.15-22.26-3.5-32.77c-2.69-7.52-6.42-14.63-11.08-21.12c-0.8-1.15-2.64,0-1.84,1.09H412.24z"/> \
  <path class="st0" d="M421.94,304.41c13.05,4.82,24.34,13.49,32.37,24.85c3.31,4.83,5.83,10.16,7.46,15.78  c0.26,0.54,0.92,0.77,1.46,0.5c0.41-0.2,0.65-0.63,0.61-1.08c-5.58-17.23-18.05-31.38-34.44-39.09c-2.23-1.15-4.53-2.17-6.89-3.04  C421.2,301.88,420.62,303.95,421.94,304.41L421.94,304.41z"/> \
  <path class="st0" d="M430.44,292.7c11.13-3.4,23.1-2.69,33.75,2.01c8.96,4.39,16.96,10.51,23.53,18.02c3.44,3.62,6.6,7.52,9.7,11.48  c0.86,1.09,2.41-0.46,1.55-1.55c-12.46-15.61-27.49-31.45-48.38-33.92c-6.97-0.71-14.01-0.04-20.72,1.95  c-0.54,0.26-0.77,0.92-0.5,1.46c0.2,0.41,0.63,0.65,1.08,0.61L430.44,292.7z"/> \
  <circle class="st10" cx="272.5" cy="273" r="255"/> \
  </svg>') no-repeat;
  background-size: 100%;
}
