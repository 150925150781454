$theme-color: #e33a3a;
$theme-color-SVG: '%23e33a3a';
$form-label-color: #ff7f8c;
$form-label-float-color: #e33a3a;
$result-background-color: #ffd1d0;
$checkbox-color: #f34141;

@font-face { 
	font-family: TopSecretStamp; 
	src: url(../../fonts/top-secret-stamp.ttf);
} 

@font-face { 
	font-family: ArialRoundedBold; 
	src: url(../../fonts/arlrdbd.ttf);
} 


#directory-modal-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 640px;
  padding-bottom: 60px;

  > div {
    width: 100%;
    height: 100%;

    > div {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes enter-photo {
    0% {
      right: calc(-80vw - 260px);
      transform: rotateZ(90deg);
    }
    100% {
      right: 0;
      transform: rotateZ(0deg);
    }
  }
  
  @keyframes exit-photo {
    0% {
      left: 0;
      transform: rotateZ(0deg);
    }
    100% {
      left: calc(-80vw - 260px);
      transform: rotateZ(90deg);
    }
  }

  @keyframes enter-letter {
    0% {
      left: calc(-80vw - 260px);
      transform: rotateZ(90deg);
    }
    100% {
      left: 0;
      transform: rotateZ(0deg);
    }
  }
  
  @keyframes exit-letter {
    0% {
      right: 0;
      transform: rotateZ(0deg);
    }
    100% {
      right: calc(-80vw - 260px);
      transform: rotateZ(90deg);
    }
  }

  .slide-exit {
    position: absolute;
    z-index: 1;
  }

  .slide-enter {
    position: absolute;
    z-index: 2;
  }

  .slide-enter .photo-wrap {
    /* transform: translate3d(100vw, 0, 0); */
    animation: enter-photo .5s .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .slide-enter .search-letter,
  .slide-enter .search-all {
    /* transform: translate3d(100vw, 0, 0); */
    animation: enter-letter .5s .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .slide-exit .photo-wrap {
     /* transform: translate3d(-100vw, 0, 0); */
    animation: exit-photo .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .slide-exit .search-letter,
  .slide-exit .search-all {
     /* transform: translate3d(-100vw, 0, 0); */
    animation: exit-letter .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }


  .slide-enter.slide-enter-active .photo-wrap,
  .slide-enter.slide-enter-active .search-letter {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes enter-directory-modal {
  0% {transform: scale(0);}
  100% {transform: scale(1);}
}

@keyframes exit-directory-modal {
  0% {transform: scale(1);}
  100% {transform: scale(0);}
}

@keyframes enter-directory-modal-background {
  0% {background-color: rgba(0,0,0,0);}
  100% {background-color: rgba(0,0,0,0.9);}
}

@keyframes exit-directory-modal-background {
  0% {background-color: rgba(0,0,0,.9);}
  100% {background-color: rgba(0,0,0,0);}
}

#directory-modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(0,0,0,0);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
  transform: translateZ(1000px); /* Necessary for Safari */
  transition: .4s;
  pointer-events: auto;
  
  #directory-modal-container {
    position: absolute;
    top: 0;
    width: 900px;
    max-width: calc(100% - 40px); 
    /* border: 20px solid #e33a3a; */
    border-radius: 50px;
    /* background: #ffeff2; */
    pointer-events: auto;
    transition: .4s;
    
    @media screen and (min-width: 1320px) {
      width: 1320px;
    }

    @media screen and (max-width: 912px) {
      left: 0;
      margin: 20px;
    }
  }
 
  &.show {
    /* background-color: rgba(0,0,0,.9); /* rgba(255, 189, 189, .8); */
    animation: enter-directory-modal-background  .4s both;

    #directory-modal-container {
      animation: enter-directory-modal 0.4s both;
    }
  }
   
  &.hide {
    /*background-color: rgba(0,0,0,0); */
    animation: exit-directory-modal-background 0.4s both;

    #directory-modal-container {
      animation: exit-directory-modal 0.4s both;
    }  
  }
}

#directory-modal-content {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 50px;
  width: 100%;  
  height: 100%;
  box-sizing: border-box;
  font-family: PoppinsMedium;
  white-space: pre-line;
  z-index: 1;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    bottom: -40px;
    height: 20px;
  }
  
  @media screen and (max-width: 700px) {
      padding: 30px 15px 150px 15px;
  }
}


#directory-modal-header {
  flex: none;
  align-self: center;
  text-align: center;
  color: #fcfcfc; /* #e33a3a; */
  font-family: ArticoExtraBold;
  font-size: 2.6rem;
  margin-bottom: 40px;
  text-shadow: 15px 15px 15px rgba(0, 0, 0, .6);
  pointer-events: auto;

  @media screen and (max-width: 600px) {
    margin-top: 100px;
    font-size: 1.8rem;
  }
}

.directory-modal-search-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  animation: enter-page-heading 0.8s both;

  .directory-modal-search-container {
    width: 432px;
    max-width: 85vw;
    display: flex;
    flex-flow: row nowrap;
    background-color: #ca2a39;
    padding: 15px 20px;
    border-style: outset;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.9);
     

    .search-field {
      width: 80%;
      background-color: #fff;
      border: 3px solid #c6c6c6;
      border-style: inset;
      outline: none;
      padding: 10px 20px;
      border-radius: 50px;
      font-size: 1.4rem;
      color: #333;      

      @media screen and (max-width: 600px) {
        font-size: 1.1rem;
      }
    }
    
    .search-icon {
      width: 60px;
      height: 60px;
      margin: 4px 0 0 20px;
      background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 125"> \
        <path fill="%23fff" d="M71.5,12.3c-16.3-16.3-42.9-16.3-59.2,0c-16.3,16.3-16.3,42.9,0,59.2c14.6,14.6,37.3,16.2,53.6,4.7l21.8,21.8 c2.8,2.8,7.4,2.8,10.2,0s2.8-7.4,0-10.2L76.1,65.9C87.6,49.6,86.1,26.9,71.5,12.3z M62.1,62.1c-11.2,11.2-29.3,11.2-40.4,0  c-11.2-11.2-11.2-29.3,0-40.4s29.3-11.2,40.4,0C73.2,32.8,73.2,50.9,62.1,62.1z"/> \
      </svg>') no-repeat;
      filter: drop-shadow(5px 5px 10px rgba(0,0,0,0.4));
    }
  }
}

.directory-profiles-wrapper,
.search-alphabet-wrapper {
  position: relative;
  width: 100vw;
  transition: .3s;
}

.directory-profiles-container,
.search-alphabet-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.search-alphabet-container {
  display: flex;
  justify-content: center;
}

.directory-profiles {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 60px 0 40px;
  transition: 1s;
  /*
  margin-right: -80px;

  @media screen and (max-width: 600px) {
    margin-right: 0;
  } 
  */
  
  > div {
    flex: none;
    position: relative;
    display: flex;
    justify-content: center;
    width: 300px;
    height: 400px;
    
    @media screen and (min-width: 1025px) and (max-width: 1400px) {
      height: 350px;
    }

    @media screen and (min-width: 601px) and (max-width: 1024px) {
      height: 330px;
    }

    @media screen and (max-width: 600px) {
      height: 270px;
    }

     /*
    border-radius: 50%;
    background: #e33a3a; /* #ff7f8c; *//* ca2a39 *//*
    color: #ffd1d0; /* #e1e1e1; /* #333;  *//*
    /* 
    margin: 0 80px 80px 0;
    *//*
    margin: 36px 25px 36px 25px;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, .6), 
                5px 5px 15px rgba(255, 255, 255, .4);
    pointer-events: auto;
  */
    /*
    @media screen and (min-width: 1320px) {

      &:nth-of-type(5n+1) {
        margin-left: 80px;
      }

      &:nth-of-type(5n+2) {
        margin-right: 160px;
      }
    }

    @media screen and (max-width: 600px) {
      margin: 0 0 80px 0;
    }
    */


    .photo {
      top: initial;
      width: 40vw !important;
      left: initial !important;
      margin-left: initial !important;
      min-width: 200px !important;
      max-width: 260px !important;
/*
      @media screen and (min-width: 800px) and (max-width: 1024px) {
        width: 22vw;
      }
    
      @media screen and (min-width: 601px) and (max-width: 1024px) {
      }

      @media screen and (max-width: 600px) {
        width: 13vw;
        min-width: 200px;
        max-width: 260px;
      }
*/
    }


    .photo-wrap {
      transform: initial;
      right: initial;
      transition: .2s;

      &:hover {
        box-shadow: 10px 10px 25px rgba(202, 42, 57, 40%), 
                    10px -10px 25px rgba(202, 42, 57, 40%),
                    -10px -10px 25px rgba(202, 42, 57, 60%),
                    -10px 10px 25px rgba(202, 42, 57, 60%);
        
      }
    }

    .profile {
      position: absolute;
      width: 180px;
      left: 50%;
      top: 60px;
      transform: translateX(-50%);
      text-align: center;
      text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);

      .profile-image {
        height: 180px;
        width: 180px;
        border-radius: 50%;
        background-size: 100%;
        background-repeat: none;
        box-shadow: 5px 5px 15px rgba(0,0,0,.3);
      }

      .profile-name {
        font-family: ArticoExtraBold;
        font-size: 1.2rem;
        font-weight: 400;
        color: #fff; /* rgba(0,0,0,.8); */
        line-height: 1.25;
        margin-bottom: .5rem;

        a,
        a:visited,
        a:focus {
          color: #ffd1d0;
          text-decoration: none;
          transition: .2s;
        }

        a:hover {
          color: #fff;
        } 
      }

      .profile-title {
        margin-bottom: .5rem;
      }

      .profile-circle-name {
        position: absolute;
        left: 90px;
        top: 70px;
        transform: translate(-50%, -50%) rotate(90deg);
        width: 320px;
        height: 320px;
        pointer-events: none;
      
        svg {
          width: 100% !important;
          height: 100% !important;

          text {
            font-family: ArticoExtraBold;
          }
        }
      }

      
      .profile-circle-title {
        position: absolute;
        left: 100px;
        top: 100px;
        transform: translate(-50%, -50%) rotate(90deg);
        width: 320px;
        height: 320px;
        pointer-events: none;
      
        svg {
          width: 100% !important;
          height: 100% !important;

          text {
            font-family: Effra;
            color: #ffd1d0;
          }
        }
      }
      
      &.one {

        .profile-circle-name {
          transform: translate(-50%, -50%) rotate(-90deg);
          left: 90px;
          top: 96px;
        }

        .profile-circle-title {
          left: 90px;
          top: 100px;
        }
      }

      &.two {
        top: 20px;

        .profile-circle-name {
          transform: translate(-50%, -50%) rotate(90deg);
          left: 90px;
          top: 96px;
        }

        .profile-circle-title {
          left: 90px;
          top: 130px;
        }
      }

      &.three {
        top: 96px;

        .profile-circle-name {
          transform: translate(-50%, -50%) rotate(-90deg);
          left: 90px;
          top: 60px;
        }

        .profile-circle-title {
          transform: translate(-50%, -50%) rotate(-90deg);
          left: 90px;
          top: 80px;
          width: 275px;
          height: 275px;
        }
      }
    }
  }
}  

.search-alphabet {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 90vw;
  width: 1000px !important;
}

.search-all {
  position: relative;
}

.search-letter {
  position: relative;
  width: 90px;

  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    width: 80px;
  }

  @media screen and (min-width: 800px) and (max-width: 1024px) {
    width: 70px;
  }

  @media screen and (max-width: 800px) {
    width: 40px;
  }
}

.search-all {
  width: 120px;

  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    width: 110px;
  }

  @media screen and (min-width: 800px) and (max-width: 1024px) {
    width: 100px;
  }

  @media screen and (max-width: 800px) {
    width: 60px;
  }
}

.search-letter,
.search-all {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 90px;
  margin: 10px;
  border-radius: 30px;
  background: lighten(#ca2a39, 5%); /* #db596c; */
  color: #fff;
  font-family: ArialRoundedBold;
  font-size: 3.4rem;
  font-weight: 700;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
  border: 3px outset;
  cursor: pointer;
  transition: .3s;

  &.selected {
    cursor: initial;
    user-select: none;
  }

  &.selected,
  &:hover {
    background: darken(#ca2a39, 1.5%);
    box-shadow: 10px 10px 25px rgba(202, 42, 57, 40%), 
                10px -10px 25px rgba(202, 42, 57, 40%),
                -10px -10px 25px rgba(202, 42, 57, 60%),
                -10px 10px 25px rgba(202, 42, 57, 60%);
  }

  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    height: 80px;
    border-radius: 26px;
    font-size: 3rem;
  }

  @media screen and (min-width: 800px) and (max-width: 1024px) {
    height: 70px;
    border-radius: 23px;
    font-size: 2.6rem;
  }

  @media screen and (max-width: 800px) {
    height: 40px;
    border-radius: 17px;
    font-size: 1.4rem;
  }
}

.directory-letter {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 6px;
  left: 24px;
  width: 110px;
  height: 110px;
  /* 
  background: $theme-color;
  */
  /* 
  border: 10px solid $theme-color; 
  border-radius: 50%;
  */
  background: transparent url(../../images/rubber-stamp-circle-2-red.png) no-repeat;
  background-size: 100%;
  border-right: none;
  border-bottom: none;
 
  z-index: 4;

  > div {
    color: $theme-color;
    font-family: TopSecretStamp;
    font-size: 5rem;
    font-weight: 700;
 
  }
}

.thing-to-do {
  position: relative;
  transition: 0.2s;
}

.thing-to-do-clip {
  width: 350px;
  height: 350px;
  margin: 0 80px 80px 0;
  pointer-events: auto;
  filter: drop-shadow(2.5px 2.5px 0px #1c1c1c) 
          drop-shadow(2.5px -2.5px 0px #1c1c1c) 
          drop-shadow(-2.5px 2.5px 0px #1c1c1c) 
          drop-shadow(-2.5px -2.5px 0px #1c1c1c) 
          drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.6));
  transition: 0.2s;
  
  @media screen and (max-width: 600px) {
    margin: 0 0 80px 0;
  }

  > div,
  a > div {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    > div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: inherit;
      background-position: inherit;
      background-repeat: no-repeat;
      opacity: 1;
      transition: 0.2s;
    }
  }


}

.thing-to-do.clickable {
  cursor: pointer;
}

.thing-to-do.clickable:hover {
  transform: scale(1.05);
  transition: 0.6s;


  .thing-to-do-clip {
    filter: drop-shadow(2.5px 2.5px 0px lighten(#1c1c1c, 5%))
            drop-shadow(2.5px -2.5px 0px lighten(#1c1c1c, 5%)) 
            drop-shadow(-2.5px 2.5px 0px lighten(#1c1c1c, 5%)) 
            drop-shadow(-2.5px -2.5px 0px lighten(#1c1c1c, 5%)) 
            drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.6));
  }
}

#directory-modal-wrapper {
  .icon-close-modal-top,
  .icon-close-modal-bottom {
    position: absolute;
    width: 50px;
    height: 50px;
    background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50px" height="50px" viewBox="0 0 100 100"> \
      <path fill="#{$theme-color-SVG}" d="M50,5L50,5C25.1,5,5,25.1,5,50l0,0c0,24.9,20.1,45,45,45h0c24.9,0,45-20.1,45-45l0,0C95,25.1,74.9,5,50,5z M72.9,65.2 \
        c2.1,2.1,2.1,5.5,0,7.6c-1.1,1.1-2.4,1.6-3.8,1.6c-1.4,0-2.8-0.5-3.8-1.6L50.3,57.8L35.2,72.8c-1.1,1.1-2.4,1.6-3.8,1.6 \
        c-1.4,0-2.8-0.5-3.8-1.6c-2.1-2.1-2.1-5.5,0-7.6l15.1-15.1L27.6,35.1c-2.1-2.1-2.1-5.5,0-7.6c2.1-2.1,5.5-2.1,7.6,0l15.1,15.1 \
        l15.1-15.1c2.1-2.1,5.5-2.1,7.6,0c2.1,2.1,2.1,5.5,0,7.6L57.9,50.2L72.9,65.2z"/> \
    </svg>') no-repeat;
    background-size: 100%;
    pointer-events: auto;
    filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.6));
    cursor: pointer;
  }

  .icon-close-modal-top {
    top: 30px;
    right: 30px;
  }

  .icon-close-modal-bottom {
    bottom: 30px;
    right: 30px;

    @media screen and (max-width: 700px) {
      bottom: 120px;
    }
  }
}

