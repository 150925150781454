@use "sass:math";

$reviews-enter-duration: 1s;
$reviewers-speak-duration: 2s;
$reviewers-exit-duration: 1s;

.bind-animation {
	/* Pause animations */
	animation-play-state: paused;
	animation-duration: 1s;
	/* Bind the animation to scroll */
	/*  animation-delay: calc(var(--scroll) * -1s); */
	/* These last 2 properites clean up overshoot weirdness */
	animation-iteration-count: 1;
	animation-fill-mode: both;
}


.caregivers-container {

  li::marker {
    font-size: 2.4rem;
    margin-top: 4px;
    color: #CF0525;

    @media screen and (max-width: 600px) {
      font-size: 6.4vw;
    }
  }

  @keyframes enter-caregivers-page-heading {
    0% {transform: scale(2) translateY(-200px);}  
    100% {transform: scale(1) translateY(0);}  
  }

  .page-heading,
  .section-heading {
    position: relative;
    font-family: ArticoExtraBold;
    color: #ca2a39;
    text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    z-index: 99;
  }

  .page-heading {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    animation: enter-caregivers-page-heading 0.8s both;

    @media screen and (max-width: 1024px) {
      margin-top: 50px;
    }
  }


  @keyframes enter-caregivers-content-copy {
    0% {transform: translateX(100px); opacity: 0;}
    100% {transform: translateX(0px); opacity: 1;}
  }

  @keyframes enter-caregivers-content-copy-column {
    0% {transform: translateY(100px); opacity: 0;}
    100% {transform: translateY(0px); opacity: 1;}
  }

  @keyframes enter-patients-content-copy {
    0% {transform: translateX(-100px); opacity: 0;}
    100% {transform: translateX(0px); opacity: 1;}
  }

  @keyframes enter-patients-content-copy-column {
    0% {transform: translateY(100px); opacity: 0;}
    100% {transform: translateY(0px); opacity: 1;}
  }

  .caregivers-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    padding: 0 20px 0 80px;
    margin-top: 60px;

    @media screen and (max-width: 1150px) {
      flex-flow: column nowrap;
    }

    @media screen and (max-width: 600px) {
      padding: 0 20px 0 20px;
    }

    > div {
      position: relative;
      flex: 1;
      flex-basis: 40%;
    }

    .caregivers-copy {
      padding-right: 40px;

      @media screen and (max-width: 600px) {
        padding-right: 0;
      }

      @media screen and (min-width: 1151px) {
        > div {
          max-width: 700px;
        }
      }

      transform: translateX(100px); 
      opacity: 0;

      &.enter {
        animation: enter-caregivers-content-copy 1s both;

        @media screen and (max-width: 1151px) {
          animation: enter-caregivers-content-copy-column 1s .25s both;
        }    
      }   

    }
  }

  .section {
    min-width: 300px;
    margin-right: 20px;
    margin-bottom: 40px;
    z-index: 2;

    @media screen and (max-width: 600px) {
      margin-right: 0;
    }
  }

  .section-heading {
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 32px;

    @media screen and (max-width: 600px) {
      font-size: 1.2rem;
    }
  }
  
  .section-copy,
  .bullet-points,
  .reviews-copy {
    font-family: Effra;
    font-size: 1.5rem;
    text-shadow: 5px 5px 5px rgba(71, 43, 43, 0.15);
    
    @media screen and (max-width: 600px) {
      font-size: 4vw;
    }
    
    li {
      position: relative;
      margin-bottom: 16px;
    }

    span {
      display: block;
      position: relative;
      top: -4px;

      @media screen and (max-width: 600px) {
        top: -2px;
      }
    }
  }


  .patients-copy {
    display: flex;
    justify-content: flex-end;
    margin-right: 40px;
    
    @media screen and (max-width: 1150px) {
      margin-right: 80px;
    }

    @media screen and (max-width: 600px) {
      margin-right: 20px;
    }

    @media screen and (min-width: 1151px) {
      > div {
        max-width: 700px;
      }
    }

    transform: translateX(-100px); 
    opacity: 0;
    
    &.enter {
      animation: enter-patients-content-copy 1s 1s both;

      @media screen and (max-width: 1151px) {
        animation: enter-patients-content-copy-column 1s .25s both;
      }
    }   

    .bullet-points {
      width: 90%;
    }
  }

  .patients-svg-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    padding: 0 40px;

    perspective: 25000px;
    transform-style: preserve-3d;

    div {
      transform-style: preserve-3d;
    }

    @media screen and (min-width: 1151px) {
      margin-top: -40px;
      justify-content: flex-start;
    }

    @media screen and (max-width: 1150px) {
      order: 1;
    }

    @media screen and (max-width: 600px) {
      left: 5vw;
    }

    > div {
      position: relative; 
      width: 500px;
      max-width: 90vw;
    }

    .st0{fill:#E6E7E8;}
    .st1{clip-path:url(#background-clip-path);}
    .st2{fill:#808285;}
    .st3{fill:#F1F2F2;}
    .st4{fill:none;stroke:#A7A9AC;stroke-linecap:round;stroke-linejoin:round;}
    .st5{clip-path:url(#mirror-clip-path);}
    .st6{fill:#231F20;}
    .st7{fill:#5A3622;}
    .st8{fill:#CF0525;}
    .st9{fill:#FFFFFF;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
    .st10{fill:#A7A9AC;}
    .st11{fill:none;stroke:#000000;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;}
    .st12{fill:#FF9E83;}
    .st13{fill:#606163;}
    .st14{fill:#EC6A83;}


    .st15{fill:#F9B9B9;}

    > div > div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }

    .floor-container {
      width: 115%;
      filter: drop-shadow(5px 5px 15px rgba(0,0,0,.2));
    }

    .background-container {
      @media screen and (min-width: 1151px) {
        position: relative; /* Take up vertical space for page flow */
        height: 700px;
      }

      @media screen and (max-width: 1150px) {
        position: relative;
        margin-bottom: 100px;
      }
    }

    @keyframes enter-patients-background {
      0% {transform: scale(.5) translateX(-20%) rotateX(-30deg) rotateY(-90deg) translateZ(-300px);}  
      100% {transform: scale(1) translateX(0) rotateX(0deg) rotateY(0deg) translateZ(-300px);}  
    }

    .background-container,
    .background-clip-group-container {
      transform-origin: left top;
      transform: scale(.5) translateX(-20%) rotateX(-30deg) rotateY(-90deg) translateZ(-300px);
    }

    &.enter .background-container,
    &.enter .background-clip-group-container { 
      animation: enter-patients-background 1.5s both;
    }

    @keyframes enter-mirror {
      0% {transform: translate(-50%, -50%);}  
      100% {transform: translate(0, 0);}  
    }

    #mirror {
      transform: translate(-50%, -50%);
    }

    &.enter #mirror {
      animation: enter-mirror 1.5s both;
    }

    @keyframes enter-table {
      0% {transform: translate3d(50%, 0, 300px) scale(0, 1);}  
      100% {transform: translate3d(0, 0, 300px) scale(1, 1);}  
    }

    .table-container {
      transform-origin: left 200%;
      transform: translate3d(50%, 0, 300px) scale(0, 1);
    }

    &.enter .table-container {
      animation: enter-table 1.5s both;
    }

    @keyframes enter-reflection {
      0% {transform: scale(0);}  
      100% {transform: scale(1);}  
    }

    #reflection {
      transform-origin: left center;
      transform: scale(0);
    }

    &.enter #reflection {
      animation: enter-reflection 1.35s .1s both ease-in;
    }

    @keyframes enter-people {
      0% {transform: scale(3) translate3d(-75%, 100%, 600px);}  
      100% {transform: scale(1) translate3d(0, 0, 600px)}  
    }

    .patient-container {
      transform-origin: right center;
      transform: scale(3) translate3d(-75%, 100%, 600px);
    }

    .caregiver-container {
      transform-origin: right center;
      transform: scale(3) translate3d(-75%, 100%, 601px);
    }

    &.enter .patient-container,
    &.enter .caregiver-container {
      animation: enter-people 1.35s .1s both ease-in;
    }

    @keyframes enter-floor {
      0% {transform: translate3d(-17%, 212%, -600px) scaleY(0);}
      100% {transform: translate3d(-17%, 212%, -600px) scaleY(1);}
    }

    .floor-container {
      transform: translate3d(-17%, 212%, -600px) scaleY(0);
    }

    &.enter .floor-container {
      animation: enter-floor 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    }
  }

  @media screen and (max-width: 1150px) {
    .patients-copy {
      order: 2;
    }
  }
  
  .caregivers-svg-container {
    display: flex;
    justify-content: center;
    padding-right: 60px;
    margin-bottom: 60px;

    @media screen and (max-width: 600px) {
      left: 7.5vw;
    }

    > div {
      position: relative;
      width: 600px;
      max-width: 90vw;

      > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1150px) {
      order: 3;
    }

    @media screen and (min-width: 1151px) and (max-width: 1400px) {
      margin-top: 40px;
    }

    @media screen and (min-width: 1151px) {
      > div {
        width: 100%;
        max-width: 700px;
      }
    }

    #caregivers-background {
      position: relative; /* Take up vertical space for page flow */
    }

    #caregivers-background {
      filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, 0.15));
    }

    #hand-on-left,
    #hand-on-right {
      filter: drop-shadow(-10px -5px 5px rgba(0, 0, 0, 0.12));
    }

    #clock,
    #dollar-signs,
    #surgical-mask,
    #gift-card,
    #health-shield,
    #calendar {
      filter: drop-shadow(-10px -5px 5px rgba(0, 0, 0, 0.15));
    }

    .st0{fill:#FFE1E1;}
    .st1{fill:#8EA8DD;}
    /* #surgical-mask .st1{fill: #cf0525; opacity: .76;} */@at-root
    #surgical-mask .st1{fill: #7c7c7c; opacity: .56;}
    .st2{fill:#199099;}
    .st3{fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;}
    .st4{fill:#A3E5D6;}
    .st5{fill:#7DC6B3;}
    .st6{fill:#8C8C8C;}
    .st7{fill:#FEC5C4;}
    .st8{fill:#FFADA4;}
    .st9{fill:#F99B9B;}
    .st10{display:none;}
    .st11{display:inline;fill:url(#SVGID_1_);}
    .st12{display:inline;fill:url(#SVGID_2_);}
    .st13{display:inline;}
    .st14{fill:#FFFFFF;}
    .st15{opacity:0.3;fill:url(#SVGID_3_);}
    .st16{opacity:0.3;fill:url(#SVGID_4_);}
    .st17{fill:#7C7C7C;}
    /* #calendar .st17 {fill: #cf0525; opacity: .76;} */
    .st18{fill:#FF9085;}
    /* .st19{fill:#D4FEFF;stroke:#000000;stroke-miterlimit:10;} */
    .st19{fill:#D4FEFF;}
    .st20{fill:#C4AFE0;}
    .st21{display:none;fill:url(#SVGID_5_);}
    .st22{display:none;fill:url(#SVGID_6_);}
    .st23{display:none;fill:url(#SVGID_7_);}
    .st24{fill:#BABABA;}
    .st25{fill:#BFC8D6;}
    .st26{display:none;opacity:0.3;fill:#BFC8D6;}
    .st27{fill:url(#SVGID_8_);}
    .st28{fill:url(#SVGID_9_);}
    .st29{opacity:0.78;fill:#FFFFFF;}
    .st30{fill:url(#SVGID_10_);}
    .st31{fill:url(#SVGID_11_);}
    .st32{fill:url(#SVGID_12_);}
    .st33{fill:url(#SVGID_13_);}
    .st34{fill:url(#SVGID_14_);}
    .st35{fill:url(#SVGID_15_);}
    .st36{fill:url(#SVGID_16_);}
    .st37{fill:url(#SVGID_17_);}
    .st38{fill:url(#SVGID_18_);}
    .st39{fill:url(#SVGID_19_);}
    .st40{display:inline;fill:#FFFFFF;}
    .st41{fill:none;stroke:#F7A9B4;stroke-width:2;stroke-miterlimit:10;}
    .st42{fill:#F7A9B4;}
    .st43{fill:#F28393;}
    .st44{display:inline;fill:none;stroke:#599AF2;stroke-miterlimit:10;}
    .st45{display:inline;fill:#4C65BA;}
    .st46{fill:#A4B8EA;}
    .st47{fill:#FFC1C1;}
    .st48{fill:#FE7D8E;}
    .st49{fill:#FEE2E3;}
    .st50{fill:#425AC2;}
    .st51{fill:#cf0525;} /* #6B6B6B */
    .st52{fill:#EC6A83;}
    .st53{fill:#FF7B85;}
    .st54{display:none;fill:#260D3F;}
    .st55{display:none;opacity:0.2;fill:#FFFFFF;}
    .st56{opacity:0.78;fill:#939393;}
    .st57{fill:#FFE8E7;}
    .st58{fill:#D65F5F;}
    .st59{fill:#EE6F6C;}
    .st60{fill:#F48E8E;}
    .st61{fill:#E6574F;}


    @keyframes enter-health-shield {
      0% {transform: scale(0);}
      100% {transform: scale(1);}
    }

    #health-shield {
      transform: scale(0);
    }

    &.enter #health-shield {
      animation: enter-health-shield 1.75s .5s both;
    }

    @keyframes enter-dollar-sign {
      0% {transform: translate(100vw, -100vw);}
      100% {transform: translate(0, 0);}
    }

    .dollar-signs {
      transform: translate(100vw, -100vw);
    }

    &.enter {
      #dollar-sign-1 {
        animation: enter-dollar-sign 1s both;
      }

      #dollar-sign-2 {
        animation: enter-dollar-sign 1s .2s both;
      }

      #dollar-sign-3 {
        animation: enter-dollar-sign 1s .4s both;
      }

      #dollar-sign-4 {
        animation: enter-dollar-sign 1s .6s both;
      }
    }


    @keyframes enter-calendar-clock {
      0% {transform: translate(-100vw, -100vw);}
      100% {transform: translate(0, 0);}
    }

    #calendar {
      transform: translate(-100vw, -100vw);
    }

    &.enter #calendar {
      animation: enter-calendar-clock 1s .75s both;
    }


    #clock {
      transform: translate(-100vw, -100vw);
    }

    &.enter #clock {
      animation: enter-calendar-clock 1s .5s both;
    }


    @keyframes enter-mask {
      0% {transform: translate(100vw, 0);}
      100% {transform: translate(0, 0);}
    }

    #surgical-mask {
      transform: translate(100vw, 0);
    }

    &.enter #surgical-mask {
      animation: enter-mask 1s .5s both;
    }


    @keyframes enter-gift-card {
      0% {transform: translate(100vw, 100vw);}
      100% {transform: translate(0, 0);}
    }

    #gift-card {
      transform: translate(100vw, 100vw);
    }

    &.enter #gift-card {
      animation: enter-gift-card 1s .75s both;
    }


    @keyframes enter-hand-on-right {
      0% {transform: translate(100vw, -100vw) scale(0);}
      100% {transform: translate(0, 0) scale(1);}
    }

    #hand-on-right {
      transform: translate(100vw, -100vw) scale(0);
    }

    &.enter #hand-on-right {
      animation: enter-hand-on-right 1.5s .1s both;
    }

    @keyframes enter-hand-on-left {
      0% {transform: translate(-100vw, 100vw) scale(3); opacity: 0}
      100% {transform: translate(0, 0) scale(1); opacity: 1;}
    }

    #hand-on-left {
      transform: translate(-100vw, 100vw) scale(3); 
      opacity: 0;
    }

    &.enter #hand-on-left {
      animation: enter-hand-on-left 1.5s .1s both;
    }

    @keyframes enter-caregivers-background {
      0% {transform: rotateY(-30deg) rotateX(-90deg) scale(.5);}  
      100% {transform: rotateY(0deg) rotateX(0deg) scale(1);}  
    }

    #caregivers-background { 
      transform: rotateY(-30deg) rotateX(-90deg) scale(.5);
    }

    &.enter #caregivers-background { 
      animation: enter-caregivers-background 1.5s .25s both;
    }
  }

  @media screen and (max-width: 1150px) {
    .caregivers-copy {
      order: 4;
    }
  }

  @keyframes enter-reviews-heading {
    0% {transform: scale(0);}
    100% {transform: scale(1);}
  }

  .reviews-heading {
    position: absolute;
    top: 0;
    left: 2.5vw;
    width: 90vw;
    display: flex;
    justify-content: center;
    text-align: center;
    transform: scale(0);

    @media screen and (max-width: 600px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 1024px) {
      left: 5vw;
    }
  }

  #reviews-container.enter .reviews-heading {
    animation: enter-reviews-heading $reviews-enter-duration both;
  }

  #reviews-container {
    position: relative;
    height: 1000px;

    &.hide {
      display: none;
    }

    &.show {
      display: initial;
    }
    
    #reviews {
      position: relative;
      left: 2vw;
      top: 2.5vh;
      width: 100vw;
      height: 100vh;
      perspective: 3000px;
      transform-style: preserve-3d;
  
      div {
        transform-style: preserve-3d;
      }

      @media screen and (max-width: 1150px) {
        left: 0;
      }
    }
  }


  #reviewers {
    position: absolute;
    top: 150px;
    left: 0;
    width: 100%;
    transform-origin: center top;
    transform: scale(.8);
    /* transform: scale(1) translate(12.5%, 0); */


    @for $i from 1 through 8 {  
      .reviewer-#{$i} svg {
        pointer-events: none;
        cursor: pointer;
        transition: .3s;

        path, 
        polygon,
        ellipse,
        circle {
          pointer-events: auto;
        }

        @media screen and (hover: hover) {
          &:hover {
            filter: drop-shadow(25px 25px 50px rgba(#EC6A83, .45))
            drop-shadow(-25px 0 50px rgba(#EC6A83, .45))
          }   
        }
      }
    }


    @media screen and (max-width: 1024px) and (min-height: 600px) {
      top: 180px;
    }

    svg {
      position: absolute;
      top: 0;
      left: -150%;
      width: 400%;
      filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.2));

      @media screen and (max-width: 1024px) {
        width: 550%;
      }
    }
  }

  .reviews-copy {
    @media screen and (max-width: 1024px) and (min-height: 600px) {
      top: 35vh !important; /* Match increase as much as #reviewers under same condition */
    }
  }

  .review-copy {
    position: absolute;
    pointer-events: none;

    .ellipse-container {
      position: relative;
      width: 100%;
      padding-bottom: 46.3%;
    }

    svg {
      position: absolute;
      left: 0;
      top: 0;
      filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.15));
      overflow: visible;
      pointer-events: auto;

      .scale {
        transform-origin: center center;
      }
    }
  }

  $reviewer-scale: .4;

  @keyframes enter-reviewer-1 {
    /* Must use visibility; opacity messes up z stacking order in Chrome */
    0% {transform: translate3d(-50vw, 120vh, 4px) scale($reviewer-scale); visibility: hidden;}
    1% {transform: translate3d(-50vw, 120vh, 4px) scale($reviewer-scale); visibility: visible;}
    100% {transform: translate3d(0, 0, 4px) scale($reviewer-scale); visibility: visible;}
  }

  @keyframes enter-reviewer-2 {
    0% {transform: translate3d(-35vw, 115vh, 3px) scale($reviewer-scale); visibility: hidden;}
    1% {transform: translate3d(-35vw, 115vh, 3px) scale($reviewer-scale); visibility: visible;}
    100% {transform: translate3d(0, 0, 3px) scale($reviewer-scale); visibility: visible;}
  }

  @keyframes enter-reviewer-3 {
    0% {transform: translate3d(-15vw, 110vh, 2px) scale($reviewer-scale); visibility: hidden;}
    1% {transform: translate3d(-15vw, 110vh, 2px) scale($reviewer-scale); visibility: visible;}
    100% {transform: translate3d(0, 0, 2px) scale($reviewer-scale); visibility: visible;}
  }

  @keyframes enter-reviewer-4 {
    0% {transform: translate3d(0, 105vh, 1px) scale($reviewer-scale); visibility: hidden;}
    1% {transform: translate3d(0, 105vh, 1px) scale($reviewer-scale); visibility: visible;}
    100% {transform: translate3d(0, 0, 1px) scale($reviewer-scale); visibility: visible;}
  }

  @keyframes enter-reviewer-5 {
    0% {transform: translate3d(5vw, 105vh, 2px) scale($reviewer-scale); visibility: hidden;}
    1% {transform: translate3d(5vw, 105vh, 2px) scale($reviewer-scale); visibility: visible;}
    100% {transform: translate3d(0, 0, 2px) scale($reviewer-scale); visibility: visible;}
  }

  @keyframes enter-reviewer-6 {
    0% {transform: translate3d(20vw, 110vh, 3px) scale($reviewer-scale); visibility: hidden;}
    1% {transform: translate3d(20vw, 110vh, 3px) scale($reviewer-scale); visibility: visible;}
    100% {transform: translate3d(0, 0, 3px) scale($reviewer-scale); visibility: visible;}
  }

  @keyframes enter-reviewer-7 {
    0% {transform: translate3d(35vw, 115vh, 4px) scale($reviewer-scale); visibility: hidden;}
    1% {transform: translate3d(35vw, 115vh, 4px) scale($reviewer-scale); visibility: visible;}
    100% {transform: translate3d(0, 0, 4px) scale($reviewer-scale); visibility: visible;}
  }

  @keyframes enter-reviewer-8 {
    0% {transform: translate3d(55vw, 120vh, 5px) scale($reviewer-scale); visibility: hidden;}
    1% {transform: translate3d(55vw, 120vh, 5px) scale($reviewer-scale); visibility: visible;}
    100% {transform: translate3d(0, 0, 5px) scale($reviewer-scale); visibility: visible;}
  }


  @keyframes speak-reviewer-1 {
    0% {transform: translate3d(0, 0, 100px) scale(1);}
    100% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
  }

  @keyframes exit-reviewer-1 {
    0% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
    100% {transform: translate3d(0, 0, 100px) scale(1);}
  }

  @keyframes sidestep-speak-1 {
    0% {transform: translate3d(0, 0, 100px) scale(1);}
    25% {transform: translate3d(-10vw, 1vh, 100px) scale(1);}
    100% {transform: translate3d(-10vw, 1vh, 100px) scale(1);}
  }

  @keyframes sidestep-exit-1 {
    0% {transform: translate3d(-10vw, 1vh, 100px) scale(1);}
    75% {transform: translate3d(-10vw, 1vh, 100px) scale(1);}
    100% {transform: translate3d(0, 0, 100px) scale(1);}
  }


  @keyframes speak-review-copy-1 {
    0% {
      transform: translate3d(-800px, -3vh, 150px) scale(0);
      transform-origin: -100% 50%;
    }
    100% {
      transform: translate3d(5vw, 12vh, 150px) scale(1);
      transform-origin: 1800px 50%;
    }
  }

  @keyframes exit-review-copy-1 {
    0% {
      transform: translate3d(5vw, 12vh, 50px) scale(1);
      transform-origin: 1800px 50%;
    }
    100% {
      transform: translate3d(800px, -3vh, 1px) scale(0);
      transform-origin: -100% 50%;
    }
  }

  @keyframes speak-review-copy-1-portrait {
    0% {
      transform: translate3d(-800px, 11vh, 400px) scale(0);
      transform-origin: -100% 50%;
    }
    100% {
      transform: translate3d(-5vw, 26vh, 400px) scale(1);
      transform-origin: 1800px 50%;
    }
  }

  @keyframes exit-review-copy-1-portrait {
    0% {
      transform: translate3d(-5vw, 26vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, 11vh, 400px) scale(0);
      transform-origin: -100% 50%;
    }
  }


  @keyframes speak-reviewer-2 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    100% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
  }

  @keyframes exit-reviewer-2 {
    0% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes sidestep-speak-2 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    25% {transform: translate3d(-10vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(-10vw, -1.5vh, 100px) scale(1);}
  }

  @keyframes sidestep-exit-2 {
    0% {transform: translate3d(-10vw, -1.5vh, 100px) scale(1);}
    75% {transform: translate3d(-10vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes speak-review-copy-2 {
    0% {
      transform: translate3d(-800px, -5vh, 150px) scale(0);
      transform-origin: -100% 50%;
    }
    100% {
      transform: translate3d(10vw, 10vh, 150px) scale(1);
      transform-origin: 1800px 50%;
    }
  }

  @keyframes exit-review-copy-2 {
    0% {
      transform: translate3d(10vw, 10vh, 50px) scale(1);
      transform-origin: 1800px 50%;
    }
    100% {
      transform: translate3d(800px, -5vh, 1px) scale(0);
      transform-origin: -100% 50%;
    }
  }

  @keyframes speak-review-copy-2-portrait {
    0% {
      transform: translate3d(-800px, 8vh, 400px) scale(0);
      transform-origin: -100% 50%;
    }
    100% {
      transform: translate3d(-5vw, 23vh, 400px) scale(1);
      transform-origin: 1800px 50%;
    }
  }

  @keyframes exit-review-copy-2-portrait {
    0% {
      transform: translate3d(-5vw, 23vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, 8vh, 400px) scale(0);
      transform-origin: -100% 50%;
    }
  }


  @keyframes speak-reviewer-3 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    100% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
  }

  @keyframes exit-reviewer-3 {
    0% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes sidestep-speak-3 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    25% {transform: translate3d(-10vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(-10vw, -1.5vh, 100px) scale(1);}
  }

  @keyframes sidestep-exit-3 {
    0% {transform: translate3d(-10vw, -1.5vh, 100px) scale(1);}
    75% {transform: translate3d(-10vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes speak-review-copy-3 {
    0% {
      transform: translate3d(-800px, -6vh, 150px) scale(0);
      transform-origin: -100% 50%;
    }
    100% {
      transform: translate3d(5vw, 3vh, 150px) scale(1);
      transform-origin: 1800px 50%;
    }
  }

  @keyframes exit-review-copy-3 {
    0% {
      transform: translate3d(5vw, 3vh, 50px) scale(1);
      transform-origin: 1800px 50%;
    }
    100% {
      transform: translate3d(800px, -6vh, 1px) scale(0);
      transform-origin: -100% 50%;
    }
  }

  @keyframes speak-review-copy-3-portrait {
    0% {
      transform: translate3d(-800px, 0vh, 400px) scale(0);
      transform-origin: -100% 50%;
    }
    100% {
      transform: translate3d(-5vw, 15vh, 400px) scale(1);
      transform-origin: 1800px 50%;
    }
  }

  @keyframes exit-review-copy-3-portrait {
    0% {
      transform: translate3d(-5vw, 15vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, 0vh, -400px) scale(0);
      transform-origin: -100% 50%;
    }
  }


  @keyframes speak-reviewer-4 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    100% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
  }

  @keyframes exit-reviewer-4 {
    0% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes speak-review-copy-4 {
    0% {
      transform: translate3d(-800px, -6vh, 150px) scale(0);
      transform-origin: -100% 50%;
    }
    100% {
      transform: translate3d(15vw, 1vh, 150px) scale(1);
      transform-origin: 1800px 50%;
    }
  }

  @keyframes exit-review-copy-4 {
    0% {
      transform: translate3d(15vw, 1vh, 50px) scale(1);
      transform-origin: 1800px 50%;
    }
    100% {
      transform: translate3d(800px, -6vh, 1px) scale(0);
      transform-origin: -100% 50%;
    }
  }

  @keyframes speak-review-copy-4-portrait {
    0% {
      transform: translate3d(-800px, 2vh, 400px) scale(0);
      transform-origin: -100% 50%;
    }
    100% {
      transform: translate3d(-5vw, 17vh, 400px) scale(1);
      transform-origin: 1800px 50%;
    }
  }

  @keyframes exit-review-copy-4-portrait {
    0% {
      transform: translate3d(-5vw, 17vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, 2vh, 400px) scale(0);
      transform-origin: -100% 50%;
    }
  }


  @keyframes speak-reviewer-5 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    100% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
  }

  @keyframes exit-reviewer-5 {
    0% {transform: translate3d(0, 10vh, 300px) scale(1.5);} 
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes sidestep-speak-5 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    25% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
  }

  @keyframes sidestep-exit-5 {
    0% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
    75% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes speak-reviewer-5-portrait {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    100% {transform: translate3d(0, 20vh, 300px) scale(1.5);}
  }

  @keyframes exit-reviewer-5-portrait {
    0% {transform: translate3d(0, 20vh, 300px) scale(1.5);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes sidestep-speak-5-portrait {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    25% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
  }

  @keyframes sidestep-exit-5-portrait {
    0% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
    75% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes speak-review-copy-5 {
    0% {
      transform: translate3d(800px, -6vh, 150px) scale(0);
      transform-origin: 100% 50%;
    }
    100% {
      transform: translate3d(-32.5vw, 5vh, 150px) scale(1);
      transform-origin: -1800px 50%;
    }
  }

  @keyframes exit-review-copy-5 {
    0% {
      transform: translate3d(-32.5vw, 5vh, 50px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, -6vh, 1px) scale(0);
      transform-origin: 100% 50%;
    }
  }

  @keyframes speak-review-copy-5-portrait {
    0% {
      transform: translate3d(800px, 8vh, 400px) scale(0);
      transform-origin: 100% 50%;
    }
    100% {
      transform: translate3d(-5vw, 23vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
  }

  @keyframes exit-review-copy-5-portrait {
    0% {
      transform: translate3d(-5vw, 23vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, 8vh, 400px) scale(0);
      transform-origin: 100% 50%;
    }
  }


  @keyframes speak-reviewer-6 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    100% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
  }

  @keyframes exit-reviewer-6 {
    0% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes sidestep-speak-6 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    25% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
  }

  @keyframes sidestep-exit-6 {
    0% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
    75% {transform: translate3d(10vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes speak-review-copy-6 {
    0% {
      transform: translate3d(800px, -6vh, 150px) scale(0);
      transform-origin: 100% 50%;
    }
    100% {
      transform: translate3d(-27.5vw, 3vh, 150px) scale(1);
      transform-origin: -1800px 50%;
    }
  }

  @keyframes exit-review-copy-6 {
    0% {
      transform: translate3d(-27.5vw, 3vh, 50px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, -6vh, 1px) scale(0);
      transform-origin: 100% 50%;
    }
  }

  @keyframes speak-review-copy-6-portrait {
    0% {
      transform: translate3d(800px, 5vh, 400px) scale(0);
      transform-origin: 100% 50%;
    }
    100% {
      transform: translate3d(-5vw, 20vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
  }

  @keyframes exit-review-copy-6-portrait {
    0% {
      transform: translate3d(-5vw, 20vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, 5vh, 400px) scale(0);
      transform-origin: 100% 50%;
    }
  }


  @keyframes speak-reviewer-7 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    100% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
  }

  @keyframes exit-reviewer-7 {
    0% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes sidestep-speak-7 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    25% {transform: translate3d(15vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(15vw, -1.5vh, 100px) scale(1);}
  }

  @keyframes sidestep-exit-7 {
    0% {transform: translate3d(15vw, -1.5vh, 100px) scale(1);}
    75% {transform: translate3d(15vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes speak-review-copy-7 {
    0% {
      transform: translate3d(800px, -6vh, 150px) scale(0);
      transform-origin: 100% 50%;
    }
    100% {
      transform: translate3d(-22.8vw, 3vh, 150px) scale(1);
      transform-origin: -1800px 50%;
    }
  }

  @keyframes exit-review-copy-7 {
    0% {
      transform: translate3d(-22.8vw, 3vh, 50px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, -6vh, 1px) scale(0);
      transform-origin: 100% 50%;
    }
  }

  @keyframes speak-review-copy-7-portrait {
    0% {
      transform: translate3d(800px, 8vh, 400px) scale(0);
      transform-origin: 100% 50%;
    }
    100% {
      transform: translate3d(-5vw, 23vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
  }

  @keyframes exit-review-copy-7-portrait {
    0% {
      transform: translate3d(-5vw, 23vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, 8vh, 400px) scale(0);
      transform-origin: 100% 50%;
    }
  }


  @keyframes speak-reviewer-8 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    100% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
  }

  @keyframes exit-reviewer-8 {
    0% {transform: translate3d(0, 10vh, 300px) scale(1.5);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes sidestep-speak-8 {
    0% {transform: translate3d(0, -5vh, 100px) scale(1);}
    25% {transform: translate3d(15vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(15vw, -1.5vh, 100px) scale(1);}
  }

  @keyframes sidestep-exit-8 {
    0% {transform: translate3d(15vw, -1.5vh, 100px) scale(1);}
    75% {transform: translate3d(15vw, -1.5vh, 100px) scale(1);}
    100% {transform: translate3d(0, -5vh, 100px) scale(1);}
  }

  @keyframes speak-review-copy-8 {
    0% {
      transform: translate3d(800px, -5vh, 150px) scale(0);
      transform-origin: 100% 50%;
    }
    100% {
      transform: translate3d(-10vw, 10vh, 150px) scale(1);
      transform-origin: -1800px 50%;
    }
  }

  @keyframes exit-review-copy-8 {
    0% {
      transform: translate3d(-10vw, 10vh, 50px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, -5vh, 1px) scale(0);
      transform-origin: 100% 50%;
    }
  }

  @keyframes speak-review-copy-8-portrait {
    0% {
      transform: translate3d(800px, 3vh, 400px) scale(0);
      transform-origin: 100% 50%;
    }
    100% {
      transform: translate3d(-5vw, 18vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
  }

  @keyframes exit-review-copy-8-portrait {
    0% {
      transform: translate3d(-5vw, 18vh, 400px) scale(1);
      transform-origin: -1800px 50%;
    }
    100% {
      transform: translate3d(800px, 3vh, 400px) scale(0);
      transform-origin: 100% 50%;
    }
  }

  #caregiver-1 {
    position: absolute;
    left: 15vw;
    top: 9.9vh;
    width: 8%;

    > div {
      visibility: hidden;
      
      &.enter {
        animation: enter-reviewer-1 $reviews-enter-duration both;
      }
    }

    transform: translate3d(0, 0, 100px) scale(1);

    &.speak {
      animation: speak-reviewer-1 $reviewers-speak-duration ease-in-out both;
    }

    &.sidestep-speak {
      animation: sidestep-speak-1 $reviewers-speak-duration ease-in-out both;
    }

    &.exit {
      animation: exit-reviewer-1 $reviewers-exit-duration ease-in-out both;
    }

    &.sidestep-exit {
      animation: sidestep-exit-1 $reviewers-exit-duration ease-in-out both;
    }

    .st0{fill:#EFAC7F;}
    .st1{fill:#282828;}
    .st2{fill:#CF0525;}
    .st3{fill:#930520;}
    .st4{fill:#D19369;}
    .st5{fill:#A81639;}
    .st6{fill:#333333;}
    .st7{fill:#FFFFFF;}
    .st8{fill:#231F20;}
  }

  @media screen and (max-width: 600px) and (min-height: 600px) {
    .review-copy {
      polygon {
        display: none;
      }
    }
  }

  .text-inside-ellipse {
    position: relative;

    .open-quote {
      position: absolute;
      left: -10%;
      top: calc(50% - 6vw);
      width: 6vw;
      height: 6vw;
      background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
          <path fill="%23777" d="m241.92 235.2c0 34.16 70.559 39.199 70.559 123.2 0 61.602-44.238 107.52-105.84 107.52-65.52-0.55859-108.64-59.359-108.64-121.52 0-107.52 100.8-249.76 163.52-249.76 15.68 0 44.238 11.199 44.238 30.238 0.003907 19.047-63.836 52.086-63.836 110.32z"/> \
          <path fill="%23777" d="m531.44 235.2c0 34.16 70.559 39.199 70.559 123.2 0 61.602-44.238 107.52-105.84 107.52-66.078 0-109.2-58.801-109.2-120.96 0-107.52 100.8-249.76 163.52-249.76 15.68 0 44.238 11.199 44.238 30.238 0 19.047-63.277 51.527-63.277 109.77z"/> \
        </svg>') no-repeat;
      background-size: 100%;  
      opacity: .1;
      z-index: -1;

      @media screen and (max-width: 600px) {
        left: -20%;
        top: calc(50% - 12vw);
        width: 12vw;
        height: 12vw;
      }
    }


    .close-quote {
      content: '';
      position: absolute;
      right: -10%;
      top: calc(50% - 3vw);
      width: 6vw;
      height: 6vw;
      background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
          <path fill="%23777" d="m241.92 235.2c0 34.16 70.559 39.199 70.559 123.2 0 61.602-44.238 107.52-105.84 107.52-65.52-0.55859-108.64-59.359-108.64-121.52 0-107.52 100.8-249.76 163.52-249.76 15.68 0 44.238 11.199 44.238 30.238 0.003907 19.047-63.836 52.086-63.836 110.32z"/> \
          <path fill="%23777" d="m531.44 235.2c0 34.16 70.559 39.199 70.559 123.2 0 61.602-44.238 107.52-105.84 107.52-66.078 0-109.2-58.801-109.2-120.96 0-107.52 100.8-249.76 163.52-249.76 15.68 0 44.238 11.199 44.238 30.238 0 19.047-63.277 51.527-63.277 109.77z"/> \
        </svg>') no-repeat;
      background-size: 100%; 
      transform: rotate(180deg); 
      opacity: .1;
      z-index: -1;

      @media screen and (max-width: 600px) {
        right: -20%;
        top: calc(50% - 6vw);
        width: 12vw;
        height: 12vw;
      }
    }
  }

  .text-initials {
    position: relative;
    height: 0;

    > div {
      position: relative;
      margin-top: 18px;
      font-size: 2.4rem !important;
      color: #e29696  !important;
      font-weight: 700  !important;

      @media screen and (min-width: 601px) and (max-width: 1024px) {
        font-size: 1.6rem !important;
      }

      @media screen and (max-width: 600px) {
        font-size: 1.3rem !important;
      }
    }
  }

  .review-copy-1 {
    transform: translate3d(-800px, -3vh, 150px) scale(0);
    transform-origin: -100% 50%;

    &.speak {
      animation: speak-review-copy-1 $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &.exit {
      animation: exit-review-copy-1 $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @media screen and (max-width: 600px) and (min-height: 600px) {
      transform: translate3d(-800px, 11vh, 400px) scale(0);
      transform-origin: -100% 50%;

      &.speak {
        animation: speak-review-copy-1-portrait $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      &.exit {
        animation: exit-review-copy-1-portrait $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      .ellipse-svg .scale {
        transform: scale(1.4, 2.4);
      }

      .text-inside-ellipse > div > div {
        width: 90%;
      }
    }
  }

  #patient-1 {
    position: absolute;
    left: 24.5vw;
    top: 5vh;
    width: 8%;

    > div {
      visibility: hidden;
      
      &.enter {
        animation: enter-reviewer-2 $reviews-enter-duration both;
      }
    }

    transform: translate3d(0, -5vh, 100px) scale(1);

    &.speak {
      animation: speak-reviewer-2 $reviewers-speak-duration #{math.div($reviewers-speak-duration, 4)} ease-in-out both;
    }
 
    &.sidestep-speak {
      animation: sidestep-speak-2 $reviewers-speak-duration ease-in-out both;
    }

    &.exit {
      animation: exit-reviewer-2 $reviewers-exit-duration ease-in-out both;
    }

    &.sidestep-exit {
      animation: sidestep-exit-2 $reviewers-exit-duration ease-in-out both;
    }

    .st0{fill:#EC8583;}
    .st1{fill:#6D6E71;}
    .st2{fill:#231F20;}
    .st3{fill:#FFB483;}
    .st4{fill:#58595B;}
    .st5{fill:#414042;}
    .st6{fill:#FFFFFF;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
    .st7{fill:#D1D3D4;}
    .st8{fill:none;stroke:#231F20;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}
    .st9{fill:#ECA595;}
    .st10{fill:#F1F2F2;}
  }

  .review-copy-2 {
    transform: translate3d(-800px, -5vh, 150px) scale(0);
    transform-origin: -100% 50%;

    &.speak {
      animation: speak-review-copy-2 $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &.exit {
      animation: exit-review-copy-2 $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @media screen and (max-width: 600px) and (min-height: 600px) {
      transform: translate3d(-800px, 8vh, 400px) scale(0);
      transform-origin: -100% 50%;

      &.speak {
        animation: speak-review-copy-2-portrait $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      &.exit {
        animation: exit-review-copy-2-portrait $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      .ellipse-svg .scale {
        transform: scale(1.6, 2.6);
      }

      .text-inside-ellipse > div > div {
        width: 100%;
      }
    }
  }

  #caregiver-2 {
    position: absolute;
    left: 33.4vw;
    top: .5vh;
    width: 5%;

    > div {
      visibility: hidden;
      
      &.enter {
        animation: enter-reviewer-3 $reviews-enter-duration both;
      }
    }

    transform: translate3d(0, -5vh, 100px) scale(1);

    &.speak {
      animation: speak-reviewer-3 $reviewers-speak-duration #{math.div($reviewers-speak-duration, 4)} ease-in-out both;
    }
 
    &.sidestep-speak {
      animation: sidestep-speak-3 $reviewers-speak-duration ease-in-out both;
    }

    &.exit {
      animation: exit-reviewer-3 $reviewers-exit-duration ease-in-out both;
    }

    &.sidestep-exit {
      animation: sidestep-exit-3 $reviewers-exit-duration ease-in-out both;
    }

    .st0{fill:#231F20;}
    .st1{fill:#EC8583;}
    .st2{fill:#FFFFFF;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
    .st3{fill:none;stroke:#231F20;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}
    .st4{fill:#CF0525;}
    .st5{fill:#EC6A83;}
  }

  .review-copy-3 {
    transform: translate3d(-800px, -6vh, 150px) scale(0);
    transform-origin: -100% 50%;

    &.speak {
      animation: speak-review-copy-3 $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &.exit {
      animation: exit-review-copy-3 $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .ellipse-svg .scale {
      transform: scale(.6, 1);
    }

    .text-inside-ellipse > div > div {
      width: 40% !important;
      text-shadow: 5px 5px 5px rgba(71, 43, 43, 0.15);
    }

    @media screen and (max-width: 600px) and (min-height: 600px) {
      transform: translate3d(-800px, 0vh, 400px) scale(0);
      transform-origin: -100% 50%;

      &.speak {
        animation: speak-review-copy-3-portrait $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      &.exit {
        animation: exit-review-copy-3-portrait $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      .ellipse-svg .scale {
        transform: scale(1.4, 2.2);
      }

      .text-inside-ellipse > div > div {
        width: 100% !important;
      }
    }
  }

  #patient-2 {
    position: absolute;
    left: 40vw;
    top: -1vh;
    width: 8%;

    @media screen and (max-width: 600px) and (min-height: 600px) {
      top: -3.8vh;
    }
    
    > div {
      visibility: hidden;

      &.enter {
        animation: enter-reviewer-4 $reviews-enter-duration both;
      }
    }

    transform: translate3d(0, -5vh, 100px) scale(1);

    &.speak {
      animation: speak-reviewer-4 $reviewers-speak-duration #{math.div($reviewers-speak-duration, 4)} ease-in-out both;
    }

    /* Doesn't ever sidestep */

    &.exit {
      animation: exit-reviewer-4 $reviewers-exit-duration ease-in-out both;
    }



    .st0{fill:#A7A9AC;}
    .st1{fill:#EC8583;}
    .st2{fill:#FFFFFF;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
    .st3{fill:#939598;}
    .st4{fill:none;stroke:#231F20;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}
    .st5{fill:#231F20;}
    .st6{fill:#CF0525;}
    .st7{fill:#6D6E71;}
    .st8{fill:#EC6A83;}
    .st9{fill:#ECA595;}
  }

  .review-copy-4 {
    transform: translate3d(-800px, -6vh, 150px) scale(0);
    transform-origin: -100% 50%;

    &.speak {
      animation: speak-review-copy-4 $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &.exit {
      animation: exit-review-copy-4 $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .ellipse-svg .scale {
      transform: scale(.8, 1);
    }

    .text-inside-ellipse > div > div {
      width: 50% !important;
    }

    @media screen and (max-width: 600px) and (min-height: 600px) {
      transform: translate3d(-800px, 2vh, 400px) scale(0);
      transform-origin: -100% 50%;

      &.speak {
        animation: speak-review-copy-4-portrait $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      &.exit {
        animation: exit-review-copy-4-portrait $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      .ellipse-svg .scale {
        transform: scale(2, 2.4);
      }

      .text-inside-ellipse > div > div {
        width: 100% !important;
      }
    }
  }

  #caregiver-3 {
    position: absolute;
    left: 47.5vw;
    top: -3.1vh;
    width: 4.7%;

    @media screen and (max-width: 600px) and (min-height: 600px) {
      top: -4.8vh;
    }

    > div {
      visibility: hidden;
      
      &.enter {
        animation: enter-reviewer-5 $reviews-enter-duration both;
      }
    }

    transform: translate3d(0, -5vh, 100px) scale(1);

    &.speak {
      animation: speak-reviewer-5 $reviewers-speak-duration #{math.div($reviewers-speak-duration, 4)} ease-in-out both; 

      @media screen and (max-width: 600px) and (min-height: 600px) {
        animation: speak-reviewer-5-portrait $reviewers-speak-duration ease-in-out both;
      }
    }
 
    &.sidestep-speak {
      animation: sidestep-speak-5 $reviewers-speak-duration ease-in-out both;

      @media screen and (max-width: 600px) and (min-height: 600px) {
        animation: sidestep-speak-5-portrait $reviewers-speak-duration ease-in-out both;
      }
    }

    &.exit {
      animation: exit-reviewer-5 $reviewers-exit-duration ease-in-out both;

      @media screen and (max-width: 600px) and (min-height: 600px) {
        animation: exit-reviewer-5-portrait $reviewers-exit-duration ease-in-out both;
      }
    }

    &.sidestep-exit {
      animation: sidestep-exit-5 $reviewers-exit-duration ease-in-out both;

      @media screen and (max-width: 600px) and (min-height: 600px) {
        animation: sidestep-exit-5-portrait $reviewers-exit-duration ease-in-out both;
      }
    }

    .st0{fill:#CF0525;}
    .st1{fill:#231F20;}
    .st2{fill:#B5690C;}
    .st3{fill:#FFFFFF;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
    .st4{fill:none;stroke:#231F20;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}
    .st5{fill:#5A3622;}
  }

  .review-copy-5 {
    transform: translate3d(800px, -6vh, 150px) scale(0);
    transform-origin: 100% 50%;

    &.speak {
      animation: speak-review-copy-5 $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &.exit {
      animation: exit-review-copy-5 $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .ellipse-svg .scale {
      transform: scale(-.8, 1.1);

      polygon {
        transform: scaleX(-1);
      }
    }

    .text-inside-ellipse > div > div {
      width: 60% !important;
    }

    @media screen and (max-width: 600px) and (min-height: 600px) {
      transform: translate3d(800px, 8vh, 400px) scale(0);
      transform-origin: 100% 50%;

      &.speak {
        animation: speak-review-copy-5-portrait $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      &.exit {
        animation: exit-review-copy-5-portrait $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      .ellipse-svg .scale {
        transform: scale(2, 2.4);
      }

      .text-inside-ellipse > div > div {
        width: 100% !important;
      }
    }
  }

  #patient-3 {
    position: absolute;
    left: 53.4vw;
    top: calc(-3.1vh + 4.2vw);
    width: 12%;

    > div {
      visibility: hidden;
      
      &.enter {
        animation: enter-reviewer-6 $reviews-enter-duration both;
      }
    }

    transform: translate3d(0, -5vh, 100px) scale(1);

    &.speak {
      animation: speak-reviewer-6 $reviewers-speak-duration #{math.div($reviewers-speak-duration, 4)} both;
    }
       
    &.sidestep-speak {
      animation: sidestep-speak-6 $reviewers-speak-duration ease-in-out both;
    }

    &.exit {
      animation: exit-reviewer-6 $reviewers-exit-duration both;
    }

    &.sidestep-exit {
      animation: sidestep-exit-6 $reviewers-exit-duration ease-in-out both;
    }

    .background {display: none;}
    .st0{fill:#E6E7E8;}
    .st1{fill:#BCBEC0;}
    .st2{fill:#231F20;}
    .st3{fill:#939598;}
    .st4{fill:#A7A9AC;}
    .st5{fill:#5A3622;}
    .st6{fill:#4C4C4C;}
    .st7{fill:#FFFFFF;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
    .st8{fill:#D1D3D4;}
    .st9{fill:none;stroke:#231F20;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}
    .st10{fill:#414042;}
    .st11{fill:#8C3622;}
    .st12{fill:#F1F2F2;}
  }

  .review-copy-6 {
    transform: translate3d(800px, -6vh, 150px) scale(0);
    transform-origin: 100% 50%;

    &.speak {
      animation: speak-review-copy-6 $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &.exit {
      animation: exit-review-copy-6 $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .ellipse-svg .scale {
      transform: scale(.8, 1.2);

      polygon {
        transform: scaleX(-1);
      }
    }

    .text-inside-ellipse > div > div {
      width: 60% !important;
    }

    @media screen and (max-width: 600px) and (min-height: 600px) {
      transform: translate3d(800px, 5vh, 400px) scale(0);
      transform-origin: 100% 50%;

      &.speak {
        animation: speak-review-copy-6-portrait $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      &.exit {
        animation: exit-review-copy-6-portrait $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      .ellipse-svg .scale {
        transform: scale(2, 3);
      }

      .text-inside-ellipse > div > div {
        width: 100% !important;
      }
    }
  }

  #caregiver-4 {
    position: absolute;
    left: 64vw;
    top: 2.2vh;
    width: 5.5%;

    > div {
      visibility: hidden;
      
      &.enter {
        animation: enter-reviewer-7 $reviews-enter-duration both;
      }
    }

    transform: translate3d(0, -5vh, 100px) scale(1);

    &.speak {
      animation: speak-reviewer-7 $reviewers-speak-duration #{math.div($reviewers-speak-duration, 4)} both;
    }
       
    &.sidestep-speak {
      animation: sidestep-speak-7 $reviewers-speak-duration ease-in-out both;
    }

    &.exit {
      animation: exit-reviewer-7 $reviewers-exit-duration both;
    }

    &.sidestep-exit {
      animation: sidestep-exit-7 $reviewers-exit-duration ease-in-out both;
    }

    .st0{fill:#5A3622;}
    .st1{fill:#513023;}
    .st2{fill:#231F20;}
    .st3{fill:#1D262D;}
    .st4{fill:#AF0828;}
    .st5{fill:#CF0525;}
    .st6{fill:#FFFFFF;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
  }

  .review-copy-7 {
    transform: translate3d(800px, -6vh, 150px) scale(0);
    transform-origin: 100% 50%;

    &.speak {
      animation: speak-review-copy-7 $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &.exit {
      animation: exit-review-copy-7 $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    polygon {
      transform: scaleX(-1);
    }

    @media screen and (max-width: 600px) and (min-height: 600px) {
      transform: translate3d(800px, 8vh, 400px) scale(0);
      transform-origin: 100% 50%;

      &.speak {
        animation: speak-review-copy-7-portrait $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      &.exit {
        animation: exit-review-copy-7-portrait $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      .ellipse-svg .scale {
        transform: scale(2, 3);
      }

      .text-inside-ellipse > div > div {
        width: 100% !important;
      }
    }
  }

  #patient-4 {
    position: absolute;
    left: 71vw;
    top: 7.5vh;
    width: 6.3%;

    > div {
      visibility: hidden;
      
      &.enter {
        animation: enter-reviewer-8 $reviews-enter-duration both;
      }
    }

    transform: translate3d(0, -5vh, 100px) scale(1);

    &.speak {
      animation: speak-reviewer-8 $reviewers-speak-duration #{math.div($reviewers-speak-duration, 4)} both;
    }
       
    &.sidestep-speak {
      animation: sidestep-speak-8 $reviewers-speak-duration ease-in-out both;
    }

    &.exit {
      animation: exit-reviewer-8 $reviewers-exit-duration both;
    }

    &.sidestep-exit {
      animation: sidestep-exit-8 $reviewers-exit-duration ease-in-out both;
    }

    .st0{fill:#231F20;}
    .st1{fill:#FA9960;}
    .st2{fill:#4C4C4C;}
    .st3{fill:#3D3D3D;}
    .st4{fill:#EC6A83;}
    .st5{fill:#FFC0EF;}
    .st6{fill:#A7A9AC;}
    .st7{fill:#FFFFFF;}
    .st8{fill:none;stroke:#231F20;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}
  }

  .review-copy-8 {
    transform: translate3d(800px, -5vh, 150px) scale(0);
    transform-origin: 100% 50%;

    &.speak {
      animation: speak-review-copy-8 $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &.exit {
      animation: exit-review-copy-8 $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .ellipse-svg .scale {
      transform: scale(.8, .8);
    }

    .text-inside-ellipse > div > div {
      width: 60% !important;
    }

    @media screen and (max-width: 600px) and (min-height: 600px) {
      transform: translate3d(800px, 3vh, 400px) scale(0);
      transform-origin: 100% 50%;

      &.speak {
        animation: speak-review-copy-8-portrait $reviewers-speak-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      &.exit {
        animation: exit-review-copy-8-portrait $reviewers-exit-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      .ellipse-svg .scale {
        transform: scale(1.2, 2);
      }

      .text-inside-ellipse > div > div {
        width: 80% !important;
      }
    }
  }


  .reviews-copy {
    position: absolute;
    top: 30vh;
    left: 15.2vw;
    display: flex;
    justify-content: center;
    fill: #FFE1E1;
    width: 80vw;
    font-size: 1.75vw;

    @media screen and (max-width: 600px) and (min-height: 600px) {
      font-size: 4vw;
    }

    > div {
      flex: none;
      width: 80%;
      display: flex;
    }
  }

  .text-inside-ellipse { 
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-bottom: 46.3%;

    > div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      

      > div {
        width: 70%;
        text-align: center;

        &::before{
          content: '';
          height: 100%;
          width: 50%;
          float: left;
          shape-outside: polygon(25% 0, 0 0, 0 100%, 25% 100%, 0 50%);
        }
        > span::before{
          content: '';
          height: 100%;
          width: 50%;
          float: right;
          shape-outside: polygon(75% 0, 100% 0%, 100% 100%, 75% 100%, 100% 50%);
        }
      }
    }
  }

  .clipped-star-groups {

    .st0{opacity:0.52;fill:#FFFFFF;} 

    @keyframes enter-circle-star-group {
      0% {transform: translate(0, -40%) scale(.2) rotate(-150deg)}
      100% {transform: translate(0, -40%) scale(.66) rotate(20deg)}
    }

    @keyframes exit-circle-star-group {
      0% {transform: translate(0, -40%) scale(.66) rotate(20deg)}
      100% {transform: translate(0, -40%) scale(1.12) rotate(170deg)}
    }

    @keyframes enter-circle-star-group-portrait {
      0% {transform: translate(-5%, -30%) scale(.6) rotate(-150deg)}
      100% {transform: translate(-5%, -30%) scale(1) rotate(20deg)}
    }

    @keyframes exit-circle-star-group-portrait {
      50% {transform: translate(-5%, -30%) scale(1) rotate(20deg)}
      100% {transform: translate(-5%, -30%) scale(1.6) rotate(170deg)}
    }

    .animated-star-groups {
      transform-origin: 40% 150%;
      opacity: .6;

      &.enter {
        animation: enter-circle-star-group $reviewers-speak-duration both;
      }

      &.exit {
        animation: exit-circle-star-group $reviewers-exit-duration both;
      }

      @media screen and (max-width: 600px) and (min-height: 600px) {
        &.enter {
          animation: enter-circle-star-group-portrait $reviewers-speak-duration both;
        }

        &.exit {
          animation: exit-circle-star-group-portrait $reviewers-exit-duration both;
        }
      }
    }

    @for $i from 1 through 8 {  
      .st1-#{$i}{fill:url(#{'#SVGID_LG1_'+$i});}
      .st2-#{$i}{fill:url(#{'#SVGID_LG2_'+$i});}
      .st3-#{$i}{fill:url(#{'#SVGID_LG3_'+$i});}
      .st4-#{$i}{fill:url(#{'#SVGID_LG4_'+$i});}
      .st5-#{$i}{fill:url(#{'#SVGID_LG5_'+$i});}
  
      &.clipped-star-group-#{$i} {
        clip-path:url(#{'#SVGID_21_'+$i});
      }


      /*
      .animated-star-group-#{$i} {
        animation: 
      }
      */
    }

    @for $i from 1 through 3 { 
      .animated-star-group-#{$i} {
        animation-delay: calc(#{'var(--scroll-'+(1 + ($i*2))+')'} * -1s); // Sass won't compile without using string interpolation
      }
    }

    .animated-star-group-4 {
      animation-delay: calc(#{'var(--scroll-10)'} * -1s); // Sass won't compile without using string interpolation
    }

    .animated-star-group-5 {
      animation-delay: calc(#{'var(--scroll-9)'} * -1s); // Sass won't compile without using string interpolation
    }
    
    @for $i from 6 through 8 { // 10, 8, 6, 4
      .animated-star-group-#{$i} {
        animation-delay: calc(#{'var(--scroll-'+(12 - (($i - 4)*2))+')'} * -1s); // Sass won't compile without using string interpolation
      }
    }
  }

  @keyframes enter-speak-button {
    0% {transform: translate(-50%, 200px) scale(0);}
    100% {transform: translate(-50%, 0) scale(1);}
  }

  @keyframes exit-speak-button {
    0% {transform: translate(-50%, 0) scale(1);}
    100% {transform: translate(-50%, 200px) scale(0);}
  }

  @keyframes enter-speak-button-mobile {
    0% {transform: translate(-50%, 200px) scale(0);}
    100% {transform: translate(-50%, 0) scale(.6);}
  }

  @keyframes exit-speak-button-mobile {
    0% {transform: translate(-50%, 0) scale(.6);}
    100% {transform: translate(-50%, 200px) scale(0);}
  }

  #speak-button {
    position: absolute;
    left: 47.5%;
    top: calc(300px + 20vh);
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #db596c; 
    color: #e33a3a;
    border-radius: 50%;
    transform: translate(-50%, 200px) scale(0);   
    filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    transition: 0.2s;

    @media screen and (min-width: 1500px) {
      top: calc(450px + 20vh);
    }

    @media screen and (max-width: 1150px) {
      left: 50%;
    }

    @media screen and (min-width: 700px) and (max-width: 1024px) {
      top: calc(380px + 20vh);
    }

    @media screen and (max-width: 600px) {
      top: calc(200px + 20vh);
    }

    &.enter {
      animation: enter-speak-button 1s .3s both;

      @media screen and (max-width: 600px) {
        animation: enter-speak-button-mobile 1s .3s both;
      }
    }

    &.exit {
      animation: exit-speak-button .4s both;

      @media screen and (max-width: 600px) {
        animation: exit-speak-button-mobile .4s both;
      }
    }

    > div { 
      width: 100px;
      height: 85px;
      background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 340"> \
          <path fill="%23fef1f3" fill-rule="evenodd" clip-rule="even-odd" d="M73.3,313c0,18.6,17.7,27.1,32.1,15.2c0,0,34.6-28.5,47.8-39.4c15.2,2.8,30.8,4.2,46.8,4.2 \
            c106,0,193.3-63.1,193.3-143.3S306,6.3,200,6.3S6.7,69.4,6.7,149.7c0,42.7,25,81.8,66.7,108.5C73.3,263.6,73.3,313,73.3,313 \
            L73.3,313z M100,243l-6.6-3.9c-38.1-22.3-60.1-54.9-60.1-89.5C33.3,86.3,107.2,33,200,33s166.7,53.3,166.7,116.7 \
            S292.8,266.3,200,266.3c-16.3,0-32.3-1.7-47.7-4.9l-6.3-1.3l-4.9,4.1L100,298.3V243z"/> \
          <path fill="%23fef1f3" fill-rule="evenodd" clip-rule="even-odd" d="M128,177c13.3,0,24-10.7,24-24s-10.7-24-24-24s-24,10.7-24,24S114.7,177,128,177z"/> \
          <path fill="%23fef1f3" fill-rule="evenodd" clip-rule="even-odd" d="M200,177c13.3,0,24-10.7,24-24s-10.7-24-24-24s-24,10.7-24,24S186.7,177,200,177z"/> \
          <path fill="%23fef1f3" fill-rule="evenodd" clip-rule="even-odd" d="M272,177c13.3,0,24-10.7,24-24s-10.7-24-24-24s-24,10.7-24,24S258.7,177,272,177z"/> \
        </svg>') no-repeat; 
    }
   
    @media screen and (hover: hover) {
      &:hover {
        background: #C24053;
        filter: drop-shadow(10px 10px 20px rgba(#EC6A83, .45))
                drop-shadow(-10px 0 20px rgba(#EC6A83, .45));

        > div { 
          background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 340"> \
              <path fill="%23fff" fill-rule="evenodd" clip-rule="even-odd" d="M73.3,313c0,18.6,17.7,27.1,32.1,15.2c0,0,34.6-28.5,47.8-39.4c15.2,2.8,30.8,4.2,46.8,4.2 \
                c106,0,193.3-63.1,193.3-143.3S306,6.3,200,6.3S6.7,69.4,6.7,149.7c0,42.7,25,81.8,66.7,108.5C73.3,263.6,73.3,313,73.3,313 \
                L73.3,313z M100,243l-6.6-3.9c-38.1-22.3-60.1-54.9-60.1-89.5C33.3,86.3,107.2,33,200,33s166.7,53.3,166.7,116.7 \
                S292.8,266.3,200,266.3c-16.3,0-32.3-1.7-47.7-4.9l-6.3-1.3l-4.9,4.1L100,298.3V243z"/> \
              <path fill="%23fff" fill-rule="evenodd" clip-rule="even-odd" d="M128,177c13.3,0,24-10.7,24-24s-10.7-24-24-24s-24,10.7-24,24S114.7,177,128,177z"/> \
              <path fill="%23fff" fill-rule="evenodd" clip-rule="even-odd" d="M200,177c13.3,0,24-10.7,24-24s-10.7-24-24-24s-24,10.7-24,24S186.7,177,200,177z"/> \
              <path fill="%23fff" fill-rule="evenodd" clip-rule="even-odd" d="M272,177c13.3,0,24-10.7,24-24s-10.7-24-24-24s-24,10.7-24,24S258.7,177,272,177z"/> \
            </svg>') no-repeat; 
        }
      }
    }
  }


  @keyframes enter-reviewers-arrow-right {
    0% {transform: scale(0);}
    100% {transform: scale(1);}
  }

  @keyframes enter-reviewers-arrow-left {
    0% {transform: rotateY(180deg) scale(0);}
    100% {transform: rotateY(180deg) scale(1);}
  }
  
  .arrow-left,
  .arrow-right {
    position: absolute;
    top: 150px;
    width: 60px;
    height: 60px;
    filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.2));
    box-shadow: none;
    transform: scale(0);

    @media screen and (min-width: 601px) and (max-width: 1150px) {
      width: 50px;
      height: 50px;
    }

    @media screen and (max-width: 600px) {
      top: 120px;
      width: 40px;
      height: 40px;
    }

    @media screen and (hover: hover) {
      &:hover {
        filter: drop-shadow(15px 15px 15px rgba(#EC6A83, .45));
      }
    }
  }
  
  .arrow-left {
    left: 5%;
        
    &.enter {
      animation: enter-reviewers-arrow-left .6s 1s both;
    }
  }

  .arrow-right {
    right: calc(5% + 17px);

    &.enter {
      animation: enter-reviewers-arrow-right .6s 1s both;
    }

    @media screen and (max-width: 600px) {
      right: 5%;
    }
  }

  @keyframes move-ending {
    0% {transform: translateX(0);}
    100% {transform: translateX(4vw);}
  }

  @keyframes exit-ending {
    0% {transform: translateY(0);}
    100% {transform: translateY(-40vh);}
  }

  /*
  #ending {
    position: relative;
    left: 0;
    top: 0;
    height: 1000px;

    @media screen and (max-width: 600px) {
      animation: move-ending 1s both;
    }

    > div {
      animation: exit-ending 1s 1s both; 
    }
  }

  @keyframes enter-end-lion {
    0% {transform: scale(0);}
    70% {transform: scale(1);}
    100% {transform: scale(1);}
  }

  #end-lion {
    position: absolute;
    top: calc(40vh - 12.5vw + 2.5vw); /* container is 2.5vw from top */
/*
    left: 34.8vw;
    width: 25vw;
    height: 25vw;
    background: url(../../images/avatar-lion.png) no-repeat;
    background-size: 100%;
    filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.2));

    animation: enter-end-lion 1s both;

    @media screen and (max-width: 1024px) {
      left: 36.8vw;
    }

    @media screen and (max-width: 600px) and (min-height: 600px) {
      left: 24vw;
      top: calc(40vh - 25vw + 2.5vw);
      width: 50vw;
      height: 50vw;
    }
  }

  @keyframes enter-end-stars {
    0% {transform: scale(2) translate3d(-10vh, 20vh, -100px) rotate(-50deg); opacity: 0;}
    100% {transform: scale(.7) translate3d(0, 0, -100px)rotate(0deg); opacity: 1;}
  }

  @keyframes enter-end-stars-portrait {
    0% {transform: scale(2) translate3d(-1.5vh, 5vh, -100px) rotate(-55deg); opacity: 0;}
    100% {transform: scale(.7) translate3d(0, 0, -100px)rotate(0deg); opacity: 1;}
  }

  #end-stars {
    position: absolute;
    top: calc(40vh - 45vw + 2.5vw); /* container is 2.5vw from top */
/*
    left: 7.3vw;
    width: 60vw;
    height: 80vw;
    transform: scale(2) translate3d(-10vh, 20vh, -100px) rotate(-50deg);
    transform: scale(.7) translate3d(0, 0, -100px)rotate(0deg); opacity: 1;
    transform-origin: center center; 
    filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.2));
    opacity: 0;
    
    animation: enter-end-stars 1s both;

    @media screen and (min-width: 601px) and (max-width: 1024px) {
      left: 9.3vw;
    }



    @media screen and (max-width: 600px) and (min-height: 600px) {
      /* animation-name: enter-end-stars-portrait; */
/*
      top: calc(40vh - 91vw + 2.5vw);
      left: -31.5vw;
      width: 120vw;
      height: 160vw;
    }

    .st0{fill:url(#SVGID_31_);}
    .st1{opacity:0.52;fill:#FFFFFF;}

    svg {
      height: 100%;
      width: 100%;
    }
  }
*/
}
