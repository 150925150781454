#resources-embed-modal-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 640px;
  max-height: 1400px;
  padding-bottom: 60px;

  > div {
    width: 100%;
    height: 100%;

    > div {
      width: 100%;
      height: 100%;
    }
  }

  &.page-aspect > div {
    position: relative;
    width: 100%;
    max-width: 1082px;
    height: initial;
    padding-bottom: 129.4%;

    > div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.exam {
    height: 2600px;
    max-height: initial;

    @media screen and (min-width: 601px) and (max-width: 900px) {
      height: 3800px;
    }

    @media screen and (max-width: 600px) {
      height: 4800px;
    }
  }
}

#form-modal-wrapper #form-modal-container.exam-modal-container {
  width: 100%;
  background:  #f7f7f7;
}

#resources-embed-modal-iframe {
  width: 100%;
  height: 100%;
  min-height: 640px;

  @media screen and (max-width:600px) {
    min-height: 450px;
  }
  
  &.tb-education {
    max-height: 1400px;
  }
}
