.toggle-switch-labeled-container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  max-width: 700px;
  height: 48px;
  margin: 0 10px 40px 10px;
  border: 2px solid #CA2939;
  border-radius: 24px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    transition: .6s;
    z-index: -2;
  }

  &.hover::before {
    box-shadow: 5px 5px 8px rgba(0, 0, 0, .3),
                5px -5px 8px rgba(0, 0, 0, .3),
                -5px -5px 8px rgba(0, 0, 0, .3);
  }
  
  label {
    flex: 1 50%;
    position: relative;
    display: inline-block;
    padding: 10px;
    color: #CA2939;
    font-family: #CA2939;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    border-radius: 24px;
    transition: all .6s ease;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background: #CA2939;
      transition: left .3ms cubic-bezier(0.77, 0, 0.175, 1);
      z-index: -1;
    }
  }

  input[type="radio"] {
    display: none;

    &.toggle-left + label {
      border-right: 0;

      &::after {
          left: 100%
      }
    }

    &.toggle-right + label{
      margin-left: -5px;

      &::after {
        left: -100%;
      }
    }

    &:checked + label {
      color: #fff;
      transition: .6s;
      cursor: default;

      &::after {
        left: 0;
      }
    }
  }
}
