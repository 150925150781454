.loader-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0;
  z-index: 999999999999;

  > div {
    flex: none;
    position: relative;
    width: 100px;
    max-width: 90%;
  }
}

.spinner {
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    animation: rotate 2s linear infinite;

    circle {
      /* stroke: #EC6A83; */ /*Defined in Loader.js */
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
}
