.contact-us-container {
  flex: 1;
  width: 100vw;
  margin-bottom: 100px;

  .pink-background {
    height: 1000px !important;
  }
  
  @keyframes enter-contact-us-page-heading {
    0% {transform: scale(2) translateY(-200px);}  
    100% {transform: scale(1) translateY(0);}  
  }

  .page-heading {
    position: relative;
    width: 100%;
    text-align: center;
    
    font-family: ArticoExtraBold;
    color: #ca2a39;
    font-size: 2rem;
    text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    z-index: 99;

    animation: enter-employment-page-heading 0.8s both;

    @media screen and (max-width: 1024px) {
      margin-top: 50px;
    }
  }

  .contact-us-content {
    position: relative;
    width: 100vw;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;

    > div {
      flex: none;

      &:first-of-type {
        margin-right: 80px;
      }
    }

    
    @media screen and (max-width: 1280px) {
      flex-flow: column nowrap;
      align-items: center;
      margin-top: 90px;

      > div:first-of-type {
        margin-right: 0;
      }
    }


    .map-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      > div {
        flex: none;
        width: 800px;
        max-width: 90%;
        margin-bottom: 80px;
      }
    }

    .form-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding: 40px 0 80px 0;
    }

    .map-container {
      width: 100%;
      padding: 0 20px;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      
      @media screen and (max-width: 1024px) {
        flex: none !important;
      }
    
      @media screen and (max-width: 600px) {
        padding: 0;
      }

      > div {
        width: calc(100% - 20px);
        display: flex;
        justify-content: center; /* center loader */
        align-items: center;
        background: #f7d8d9;
        height: 400px;
        margin: 0 10px;
        padding: 10px;
        border-radius: 15px;
        border: 4px solid #aa1d2b;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    
        @media screen and (min-width: 601px) and (max-width: 1024px) {
          width: calc(100% - 40px);
          margin: 0 20px;
        }
    
        @media screen and (max-width: 600px) {
          margin: 0 10px;
        }
      }
    }

    .location-address {
      width: 100%;
      margin-top: 40px;
      text-align: center; 
      color: #ca2a39;
      font-family: Effra; 
      font-size: 2.2rem;
      text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
      /* 
      font-family: ArticoExtraBold; 
      font-size: 2rem;
      text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3); 
      */

      @media screen and (min-width: 451px) and (max-width: 600px) {
        font-size: 1.6rem;
      }

      @media screen and (max-width: 450px) {
        font-size: 1.25rem;
      }
    }

    .contact-phone {
      padding: 30px 0 30px 60px;

      @media screen and (max-width: 600px) {
        padding: 30px 0 30px;
        text-align: center;
      }

      a,
      a:focus,
      a:visited {
        color: #ca2a39;
        font-family: Effra;
        font-size: 2rem;
        font-weight: 700;
        text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
        transition: .3s;
      }

      a:hover {
        color: #444;
      }
    }

    .stretching-svg-container {
      position: relative;
      width: 650px;
      max-width: 45%;
      margin: -50px 0 60px;
      perspective: 3000px;
      transform-style: preserve-3d;
      
      @media screen and (max-width: 1280px) {
        max-width: 90%;
      }

      .stretching-container {
        position: relative;
        width: 100%;

        .st0{fill:#F1F2F2;}
        .st1{clip-path:url(#SVGID_2_);fill:#D1D3D4;}
        .st2{clip-path:url(#SVGID_2_);fill:none;stroke:#6D6E71;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;}
        .st3{fill:#A7A9AC;stroke:#FFFFFF;stroke-width:6;stroke-miterlimit:10;}
        .st4{fill:none;stroke:#6D6E71;stroke-width:18;stroke-linecap:round;stroke-linejoin:round;}
        .st5{fill:#CA2939;}
        .st6{fill:#FF9E83;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;}
        .st7{fill:#FF9E83;}
        .st8{fill:#FFB483;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;}
        .st9{fill:#EC6A83;}
        .st10{fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;}
        .st11{fill:#FFFFFF;}
        .st12{fill:#E6E7E8;stroke:#000000;stroke-miterlimit:10;}
        .st13{fill:#E6E7E8;}
        .st14{fill:#FFB483;}
        .st15{fill:#EC6A83;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;}
        .st16{fill:#CA2939;stroke:#000000;stroke-miterlimit:10;}
        .st17{fill:#EC8583;}
        .st18{fill:#ECA595;}
        .st19{fill:#FFFFFF;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
        .st20{fill:#D1D3D4;}
        .st21{fill:none;stroke:#231F20;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}
        .st22{fill:#231F20;}
        .st23{fill:#CF0525;}
        .st24{fill:#6D6E71;}

        @keyframes enter-contact-us-trees {
          0% {opacity: 0; transform: scaleY(0);}
          100% {opacity: 1; transform: scaleY(1);}
        }

        #left-tree {
          animation: enter-contact-us-trees 1s .5s both;
          transform-origin: center 60%;
        }

        #middle-tree {
          animation: enter-contact-us-trees 1s 1s both;
          transform-origin: center 60%;
        }

        #right-tree {
          animation: enter-contact-us-trees 1s 1.5s both;
          transform-origin: center 60%;
        }

        @keyframes enter-contact-us-background-shape {
          0% {transform: rotateY(90deg);}
          100% {transform: rotateY(0deg);}
        }

        #background-shape {
          animation: enter-contact-us-background-shape 1s .25s both;
        }

        @keyframes enter-contact-us-ground {
          0% {opacity: 0; transform: translateY(100px);}
          100% {opacity: 1; transform: translateY(0);}
        }

        #ground {
          animation: enter-contact-us-ground 1s .5s both;
        }

        @keyframes enter-contact-us-lion {
          0% {opacity: 0; transform: scale(1.5) translate(100px, 150px);}
          100% {opacity: 1; transform: scaleY(1) translate(0, 0);}
        }

        &.stretching-lion {
          animation: enter-contact-us-lion 1s .75s both;
        }

        @keyframes enter-contact-us-patient {
          0% {opacity: 0; transform: scale(1.5) translate(-400px, 150px);}
          100% {opacity: 1; transform: scaleY(1) translate(0, 0);}
        }

        &.stretching-patient {
          animation: enter-contact-us-patient 1s .5s both;
        }

      }

      .stretching-park svg {
        filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, .15));
      }

      .stretching-lion svg {
        filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, .3));
      }

      .stretching-patient svg {
        filter: drop-shadow(5px 10px 15px rgba(0, 0, 0, 0.3));
      }

      .stretching-lion,
      .stretching-patient {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .form-container {
      width: 700px;
      max-width: 90%;

      .form-input-desc {
        color: darken(#e33a3a, 10%);
      }

      #success-message {
        font-size: 1.4rem;
      
        > div:first-of-type {
          font-size: 1.6rem;
          font-weight: 700;
          margin-bottom: 30px;
        }
      }
    }
  }
}


.directions-link {
  flex: none;
  position: absolute;
  left: 80px;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(2rem + 48px); /* 2rem = padding of address div and 48px = height of text */
  height: calc(2rem + 48px);
  background: #CA2939;
  /* border: 3px solid ${props => props.theme.colors.primary}; */
  border-radius: 40px; 
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);

  @media screen and (hover: hover) {
    &:hover {
      background: #CA2939;
      box-shadow: 0px 0px 20px #f0a0a3;
      /* background: #db878a; */
      /* box-shadow: 0px 0px 20px ${props => props.theme.colors.primaryShadow}; */
    }
  }

  @media screen and (min-width: 501px) and (max-width: 600px) {
    left: 60px;
  }

  @media screen and (max-width: 500px) {
    margin-left: 0;
    left: -10px;
  }

  > div {
    width: 40px;
    height: 50px;
    margin-left: 4px;

    background: #CA2939 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 57 70"> \
        <path stroke="%23fff" fill="%23fff" d="M56.4,38.6l-16-16C39.2,21.3,37,22.2,37,24v7h-2c-2.8,0-5.4,0.4-8,1.3V21h7c1.8,0,2.7-2.2,1.4-3.4l-16-16 \
          c-0.8-0.8-2-0.8-2.8,0l-16,16C-0.7,18.9,0.2,21,2,21h7v46c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V57c0-4.4,3.6-8,8-8h2v7 \
          c0,1.8,2.2,2.7,3.4,1.4l16-16C57.2,40.6,57.2,39.4,56.4,38.6z M41,51.2V47c0-1.1-0.9-2-2-2h-4c-6.6,0-12,5.4-12,12v8H13V19 \
          c0-1.1-0.9-2-2-2H6.8L18,5.8L29.2,17H25c-1.1,0-2,0.9-2,2v16.2c0,1.5,1.5,2.4,2.8,1.8c2.8-1.3,6-2,9.2-2h4c1.1,0,2-0.9,2-2v-4.2 \
          L52.2,40L41,51.2z"/> \
        <path id="hollow-center" fill="%23CA2939" d="M41,51.2V47c0-1.1-0.9-2-2-2h-4c-6.6,0-12,5.4-12,12v8H13V19c0-1.1-0.9-2-2-2H6.8L18,5.8L29.2,17H25 \
          c-1.1,0-2,0.9-2,2v16.2c0,1.5,1.5,2.4,2.8,1.8c2.8-1.3,6-2,9.2-2h4c1.1,0,2-0.9,2-2v-4.2L52.2,40L41,51.2z"/> \
      </svg>') no-repeat;
  }

  @media screen and (hover: hover) {
    &:hover > div {
      background: #CA2939 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 57 70"> \
        <path stroke="%23fff" fill="%23fff" d="M56.4,38.6l-16-16C39.2,21.3,37,22.2,37,24v7h-2c-2.8,0-5.4,0.4-8,1.3V21h7c1.8,0,2.7-2.2,1.4-3.4l-16-16 \
          c-0.8-0.8-2-0.8-2.8,0l-16,16C-0.7,18.9,0.2,21,2,21h7v46c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V57c0-4.4,3.6-8,8-8h2v7 \
          c0,1.8,2.2,2.7,3.4,1.4l16-16C57.2,40.6,57.2,39.4,56.4,38.6z M41,51.2V47c0-1.1-0.9-2-2-2h-4c-6.6,0-12,5.4-12,12v8H13V19 \
          c0-1.1-0.9-2-2-2H6.8L18,5.8L29.2,17H25c-1.1,0-2,0.9-2,2v16.2c0,1.5,1.5,2.4,2.8,1.8c2.8-1.3,6-2,9.2-2h4c1.1,0,2-0.9,2-2v-4.2 \
          L52.2,40L41,51.2z"/> \
        <path id="hollow-center" fill="%23CA2939" d="M41,51.2V47c0-1.1-0.9-2-2-2h-4c-6.6,0-12,5.4-12,12v8H13V19c0-1.1-0.9-2-2-2H6.8L18,5.8L29.2,17H25 \
          c-1.1,0-2,0.9-2,2v16.2c0,1.5,1.5,2.4,2.8,1.8c2.8-1.3,6-2,9.2-2h4c1.1,0,2-0.9,2-2v-4.2L52.2,40L41,51.2z"/> \
      </svg>') no-repeat;
    }
  }

  /*
  &:hover > div {
    background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 57 70"> \
      <path fill="%23fff" d="M56.4,41.4l-16,16C39.2,58.7,37,57.8,37,56v-7h-2c-4.4,0-8,3.6-8,8v10c0,1.1-0.9,2-2,2H11c-1.1,0-2-0.9-2-2V21H2 \
        c-1.8,0-2.7-2.2-1.4-3.4l16-16c0.8-0.8,2-0.8,2.8,0l16,16c1.3,1.3,0.4,3.4-1.4,3.4h-7v11.3c2.6-0.8,5.2-1.3,8-1.3h2v-7 \
        c0-1.8,2.2-2.7,3.4-1.4l16,16C57.2,39.4,57.2,40.6,56.4,41.4z"/> \
      </svg>') no-repeat;
  */
}
