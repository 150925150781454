.control-dots-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
  
  .control-dots {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;

    scroll-behavior: smooth;
    pointer-events: none; /* Oddly interferes with arrows */

    &.scroll-dots {
      /* width: 150px !important; /* now defined inline */
      height: 100px;
      clip-path: rect(0, 386px, 100px, -36px); /* Default for 7 dots... overridden by Component */
      transition: transform .5s ease-in;
    }

    .nav-dot {
      flex: none;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      padding: 6px;
      pointer-events: auto;

      > div {
        flex: none;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background: #555;
        box-shadow: 1px 1px 2px rgba(0,0,0,0.9);
        cursor: pointer;
        transition: width .5s, height .5s, opacity .25s ease-in;
        display: flex;
        justify-content: center;
        align-items: center;

        > img {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 2px solid #555;
          box-shadow: 5px 5px 20px 0px #929292;
          transition: .2s;
          z-index: 1;

          @media screen and (hover: hover) {
            &:hover {
              width: 90px;
              height: 90px;
              border: 3px solid #555;
              z-index: 99999;
            }
          }
        }
      }

      &.selected > div {
        opacity: 1;

        > img {
          width: 45px;
          height: 45px;
          border: 3px solid #DD4B60;
          box-sizing: content-box;
        }
    
        @media screen and (hover: hover) {
          &:hover {
            border: 3px solid #DD4B60;
          }
        }
      }

    }

    .sm-dot {

      > div {
        width: 28px;
        height: 28px;
      }

      
      &.selected > div {
        > img {
          width: 23px;
          height: 23px;
        }
      }

      &.left > div {
        margin-left: 5px; // Shift to keep space with next dot consistent while container size is the same and dot is smaller
      }

      &.right > div {
        margin-right: 5px;
      }
    }

    .xs-dot {

      > div {
        width: 18px;
        height: 18px;
      }

      &.selected > div {
        > img {
          width: 13px;
          height: 13px;
        }
      }

      &.left > div {
        margin-left: 20px;
      }

      &.right > div {
        margin-right: 20px;
      }
    }

    .no-dot {
      pointer-events: none;
      
      > div {
        width: 0;
        height: 0;
        opacity: 0;
        box-shadow: none;

        > img {
          border: none;
          box-shadow: none;
        }
      }

      &.selected > div {
        opacity: 0 !important;

        > img {
          width: 0;
          height: 0;
          border: none;
        }
      }

      &.left > div {
        margin-left: 26px;
      }

      &.right > div {
        margin-right: 26px;
      }
    }

    .selected {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }
}