$theme-color: #e33a3a;
$theme-color-SVG: '%23e33a3a';
$form-label-color: darken(#e33a3a, 10%); /* #ff7f8c; */
$form-label-float-color: #e33a3a;
$result-background-color: #ffd1d0;
$checkbox-color: #f34141;
$checklist-header-color: #ca2a39;

@keyframes rotate-open-form-modal-button {
	0% {transform: translateZ(1px) rotatex(15deg) rotateY(20deg);}
	50% {transform: translateZ(1px) rotatex(10deg) rotateY(-20deg);}
	100% {transform: translateZ(1px) rotatex(15deg) rotateY(20deg);}
}

@keyframes enter-open-form-modal-button-container {
	0% {transform: scale(0);}
	100% {transform: scale(1);}
}

.open-form-modal-button-container {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	margin-top: 80px;
	margin-bottom: 120px;
	animation: enter-open-form-modal-button-container 0.4s 8s both;
  pointer-events: auto;
  
	.shadow {
		width: 310px;
    height: 15px;
		box-shadow: 0 55px 40px rgba(0,0,0,.6);
	}
}

.open-form-modal-button {
	background: #ffd1d0; 
	border: 10px solid #e33a3a;
	color: #e33a3a;
	border-radius: 50px;
	font-size: 2rem;
	font-weight: 700;
	font-family: ArticoExtraBold;
	text-align: center;
	text-shadow: 15px 15px 15px rgba(0, 0, 0, 0.25);
	padding: 15px 40px;
	transform: translateZ(1px) rotatex(15deg) rotateY(10deg);
	transform-style: preserve-3d;
	animation: rotate-open-form-modal-button 10s both infinite;
	cursor: pointer;
	transition: 0.2s;

	@media screen and (max-width: 600px) {
		border: 5px solid #e33a3a;
		font-size: 1.5rem;
		padding: 10px 30px;
	}

  @media screen and (hover: hover) {
    &:hover {
      background: #e33a3a;
      border: 10px solid #ff7f8c;
      color: #ffeff2;

      @media screen and (max-width: 600px) {
        border: 5px solid #ff7f8c;
      }
    }
	}


	.sides {
    position: absolute;
    left: -7px;
    top: -7px;
		width: calc(100% + 14px);
		height: calc(100% + 14px);
    transform-style: preserve-3d;
		transform: translateZ(-1px);

		@media screen and (max-width: 600px) {
			left: -2px;
			top: -2px;
			width: calc(100% + 4px);
			height: calc(100% + 4px);
		}

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 10px solid #a20e19;
      border-radius: 50px;
    }

    @for $i from 1 through 24 {
      > div:nth-of-type(#{$i}) {
        transform: translateZ(#{-$i}px);
      }
    }

		> div:last-of-type {
			background: #e33a3a;
			left: -3px;
			top: -3px;
			width: calc(100% + 6px);
			height: calc(100% + 6px);
		}
  }
}



@keyframes enter-form-modal {
  0% {transform: scale(0);}
  100% {transform: scale(1);}
}

@keyframes exit-form-modal {
  0% {transform: scale(1);}
  100% {transform: scale(0);}
}

#form-modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(0,0,0,0);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
  transform: translateZ(1000px); /* Necessary for Safari */
  transition: 0.4s;

  #form-modal-container {
    position: absolute;
    top: 0;
    width: 900px;
    max-width: calc(100% - 40px); 
    margin: 20px;
    border: 20px solid #e33a3a;
    border-radius: 50px;
    background: #ffeff2; 

    @media screen and (max-width: 600px) {
      max-width: calc(100% - 10px);
      margin: 20px 5px;
      border: 15px solid #e33a3a;
    }
  }
 
  &.show {
    background-color: rgba(0,0,0,.8);

    #form-modal-container {
      animation: enter-form-modal 0.4s both;
    }
  }
   
  &.hide {
    background-color: rgba(0,0,0,0);

    #form-modal-container {
      animation: exit-form-modal 0.4s both;
    }  
  }
}




#form-modal-content {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding: 50px;
  width: 100%;  
  height: 100%;
  box-sizing: border-box;
  font-family: PoppinsMedium;
  z-index: 1;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    bottom: -40px;
    height: 20px;
  }
  
  @media screen and (max-width: 700px) {
      padding: 30px 15px 50px 15px;
  }
}


#form-modal-header {
  flex: none;
  color: #e33a3a;
  font-size: 2rem;
  margin-left: 24px;
}

.form-container {
  flex: none;
}

.form-row {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-top: 6px;
}
  
.form-row div {
  box-sizing: border-box;
  vertical-align: top;
}

.form-field {
  flex: 1;
  min-width: 250px;

  border: 2px solid $theme-color; /* #f55b6d; */
  border-radius:30px; /* 60px */
  margin: 24px 10px 0 10px;
  padding: 0 0 0 20px;
  background-color: rgba(227, 58, 58, .1); 
  box-shadow: 5px 10px 15px rgba(0,0,0,0.1);
}

/* @media only screen and (max-width: 680px) { */

.form-input-label-tag {
  position: relative;
  margin: auto;
  width:100%;
  max-width:280px;
  cursor:text;
  /* z-index:1; */
}

.form-input-desc {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  color: $form-label-color;
  font-weight: 500;
  text-shadow: 5px 5px 10px rgba(0,0,0,0.3);
  transform-origin: 0 0;
  transition: all 0.2s ease;
  z-index:-1;
  height:24px;
  /* overflow: hidden; */
}


.form-input-border {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: transparent;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: all 0.15s ease;
  /*z-index:1;*/
}

.form-input-field {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  height: 48px;
  font-size: 1rem;
  font-weight: 500;
  background: transparent;
  color: #666; /* ffbdfb */
  /* text-shadow: 1px 1px #333333; */
  transition: all 0.15s ease;
  z-index:99999;
  padding: 0 24px 0 0;
}

textarea.form-input-field {
  height: 96px;
  padding: 12px 24px 0 6px;
  resize: none;
}

#message-desc {
  top:-66px; 
}

.form-input-field:invalid {
  color:#f42121; /* rgb(255, 0, 128); */
}
  
.form-input-field:hover {
  background: transparent;
}

.form-input-field:not(:placeholder-shown) + span {
  color: #666;
  transform: translateY(-36px) scale(0.75);
}

.form-input-field[data-filled="Y"] + span {  /* .form-input-field:not(:placeholder-shown) does not work in MS Edge */
  color: #666;
  transform: translateY(-36px) scale(0.75);
}

.form-input-field:focus {
  background: none;
  outline: none;
}

.form-input-field:focus + span {
  color: $form-label-float-color;
  transform: translateY(-36px) scale(0.75);
}

.form-input-field:focus + span + .border {
  transform: scaleX(1);
}
  
.form-input-field:required {
  box-shadow:none;
}

.form-input-field:-webkit-autofill,
.form-input-field:-webkit-autofill:hover,
.form-input-field:-webkit-autofill:focus,
.form-input-field:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $theme-color;
}



/* Chrome and Safari */
#message::-webkit-scrollbar { /* width */
  width: 20px;
  left: 20px;
}
#message::-webkit-scrollbar-track, 
#message::-webkit-scrollbar-track {   /* Track */
  background: $result-background-color; /* #e9e9e9; */
  border-radius: 10px;
}
#message::-webkit-scrollbar-thumb, 
#message::-webkit-scrollbar-thumb {   /* Handle */
  background:$theme-color; 
  border-radius: 10px;
}
#message::-webkit-scrollbar-thumb:hover, 
#message::-webkit-scrollbar-thumb:hover {   /* Handle on hover */
  background: $theme-color; 
}


#message {
  cursor: auto;
  
  /* MS Edge */
  scrollbar-face-color: $theme-color; /* Originally different from others */
  scrollbar-shadow-color: $theme-color;
  scrollbar-highlight-color: $theme-color;
  scrollbar-3dlight-color: $theme-color;
  scrollbar-darkshadow-color: $theme-color;
  scrollbar-track-color: $theme-color;
  scrollbar-arrow-color: $theme-color;
  
  /* Firefox */
  scrollbar-color: $theme-color $result-background-color; /* first value is thumb, second is track */
}    



#form-submit-button,
.form-submit-button {
  width: 160px;
  margin-bottom: 48px;
  transition: 0.4s;

  @media screen and (hover: hover) {
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  &.employee-assistance {
    padding: 20px 0;
  }
}

#form-submit-button-background,
.form-submit-button-background {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 160px;
  background-color: $theme-color;
  border: 4px solid $theme-color;
  border-radius: 24px;
  font-size: 1.5rem;
  color:#efefef;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.2);
  transition: .4s;

  &.submitted {
    background: #efefef;
  }
}

/*
#submit-form-success,
#submit-form-error {
  display: none;
  border: 1px solid transparent;
  margin-top: 20px;
  font-size: 1.2rem;
  color: $form-label-color;
}
*/

@keyframes enter-submit-spinner {
  from {transform: scale(0);}
  to {transform: scale(1);}
}

@keyframes exit-submit-spinner {
  from {transform: scale(1);}
  to {transform: scale(0);}
}

#submit-spinner {
  position: relative;
  left: 10px;
  top: 10px;
  width: 100px;
  height: 100px;
  transform-origin: center center;
  animation: enter-submit-spinner 0.4s both;

  &.exit {
      animation: exit-submit-spinner 0.4s both;
  }
}




/* END FORM */

/*
#speech-bubble {
  position: absolute;
  display: block;
  width: 500px;
  height: 433px;
  left: 50%;
  top: 50%;
  transform: translate(-10%, -60%);
  z-index: -1;
  cursor: pointer;
  background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="500" height="433" viewBox="0 0 155.57 134.81"> \
          <path style="fill: #{$theme-color-SVG}" d="M157.16,63.26c0,33.81-34.83,61.23-77.79,61.23a97.3,97.3,0,0,1-20.81-2.22l-27.3,14.58L32.31,112C13.64,100.83,1.59,83.15,1.59,63.26,1.59,29.45,36.41,2,79.37,2S157.16,29.45,157.16,63.26Z" transform="translate(-1.59 -2.04)"/> \
          <path style="fill: #{$theme-color-SVG}" d="M157.16,63.26c0,33.81-34.83,61.23-77.79,61.23a97.3,97.3,0,0,1-20.81-2.22l-27.3,14.58L32.31,112C13.64,100.83,1.59,83.15,1.59,63.26,1.59,29.45,36.41,2,79.37,2S157.16,29.45,157.16,63.26Z" transform="translate(-1.59 -2.04)"/> \
      </svg>') no-repeat;

  opacity: 0.7;
  }
*/

  
.icon-close-modal {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50px" height="50px" viewBox="0 0 100 100"> \
    <path fill="#{$theme-color-SVG}" d="M50,5L50,5C25.1,5,5,25.1,5,50l0,0c0,24.9,20.1,45,45,45h0c24.9,0,45-20.1,45-45l0,0C95,25.1,74.9,5,50,5z M72.9,65.2 \
      c2.1,2.1,2.1,5.5,0,7.6c-1.1,1.1-2.4,1.6-3.8,1.6c-1.4,0-2.8-0.5-3.8-1.6L50.3,57.8L35.2,72.8c-1.1,1.1-2.4,1.6-3.8,1.6 \
      c-1.4,0-2.8-0.5-3.8-1.6c-2.1-2.1-2.1-5.5,0-7.6l15.1-15.1L27.6,35.1c-2.1-2.1-2.1-5.5,0-7.6c2.1-2.1,5.5-2.1,7.6,0l15.1,15.1 \
      l15.1-15.1c2.1-2.1,5.5-2.1,7.6,0c2.1,2.1,2.1,5.5,0,7.6L57.9,50.2L72.9,65.2z"/> \
  </svg>') no-repeat;
  background-size: 100%;
  filter: drop-shadow(12px 12px 12px rgba(0, 0, 0, 0.2));
  cursor: pointer;
  z-index: 9999;
}


/* CHECKBOXES */

.checklist-header {
  margin: 0 12px;
  font-size: 1.2rem;
  color: $checklist-header-color;
  text-shadow: 5px 5px 10px rgba(0,0,0,0.3);
  margin-top: 36px;
}

.checklist.form-row {
  display: flex;
  flex-flow: row nowrap;
  margin: 16px; 

  > div {
    text-shadow: 5px 5px 10px rgba(0,0,0,0.3);
  }

  > div:first-of-type {
    flex: none;
    margin-right: 12px;
  }

  > div:nth-of-type(2) {
    flex: 1;
  }
}

#goo-svg {
  height: 0;
}

@keyframes splash {
  40% {
    background: $checkbox-color;
    box-shadow: 
      0 -18px 0 -8px $checkbox-color, 
      16px -8px 0 -8px $checkbox-color, 
      16px 8px 0 -8px $checkbox-color, 
      0 18px 0 -8px $checkbox-color, 
      -16px 8px 0 -8px $checkbox-color, 
      -16px -8px 0 -8px $checkbox-color;
  }
  100% {
    background: $checkbox-color;
    box-shadow: 
      0 -36px 0 -10px transparent, 
      32px -16px 0 -10px transparent, 
      32px 16px 0 -10px transparent, 
      0 36px 0 -10px transparent, 
      -32px 16px 0 -10px transparent, 
      -32px -16px 0 -10px transparent;
  }
}

input[type="checkbox"] {
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;

  &:focus {
    outline: 0;
  }
}

.cbx {
  position: relative;
  width: 24px;
  height: 24px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border: 2px solid $checkbox-color;
    border-radius: 50%;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.12);
  }

  input:checked + label {
    animation: splash 0.6s ease forwards;
  }

  input:checked + label + svg path {
    stroke-dashoffset: 0;
  }

  label {
    width: 24px;
    height: 24px;
    background: none;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-filter: url("#goo");
    filter: url("#goo");
    transform: translate3d(0, 0, 0);
    pointer-events: none;
  }

  svg {
    position: absolute;
    top: 5px;
    left: 4px;
    z-index: 1;
    pointer-events: none;

    path {
      stroke: #fff;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 19;
      stroke-dashoffset: 19;
      transition: stroke-dashoffset 0.3s ease;
      transition-delay: 0.2s;
    }
  }
}

#success-message {
  font-size: 1.4rem;
  margin-bottom: 60px;

  @media screen and (max-width: 600px) {
    padding: 20px;
  }

  > div:first-of-type {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 30px;
  }
}

@keyframes enter-form-submit-error {
  from {transform: scale(0);}
  to {transform: scale(1);}
}

#icon-error {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 90 112.5"> \
      <path fill="#{$theme-color-SVG}" d="M45,0C20.1,0,0,20.1,0,45c0,24.9,20.1,45,45,45s45-20.1,45-45C90,20.1,69.9,0,45,0z M44.6,10.8c4-0.2,7.3,2.9,7.5,6.8 \
        c0,0.2,0,0.5,0,0.7l-1.7,34.3c-0.1,3-2.7,5.4-5.8,5.2c-2.8-0.1-5.1-2.4-5.2-5.2l-1.7-34.3C37.6,14.4,40.7,11,44.6,10.8z M50.3,77 \
        c-1.4,1.4-3.3,2.2-5.3,2.2c-2,0-3.9-0.8-5.3-2.2c-1.4-1.4-2.2-3.3-2.2-5.3s0.8-3.9,2.2-5.3c1.4-1.4,3.3-2.2,5.3-2.2 \
        c2,0,3.9,0.8,5.3,2.2c1.4,1.4,2.2,3.3,2.2,5.3S51.7,75.6,50.3,77z"/> \
    </svg>') no-repeat;
  transform: translateY(16px);
}

#form-submit-error {
  font-size: 1.4rem;
  transform-origin: left center;
  animation: enter-form-submit-error 0.4s both;
}
