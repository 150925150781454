// @import "../../_variables.scss";

/* BEGIN HEADER */
#header {
  position: relative;
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  font-family: PoppinsMedium;
  background-color: transparent;
  z-index: 200;

  #logo {
    flex: none;
    width: 246px;
    max-width: 50%;

    
    img {
      width: 100%;  
      filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.15));
    }
  }

  .menu-links ul {
    display: grid;
    width: 800px;
    max-width: calc(100% - 240px);
    grid-template-columns: 1fr 1fr 1fr;
    list-style-type: none;

    li > a {
      color: #333333;
    }
  }

  .social-container {
    position: absolute;
    right: 162px;
    top: 40px;

    @media screen and (max-width: 850px) {
      display: none;
    }
  }
}
/* END HEADER */

/* BEGIN OPEN/CLOSE MENU */
#side-menu-container {
  position: absolute;
  top: 0;
  width: 100%; /* So scrollbar takes place of disabled body scrollbar on right */
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  opacity: 1;
  pointer-events: none;
  z-index: 99999;
  transform: translateZ(200px); /* To be above 3d animation on Qualifying for Care page */
  }

#side-menu-container.show,
#side-menu-container.hide {
  position: fixed;
  top: 0; 
  bottom: 0;
  height: 100%; /* 100vh doesn't adust on iOs for browser address and bottom bars */
  pointer-events: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

#side-menu {
  position: absolute;
  width: 0;
  height: 0; /* Important: Can't Be 100vh or won't scroll in iOs */
  right: -350px;
  // background-color: #fff;
  border-radius: 0 0 2000px 0;
  pointer-events: auto;
  z-index: 10000; /* Above fade screen */
  transform: translateZ(100px) scale(0);
}

#side-menu-background {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 1px;
  height: 1px;
  z-index: 1;
  
  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color:#fff;
    
    // screen diameter can be 142vmax at most,
    // circle needs to be twice that size to cover it
    /*
    width: 284vmax;
    height: 284vmax;
    top: -142vmax;
    left: -142vmax;
    */

    width: 63vmax;
    max-width: 1100px;

    height: 140vmax;
    max-height: 2444px;
    top: -50vmax;
    right: -50vmax;
/*
    transform: scale(0);
    transform-origin: right top;
    transition: transform .5s cubic-bezier(0.755, 0.050, 0.855, 0.060);
    will-change: transform;
*/
    box-shadow: 4px 4px 15px #999;

    #side-menu-container.show & {
      transform: translateZ(100px) scale(1);
    }
  }
}
 
#side-menu-container.show #side-menu {
  right: 0;
  width: 350px;
  height: 100%;
  transform-origin: right top;
  transform: translateZ(100px) scale(1);
  transition: transform 0.6s;
}

#side-menu-container.hide #side-menu {
  right: -350px;
  transition: 0.3s;
}

.menu-links {
  padding: 22px;
  pointer-events: auto;

  display: none; /* Client doesn't want it */

  @media screen and (max-width: 920px) {
    display: none;
  }
}


#fade-screen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  min-width: 320px;
  height: 100%; /* 100vh doesn't adust on iOs for browser address and bottom bars */
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s;
  backface-visibility: hidden; /* Helps reduce flicker on opacity transition */
  pointer-events: none;
  z-index: 9999;
  transform: translateZ(1px);
}

#fade-screen.show {
  opacity: 1;
  pointer-events: auto;
}

#close-menu-icon {
  position: absolute;
  top: 22px;
  left: 22px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 3; 
  
  > div {
    height: 24px;
    width: 1pt;
    margin-right: 12px;
    background-color:#B6B6B6; /* */
    transform: rotate(45deg);

    > div {
      height: 24px;
      width: 1pt;
      background-color:#B6B6B6;
      transform: rotate(90deg);
    }
  } 

  &:hover {
    > div {
      background-color: #d43345;
      transform: rotate(225deg);
      transition: 0.3s;
      > div {
        background-color: #d43345;
        transform: rotate(270deg);
        transition: 0.3s;
      }
    }
  }
}
/* END OPEN/CLOSE MENU */

#menu-logo {
  margin-bottom: 14px;

  img {
    width: 125px;
    height: 125px;
  }
}

#burger-menu-container {
  position: absolute;
  display: flex;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 600px) {
    right: 40px;
  }

  #menu-word {
    flex: none;
    /* margin-right: 40px; */
    font-family: PoppinsMedium;
    font-size: 1.5rem;
    color: #ca2a39;
    cursor: pointer;
    text-transform: lowercase;
/*
    @media screen and (max-width: 600px) {
      display: none;
    }
*/
  }

  &:hover #menu-word {
    color: #6d6e70;
  }

  #burger-menu {
    flex: none;
    /* display: none;  */
    display: flex; /* Client wants it */
    /* right: 40px;  */
    right: 60px;
    /*left: 380px; */
    width: 50px;
    height: 38px;
    border-top: 4px solid #d43345;
    border-bottom: 4px solid #d43345;
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,.12));
    border-radius: 2px;
    align-items: center;
    cursor: pointer;
  
    @media screen and (max-width: 600px) {
      display: flex;
      left: initial;
      right: 40px;
    }
  
    > div {
      flex: none;
      width: 100%;
      border-top: 4px solid #d43345;
      border-radius: 2px;
      filter: drop-shadow(5px 5px 5px rgba(0,0,0,.12));
    }
  }

  &:hover #burger-menu {
    border-top: 4px solid #6d6e70;
    border-bottom: 4px solid #6d6e70;
    
    > div {
      border-top: 4px solid #6d6e70;
    }
  }
}





/* BEGIN ACCORDION SUBMENUS */

#side-menu-links {
  position: relative;
  font-family: PoppinsMedium;
  padding-top: 62px;
  transform: translateZ(110px);
  z-index: 2;
}


#side-menu-links ul li {
  opacity: 0;
  transition: all .3s cubic-bezier(0.000, 0.995, 0.990, 1.000) .3s;
}

@for $i from 1 through 9 {
  #side-menu-links ul li:nth-child(#{$i}){
    transform: translateY(-40px * $i);
  }
}

#side-menu-container.show #side-menu-links ul li {
  opacity: 1;
  transform: translateY(0);
}

#side-menu-links ul,
#side-menu-links ul li,
#side-menu-links ul li > a {
  display: block;
  position: relative;
  margin: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  text-align: center;
}

#side-menu-links ul,
#side-menu-links ul li {
  padding: 0;
}

#side-menu-links ul li {
  padding: 16px 22px;
  z-index: 2;
  font-size: 1rem;
  color: #75797C;
  cursor: pointer;

  a {
    font-size: 14pt;
    color: #75797C;
    text-decoration: none;
    transition: color .2s ease;

    @media screen and (hover: hover) {
      &:hover {
        color: #d43345;
      }
    }
  }
}


@keyframes open-menu {
  0% {max-height: 0}
  99% {max-height: 80px}
  100% {max-height: 1000px} /* In case has another submenu, needs to be tall enough to accomodate it */
}

@keyframes close-menu {
  0% {max-height: 80px}
  100% {max-height:0}
}



/* END ACCORDION SUBMENUS */


.menu-links {
  a {
    position: relative; 
    color: #333333;
    text-decoration: none;
  }
  
  a:hover {
    color: #dc3545 !important;
    transition: 0.2s;
  }

  a::after {    
    content: "";
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    display: block;
    height: 2px;
    right: 50%;
    position: absolute;
    background:#d43345;
    transition: width 0.3s ease 0s, right 0.3s ease 0s;
    width: 0;
  }
  a:hover::after { 
    width: 100%; 
    right: 0; 
  }
}

.languages {
  position: absolute;
  width: 0;
  height: 30px;
  right: 26px;
  top: 4px;
  color: #6d6e70;
  overflow: hidden;
  transition: 0.3s;

  a,
  a:focus,
  a:visited {
    color: #ca2a39;
    text-decoration: none;
  }

  a:hover {
    color: #6d6e70;
  }
}

.globe-icon {
  position: absolute;
  right: 364px;
  top: 46px;
  width: 30px;
  height: 30px;
  background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 64 80" x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
    <path fill="%23ca2a39" d="M32,4A28,28,0,1,0,60,32,28,28,0,0,0,32,4ZM53.81,22H44.28a36.33,36.33,0,0,0-7.1-13.43A24.09,24.09,0,0,1,53.81,22ZM31.89,55.06h0A32.44,32.44,0,0,1,23.9,42H40.1a32.44,32.44,0,0,1-8,13.06A.18.18,0,0,1,31.89,55.06ZM22.89,38a33,33,0,0,1,0-12H41.11a33,33,0,0,1,0,12ZM8,32a23.77,23.77,0,0,1,.76-6H18.83a37,37,0,0,0,0,12H8.76A23.77,23.77,0,0,1,8,32ZM32,8.9a.15.15,0,0,1,.11,0A32.44,32.44,0,0,1,40.1,22H23.9a32.44,32.44,0,0,1,8-13.06A.15.15,0,0,1,32,8.9ZM45.17,26H55.24a24.06,24.06,0,0,1,0,12H45.17a37,37,0,0,0,0-12ZM26.82,8.57A36.48,36.48,0,0,0,19.72,22H10.19A24.09,24.09,0,0,1,26.82,8.57ZM10.19,42h9.53a36.33,36.33,0,0,0,7.1,13.43A24.09,24.09,0,0,1,10.19,42Zm27,13.43A36.48,36.48,0,0,0,44.28,42h9.53A24.09,24.09,0,0,1,37.18,55.43Z"/> \
  </svg>') no-repeat;
  background-size: 100%;

  @media screen and (min-width: 601px) and (max-width: 850px) {
    right: 162px;
  }

  @media screen and (max-width: 600px) {
    top: 86px;
    right: 40px;
  }

  &:hover .languages {
    width: 144px;
    right: 32px;
  }
}