$theme-color: #e33a3a;
$theme-color-SVG: '%23e33a3a';
$form-label-color: #ff7f8c;
$form-label-float-color: #e33a3a;
$result-background-color: #ffd1d0;
$checkbox-color: #f34141;

@keyframes enter-meeting-modal {
  0% {transform: scale(0);}
  100% {transform: scale(1);}
}

@keyframes exit-meeting-modal {
  0% {transform: scale(1);}
  100% {transform: scale(0);}
}

@keyframes enter-meeting-modal-background {
  0% {background-color: rgba(0,0,0,0);}
  100% {background-color: rgba(0,0,0,0.9);}
}

@keyframes exit-meeting-modal-background {
  0% {background-color: rgba(0,0,0,.9);}
  100% {background-color: rgba(0,0,0,0);}
}

#meeting-modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(0,0,0,0);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
  transform: translateZ(1000px); /* Necessary for Safari */
  transition: .4s;
  pointer-events: auto;
  
  #meeting-modal-container {
    position: absolute;
    top: 0;
    width: 900px;
    max-width: calc(100% - 40px); 
    /* border: 20px solid #e33a3a; */
    border-radius: 50px;
    /* background: #ffeff2; */
    pointer-events: auto;
    transition: .4s;
    
    @media screen and (min-width: 1320px) {
      width: 1320px;
    }

    @media screen and (max-width: 912px) {
      left: 0;
      margin: 20px;
    }
  }
 
  &.show {
    /* background-color: rgba(0,0,0,.9); /* rgba(255, 189, 189, .8); */
    animation: enter-meeting-modal-background  .4s both;

    #meeting-modal-container {
      animation: enter-meeting-modal 0.4s both;
    }
  }
   
  &.hide {
    /*background-color: rgba(0,0,0,0); */
    animation: exit-meeting-modal-background 0.4s both;

    #meeting-modal-container {
      animation: exit-meeting-modal 0.4s both;
    }  
  }
}

#meeting-modal-content {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 50px;
  width: 100%;  
  height: 100%;
  box-sizing: border-box;
  font-family: PoppinsMedium;
  white-space: pre-line;
  z-index: 1;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    bottom: -40px;
    height: 20px;
  }
  
  @media screen and (max-width: 700px) {
      padding: 30px 15px 150px 15px;
  }
}


#meeting-modal-header,
#meeting-modal-subheader {
  flex: none;
  align-self: center;
  color: #fcfcfc; /* #e33a3a; */
  font-family: ArticoExtraBold;
  font-size: 2.6rem;
  text-align: center;

  text-shadow: 15px 15px 15px rgba(0, 0, 0, .6);
  pointer-events: auto;
}

#meeting-modal-header {
  @media screen and (max-width: 600px) {
    margin-top: 100px;
  }
}

#meeting-modal-subheader {
  color: #dddcdc;
  font-size: 2rem;
  margin-bottom: 40px;
}

#meeting-modal-date {
  color: #fcfcfc;
  font-family: ArticoExtraBold;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 40px;
  text-shadow: 15px 15px 15px rgba(0, 0, 0, .6);
}

.meeting-modal-copy {
    position: relative;
    width: 800px;
    max-width: 100%;
    min-height: 300px;
    border-radius: 175px;
    background: #6528b9;/* #4f1e91; */ /* #e33a3a; */
    color: lighten(#4f1e91, 52%); /* #ffd1d0; */
    padding: 100px;
    margin-bottom: 50px;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.6);

    @media screen and (max-width: 600px) {
      border-radius: 100px;
      padding: 100px 25px;
    }
}

.icon-close-modal-top,
.icon-close-modal-bottom {
  position: absolute;
  width: 50px;
  height: 50px;
  background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50px" height="50px" viewBox="0 0 100 100"> \
    <path fill="%23732cd2" d="M50,5L50,5C25.1,5,5,25.1,5,50l0,0c0,24.9,20.1,45,45,45h0c24.9,0,45-20.1,45-45l0,0C95,25.1,74.9,5,50,5z M72.9,65.2 \
      c2.1,2.1,2.1,5.5,0,7.6c-1.1,1.1-2.4,1.6-3.8,1.6c-1.4,0-2.8-0.5-3.8-1.6L50.3,57.8L35.2,72.8c-1.1,1.1-2.4,1.6-3.8,1.6 \
      c-1.4,0-2.8-0.5-3.8-1.6c-2.1-2.1-2.1-5.5,0-7.6l15.1-15.1L27.6,35.1c-2.1-2.1-2.1-5.5,0-7.6c2.1-2.1,5.5-2.1,7.6,0l15.1,15.1 \
      l15.1-15.1c2.1-2.1,5.5-2.1,7.6,0c2.1,2.1,2.1,5.5,0,7.6L57.9,50.2L72.9,65.2z"/> \
  </svg>') no-repeat;
  background-size: 100%;
  pointer-events: auto;
  filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.6));
  cursor: pointer;
}

.icon-close-modal-top {
  top: 30px;
  right: 30px;
}

.icon-close-modal-bottom {
  bottom: 30px;
  right: 30px;

  @media screen and (max-width: 700px) {
    bottom: 120px;
  }
}
