#month-picker {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;

  @media screen and (max-width: 900px) {
    justify-content: center;
  }
  
  width: 100%;
  z-index: 1;

  > div {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 900px;/* Must match logo div width */
  }

  > div > div {
    position: relative;
    top: 0;
    width: 350px;
    height: 350px;
    transition: .3s;
  }

  &.show > div > div {
    top: -280px;

    @media screen and (max-width: 900px) {
      top: -270px;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }

  #months {
    transform: rotate(-315deg);
    transition: .3s;

    @media screen and (max-width: 900px) {
      transform: rotate(-320deg);
    }

    text {
      font-family: Effra;
      font-size: .33rem;
      font-weight: 700;
      text-transform: uppercase;
      fill: #fff; /* #ca2a39; */
      user-select: none;
      pointer-events: auto;

      &:not(.disabled) textPath:hover {
        fill: #6d6e70;
        cursor: pointer;
      }

      &.disabled {
        opacity: .3;

        textPath:hover {
          cursor: initial;
        }
      }
    }

    #ring {
      fill: none;
      stroke: #4f1e91; /* #f4cbcf; */
      stroke-width: 10px;
      fill: #dddcdc;
    }
  }

  &.show #months {
    transform: rotate(-45deg);

    @media screen and (max-width: 900px) {
      transform: rotate(-50deg);
    }
  }

  #picker-center {
    position: absolute;
    top: 200px;
    left: 0;
    font-family: ArticoExtra;
    pointer-events: none;
    transition: .3s;
    
    font-weight: 700;
    
    fill: #4f1e91; /* #ca2a39; */

    #year {
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    #safety-committee,
    #meeting-minutes {
      font-family: Effra;
      font-size: .24rem;
      text-transform: uppercase;
    }
  }

  &.show #picker-center {
    top: 0;
  }

  .icon-close {
    position: absolute;
    bottom: 85px;
    left: 166px;
    width: 25px;
    height: 25px;
    background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50px" height="50px" viewBox="0 0 100 100"> \
      <path fill="%236d6e70" d="M50,5L50,5C25.1,5,5,25.1,5,50l0,0c0,24.9,20.1,45,45,45h0c24.9,0,45-20.1,45-45l0,0C95,25.1,74.9,5,50,5z M72.9,65.2 \
        c2.1,2.1,2.1,5.5,0,7.6c-1.1,1.1-2.4,1.6-3.8,1.6c-1.4,0-2.8-0.5-3.8-1.6L50.3,57.8L35.2,72.8c-1.1,1.1-2.4,1.6-3.8,1.6 \
        c-1.4,0-2.8-0.5-3.8-1.6c-2.1-2.1-2.1-5.5,0-7.6l15.1-15.1L27.6,35.1c-2.1-2.1-2.1-5.5,0-7.6c2.1-2.1,5.5-2.1,7.6,0l15.1,15.1 \
        l15.1-15.1c2.1-2.1,5.5-2.1,7.6,0c2.1,2.1,2.1,5.5,0,7.6L57.9,50.2L72.9,65.2z"/> \
    </svg>') no-repeat;
    background-size: 100%;
    filter: drop-shadow(12px 12px 12px rgba(0, 0, 0, 0.2));
    opacity: .4;
    pointer-events: auto;
    cursor: pointer;

    &:hover {

      opacity: 1;
    }
  }

  .picker-arrow-right,
  .picker-arrow-left {
    flex: none;
    position: absolute;
    top: 152px;
    width: 32px;
    height: 32px;
    transform: translateZ(10px);
    padding: 7px 0;
    border-radius: 50%;
    /* #fef1f3 */
    background: transparent url('data:image/svg+xml;utf8,<svg width="36px" height="36px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
        <path fill="%236d6e70" d="M67.36,50v0.19c0,1.57-0.62,3.08-1.73,4.19L46.74,73.27c-2.31,2.31-6.06,2.31-8.37-0.01 \
          c-2.32-2.31-2.31-6.06,0-8.37L53.27,50l-14.9-14.89c-2.31-2.31-2.32-6.06-0.01-8.37c2.32-2.32,6.07-2.32,8.38-0.01l18.89,18.89 \
          C66.79,46.78,67.42,48.36,67.36,50z"/> \
      </svg>') no-repeat;
    background-size: 100%;
    opacity: .4;
    pointer-events: auto;
    cursor: pointer;
    transition: .2s;
    z-index: 2;

    @media screen and (hover: hover) {
      &:hover {
        opacity: 1;
      }
      
    }

    &.disabled {
      /* fill="%23eca5b0" */
      opacity: .2;
      cursor: initial;
    }

    @media screen and (max-width: 600px) {
      margin: 0 5px;
    }
  }

  .picker-arrow-left {
    left: 60px;
    transform: translateZ(10px) rotateY(180deg);
  }

  .picker-arrow-right {
    right: 60px;
  }
}

// VARIABLES
$month-size: 350px;
$h1-size: 25px;
$h2-size: 12px;

// MIXINS
@mixin rotated-text($num-letters: 100, $angle-span: 180deg, $angle-offset: 0deg) {
	$angle-per-char: $angle-span / $num-letters;
	@for $i from 1 through $num-letters {
		.char#{$i} {
			transform: rotate($angle-offset + $angle-per-char * $i);
	  }
	}
}


// TYPICAL ELEMENTS
h1 {
	color: #fff;
	font-size: 25px;
}

h2 {
	color: #fff;
	font-size: 15px;	
}


.month-dial {
  position: absolute;
  top: 50%;
  left: 50%;
	transform: translateX(-50%) translateY(-50%);
  width: $month-size;
	height: $month-size;
	transition: .5s;	
}

.month-preview span {
	position: absolute;
  top: calc(-25% + 20px);
  left: calc(50% - 12.5px);
	height: $month-size;
	width: $h1-size;	
  text-align: center;
	transform-origin: center center;
}

.month-text span {
  position: absolute;
  top: calc(-25% + 30px);
  left: calc(50% - 6px);
	height: 332px;
	width: $h2-size;
  text-align: center;
	transform-origin: center center;
}

.month-text {
	@include rotated-text($num-letters: 48, $angle-span: 270deg, $angle-offset: -135deg);
}

.ring-back {
	opacity: .1;
  width: 100%;
  height: 100%;
	border: solid 10px transparent;
	border-radius: 50%;
	
	&::before {
    content: " ";
    position: absolute;
    top: 5px;
    left: 5px;
    bottom: 5px;
    right: 5px;
		border-radius: 50%;
		border: 35px solid #fff;	
	}
}

.ring {
  position: relative;
  top: -100%;
  width: 100%;
  height: 100%;
	transition: .5s;
	border: 45px solid #202020;
	border-radius: 50%;
	border-bottom-color: transparent;
	box-shadow: 0px -2px 2px #000;
}
