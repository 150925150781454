@use "sass:math";

$services-rotation-speed: 160s;
$services-rotation-speed-mobile: 80s;

#services-container {
  flex: 1;
  top: -120px; /* Height of header */
  width: 100%;
  min-height: 800px;
  /* flex-basis: 800px; */
   /* height: 800px; /* services-circle container (height (50vw - 8px) + top 400px)  // 100%; *//* services-circle-container */
  overflow: hidden;

  @media screen and (max-width: 600px) {
    /* height: 775px; /* 400px + 375px */  
    /* flex-basis: 775px; */
    min-height: 775px;
  }

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    /* height: 900px; /* 400px + 500px */ 
    /* flex-basis: 900px; */
    min-height: 900px;
  }
}

#services-circle-container {
  position: absolute;
  top: -50px;
  left: -20px;
 
  /*
  width: 400px;
  height: 400px;
  */
  z-index: 201; // above header

  /* Before including inside ThreeJs Canvas */
    /*
    left: calc(50vw - 8px);
    top: 400px;
    
    @media screen and (min-width: 601px) and (max-width: 1024px) {
      top: 500px;
    }

    @media screen and (max-width: 600px) {
      top: 375px;
    }
    */

  &.unclickable .services {
    pointer-events: none;
  }

  path {
    -webkit-filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.3));
  }

  @keyframes services-circle-container-enlarge {
    0% {transform: scale3d(1, 1, 1)}
    100% {transform: scale3d(1.6, 1.6, 1.6)}
  }

  @keyframes services-enlarge {
    0% {transform: scale3d(1)}
    100% {transform: scale3d(#{math.div(1, 1.6)}, #{math.div(1, 1.6)}, #{math.div(1, 1.6)})}
  }

  @keyframes services-circle-container-shrink {
    0% {transform: scale3d(1.6, 1.6, 1.6)}
    100% {transform: scale3d(1, 1, 1)}
  }

  @keyframes services-shrink {
    0% {transform: scale3d(#{math.div(1, 1.6)}, #{math.div(1, 1.6)}, #{math.div(1, 1.6)})} 
    100% {transform: scale3d(1, 1, 1)}
  }

  transform-origin: left top;

  &.enlarge {
    animation: services-circle-container-enlarge 1s both;
    
    .services {
      transform-origin: center center;
      
      svg {    
        animation: services-enlarge 1s both;
      }
    }
  }

  &.shrink {
    animation: services-circle-container-shrink .75s both;

    .services svg {
      animation: services-shrink .75s both;
    }
  }
}

.services {
  position: absolute;
  width: 220px;

  .scaling {
    position: relative;
    transform: scale3d(1.2, 1.2, 1.2);
    z-index: 2;

    & #waiver {
      transform: scale3d(#{1.2*0.8}, #{1.2*0.8}, #{1.2*0.8});
      transform-origin: 100% 75%;

      @media screen and (min-width: 601px) {
        position: relative;
        /*
        top: 5px;
        */
      }
    }
  /*
    & #hoyer-lift {
      transform: scale(#{1.2*0.9});
    }
  */
    & #military {
      transform: scale3d(#{1.2*1.125}, #{1.2*1.125}, #{1.2*1.125});
      transform-origin: 47% 40%;
      @media screen and (min-width: 601px) {
        position: relative;
        /* 
        top: 7px;
        left: 2px;
        */
      }
    }
  }

  svg {
    width: 100%;

    path {
      fill: none;
      stroke:#000000;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
  }
}


$services-circle-diameter: 250px;
$button-count: 7;
$button-size: 180px;
$button-circle-size: 475px;

$services-circle-diameter-mobile: 175px;
$button-size-mobile: 100px;
$button-circle-size-mobile: 275px;

#services-circle {
  position: relative;
  width: $services-circle-diameter;
  height: $services-circle-diameter;
  border-radius: 50%;
  z-index: 800;

  @media screen and (max-width: 600px) {
    width: $services-circle-diameter-mobile;
    height: $services-circle-diameter-mobile;
  }

  @keyframes grow-services-circle {
      from {transform: translate(-50%,-50%) scale3d(0, 0, 0);}
      to {transform: translate(-50%,-50%) scale3d(1, 1, 1)}
  }

  @keyframes grow-services-circle-mobile { /* Duplicates to restart animation on resize */
    from {transform: translate(-50%,-50%) scale3d(0, 0, 0);}
    to {transform: translate(-50%,-50%) scale3d(1, 1, 1);}
  }

  @keyframes rotate-services-circle {
      from {transform: translate(-50%,-50%) rotate(0deg);}
      to {transform: translate(-50%,-50%) rotate(360deg);}
  }

  @keyframes rotate-services-circle-mobile {  /* Duplicates to restart animation on resize */
    from {transform: translate(-50%,-50%) rotate(0deg);}
    to {transform: translate(-50%,-50%) rotate(360deg);}
  }

  transform: translate(-50%,-50%);

  animation: rotate-services-circle $services-rotation-speed 0.4s linear infinite;

  @media only screen and (max-width: 600px) {
    animation:  rotate-services-circle-mobile $services-rotation-speed-mobile 0.4s linear infinite; /* Make it spin faster as a couple of icons will be off screen on phones */
  }

  /* iOs Safari click area gets screwed up with scaling */
  @media only screen and (hover: hover) {
    animation:  grow-services-circle 0.4s forwards, 
                rotate-services-circle $services-rotation-speed 0.4s linear infinite;

    @media only screen and (max-width: 600px) {
        animation:  grow-services-circle-mobile 0.4s forwards, 
                    rotate-services-circle-mobile $services-rotation-speed-mobile 0.4s linear infinite; /* Make it spin faster as a couple of icons will be off screen on phones */
    }
  }

  transform-origin: 50% 50%;

  .services,
  .action {
    display: block;
    position: absolute;
    top: 50%; 
    left: 50%;
    margin: -#{math.div($button-size, 2)};
    width: $button-size;
    height: $button-size;
    outline: none;
    z-index: 800;

    &:hover {
      z-index: 900;
    }

    @media screen and (max-width: 600px) {
      margin: -#{math.div($button-size-mobile, 2)};
      width: $button-size-mobile;
      height: $button-size-mobile;
    }
  }

  .action {
    cursor: pointer;
  }

  .hover .action {
    height: #{$button-size + 20}; /* Cover the gap between action area and service label */
  }

  .services {
    $angle: math.div(360, $button-count); 
    $rotation: 0;

    @for $i from 1 through $button-count {

      
        @keyframes #{'inner-circle-' + $i} {
            from {transform: rotate($rotation * 1deg) translate(math.div($button-circle-size, 2)) rotate($rotation * -1deg) rotate(0deg);}
            to {transform: rotate($rotation * 1deg) translate(math.div($button-circle-size, 2)) rotate($rotation * -1deg) rotate(-360deg);}
        }

        @keyframes #{'inner-circle-mobile-' + $i} {
          from {transform: rotate($rotation * 1deg) translate(math.div($button-circle-size-mobile, 2)) rotate($rotation * -1deg) rotate(0deg);}
          to {transform: rotate($rotation * 1deg) translate(math.div($button-circle-size-mobile, 2)) rotate($rotation * -1deg) rotate(-360deg);}
        }

        &:nth-of-type(#{$i}) {
            animation: #{'inner-circle-' + $i} $services-rotation-speed linear infinite;
        }

        @media only screen and (max-width: 600px) {
            &:nth-of-type(#{$i}) {
                animation: #{'inner-circle-mobile-' + $i} $services-rotation-speed-mobile linear infinite;
            }
        }
        

        &:nth-of-type(#{$i}) {
          transform: rotate($rotation * 1deg) translate(math.div($button-circle-size, 2)) rotate($rotation * -1deg) rotate(-360deg);
        
          @media only screen and (max-width: 600px) {
            transform: rotate($rotation * 1deg) translate(math.div($button-circle-size-mobile, 2)) rotate($rotation * -1deg) rotate(-360deg);
          }
        }

        $rotation: $rotation + $angle;

        @keyframes spiral-into-place-#{$i} {
          0% {transform: rotate(450deg) translate(-#{40*$i}vw, 100vw) rotate(-180deg) scale3d(12, 12, 12);}
          100% {transform: rotate(0deg) translate(0, 0) rotate(0deg) scale3d(1, 1, 1);}
        }

        @keyframes move-into-place-#{$i} {
          0% {transform: rotate(270deg) scale3d(0, 0, 0);}
          100% {transform: rotate(0deg) scale3d(1, 1, 1);}
        }

        /* iOs Safari positioning gets screwed up with scaling */
        @media only screen and (hover: hover) {
          &.services-#{(($i+0)%7)+1} .spiral-in {
            animation: move-into-place-#{$i} 1s #{0.2*$i}s both;
          }
        }
    }
  }



}

#services-circle-container:not(.enlarge) .services-5 .service-label {
  left: 44.5%;
}

.enlarge .services-5 .service-label {
  left: 47%;
}

#services-circle-container:not(.enlarge) .services-6 .service-label {
  left: 55%;
}

.enlarge .services-6 .service-label {
  left: 52%;
}

#services-circle-background {
  position: absolute;
  left: 0;
  top: 0;
  width: $services-circle-diameter;
  height: $services-circle-diameter;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #fbd6d7;
  box-shadow: 5px 5px 20px 0px #dbdbdb;
  z-index: 600;

  @media screen and (max-width: 600px) {
    width: $services-circle-diameter-mobile;
    height: $services-circle-diameter-mobile;
  }

} 

#services-triangles {
  display: none;

  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 1000px;
  overflow: hidden;
  transform-origin: center center;

  @keyframes rotate-triangles {
    0% {transform: rotate(0);}
    100% {transform: rotate(360deg);}
  }

  > div {
    position: absolute;
    left: calc(50vw - 50px);
    top: 300px;
    width: 200px;
    height: 200px;
    animation: rotate-triangles 150s linear both infinite;
  }

  $count-triangle: 7;
  $angle-triangle: math.div(360, $count-triangle); 
  $rotation-triangle: 0;

  .services-triangle {
    position: absolute;
    left: -50%;
    top: 0;
    width: 400px;
    height: #{400px * .6067};
    z-index: -1;
    
    polygon {
      fill:rgba(255,191, 193, .85); /* rgba(208, 4, 37, .1) #ffc9ca /* rgb(255, 116, 135); /* #ffc4c6 */
    }
  }

  @for $i from 1 through $count-triangle {

    .services-triangle.triangle-#{$i} {
      $rotation-triangle: $rotation-triangle + $angle-triangle;

      transform: rotate(#{$rotation-triangle}deg) scale3d(2.5, 2.5, 2.5) translateX(200px) ;
    }
  }
}


#healthcare #cross,
#nursing-home #heart-bottom,
#nursing-home #heart-top,
#personal-assistance #caregiver-head,
#personal-assistance #caregiver-body,
#military path,
#waiver #paper-border,
#hoyer-lift #lift,
#therapy #box-1,
#therapy #box-2,
#therapy #box-3 {
  fill: #D00425;
  stroke: none;
  transition: 0.2s;
}

#healthcare:hover #cross,
#nursing-home:hover #heart-bottom,
#nursing-home:hover #heart-top,
#personal-assistance:hover #caregiver-head,
#personal-assistance:hover #caregiver-body,
#military:hover path,
#waiver:hover #paper-border,
#hoyer-lift:hover #lift,
#therapy:hover #box-1,
#therapy:hover #box-2,
#therapy:hover #box-3 {
  fill: darken(#D00425, 10%);
}


#healthcare #stethoscope,
#hoyer-lift #head,
#hoyer-lift #arms,
#hoyer-lift #butt,
#hoyer-lift #legs,
#waiver #pen,
#personal-assistance #patient-head,
#personal-assistance #patient-body,
#personal-assistance #patient-left-leg,
#personal-assistance #patient-right-leg,
#personal-assistance #tag,
#nursing-home #person-head,
#nursing-home #person-body,
#nursing-home #hand,
#therapy #patient-caregiver,
#therapy #patient-legs {
  fill: #EC8583;
  stroke: none;
  transition: 0.2s;
}

#healthcare:hover #stethoscope,
#hoyer-lift:hover #head,
#hoyer-lift:hover #arms,
#hoyer-lift:hover #butt,
#hoyer-lift:hover #legs,
#waiver:hover #pen,
#personal-assistance:hover #patient-head,
#personal-assistance:hover #patient-body,
#personal-assistance:hover #patient-left-leg,
#personal-assistance:hover #patient-right-leg,
#personal-assistance:hover #tag,
#nursing-home:hover #person-head,
#nursing-home:hover #person-body,
#nursing-home:hover #hand,
#therapy:hover #patient-caregiver,
#therapy:hover #patient-legs {
  fill: darken(#EC8583, 10%);
}


#hoyer-lift #seat,
#therapy #wheel,
#personal-assistance #wheel-left,
#personal-assistance #wheel-right,
#personal-assistance #wheelchair-left,
#personal-assistance #wheelchair-right,
#nursing-home #house-wall-left-floor,
#nursing-home #house-wall-right,
#nursing-home #house-roof {
  fill: #4C4C4C;
  stroke: none;
  transition: 0.2s;
}

#hoyer-lift:hover #seat,
#therapy:hover #wheel,
#personal-assistance:hover #wheel-left,
#personal-assistance:hover #wheel-right,
#personal-assistance:hover #wheelchair-left,
#personal-assistance:hover #wheelchair-right,
#nursing-home:hover #house-wall-left-floor,
#nursing-home:hover #house-wall-right,
#nursing-home:hover #house-roof {
  fill: darken(#4C4C4C, 10%);
}


#healthcare #left-earbud,
#healthcare #right-earbud,
#therapy #table,
#waiver #line-1,
#waiver #line-2,
#waiver #line-3,
#waiver #line-4,
#waiver #line-5,
#nursing-home #house-chimney,
#hoyer-lift #rope {
  fill: #A7A9AC;
  stroke: none;
  transition: 0.2s;
}

#healthcare:hover #left-earbud,
#healthcare:hover #right-earbud,
#therapy:hover #table,
#waiver:hover #line-1,
#waiver:hover #line-2,
#waiver:hover #line-3,
#waiver:hover #line-4,
#waiver:hover #line-5,
#nursing-home:hover #house-chimney,
#hoyer-lift:hover #rope {
  fill: darken(#A7A9AC, 10%);
}


#healthcare,
#nursing-home,
#therapy,
#hoyer-lift,
#waiver,
#personal-assistance,
#military {
  z-index: 999;
  transition: 0.2s;

  path {
    transition: 0.2s;
  }
}

.service-label {
  position: relative;
  width: max-content;
  background: #EC8583;
  font-family: PoppinsMedium;
  font-weight: 700;
  font-size: 1.2rem;
  color: #fff;
  white-space: pre-line;
  text-align: center;
  padding: 20px 40px;
  border: 5px solid #ca2a39;
  opacity: 0;
  transition: .2s;
  border-radius: 1000px;
  z-index: 9999999;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  user-select: none;
  pointer-events: none;

  @media screen and (hover: none) {
    display: none;
  }

  @media screen and (max-width: 600px) {
    font-size: 1rem;
    padding: 10px 30px;
  }
}


.hover .service-label { 
/* #services-circle-container .hover .service-label { */
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
 
 @media not all and (min-resolution:.001dpcm) {  /* Targets Safari, which doesn't animate hover area and shows wrong service as a result */
    @media screen {
      opacity: 0;
    }
  }
}

#services-circle-container.enlarge .service-label,
#services-circle-container.shrink .service-label {
  top: -50px;
  transform: translateX(-50%) scale3d(.6, .6, .6);

  @media screen and (max-width: 600px) {
    top: -30px;
  }
}

.enlarge .selected .service-label,
.shrink .selected .service-label {
  display: none;
}

#healthcare:hover,
#nursing-home:hover,
#therapy:hover,
#hoyer-lift:hover,
#waiver:hover,
#personal-assistance:hover,
#military:hover {
  cursor: pointer;
  transform: scale3d(1.1, 1.1, 1.1);
  
  /* 
  path {
    fill: #FF9C0C !important;
  }
  */
}


@keyframes services-info-enlarge {
  0% {transform: translate(-50%, -16px);}
  100% {transform: translate(-50%,-50%);}
}

@keyframes services-info-copy-enlarge {
  0% {transform: scale3d(0, 0, 0);}
  100% {transform: scale3d(1, 1, 1);}
}

@keyframes services-info-shrink {
  0% {transform: translate(-50%,-50%);} 
  100% {transform: translate(-50%, -16px);}
}

@keyframes services-info-copy-shrink {
  0% {transform: scale3d(1, 1, 1);} 
  100% {transform: scale3d(0, 0, 0);}
}

#services-info {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -16px);
  width: 300px;
  z-index: 700; /* Must be over services circle and under .services icons */
  pointer-events: none;

  @media only screen and (max-width: 600px) {
    width: 200px;
  }

  &.enlarge {
    animation: services-info-enlarge 1s both;
  }

  &.shrink {
    animation: services-info-shrink .75s both;
  }

  @keyframes services-info-enter {
    0% {transform: scale3d(0, 0, 0);}
    100% {transform: scale3d(1, 1, 1);}
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    animation: services-info-enter 0.4s both;
  }

  @keyframes services-info-heading-enlarge-1 {
    0% {transform: translate(-50%) rotateX(0deg);}
    100% {transform: translate(-50%) rotateX(-90deg);}
  }

  @keyframes services-info-heading-enlarge-2 {
    0% {transform: rotateX(-90deg);}
    100% {transform: rotateX(0deg);}
  }


  @keyframes services-info-heading-shrink-2 {
    0% {transform: rotateX(0deg);}
    100% {transform: rotateX(90deg);}
  }

  @keyframes services-info-heading-shrink-1 {
    0% {transform: translate(-50%) rotateX(90deg);}
    100% {transform: translate(-50%) rotateX(0deg);}
  }

  #services-info-heading-1 {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 0;
  }

  #services-info-heading-1,
  #services-info-heading-2 {
    font-family: ArticoExtraBold;
    color: #ca2a39;
    font-size: 1.9rem;
    font-weight: 700;
    text-align: center;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
    margin-bottom: 12px;
    width: 100%;
    white-space: pre;

    @media only screen and (max-width: 600px) {
      font-size: 1.2rem;
      width: 200px;
    }
  }

  #services-info-heading-2 {
    opacity: 0;
  }

  &.enlarge #services-info-heading-1.roll-1 {
    animation: services-info-heading-enlarge-1 0.125s both;
  }

  &.enlarge #services-info-heading-1.roll-2 {
    opacity: 0;
  }

  &.enlarge #services-info-heading-2.roll-2 {
    opacity: 1;
    animation: services-info-heading-enlarge-2 0.375s both;
  }

  &.shrink #services-info-heading-1.roll-1 {
    animation: services-info-heading-shrink-1 #{0.125*0.75}s both;
  }

  &.shrink #services-info-heading-1.roll-2 {
    opacity: 0;
  }

  &.shrink #services-info-heading-2.roll-2 {
    opacity: 1;
    animation: services-info-heading-shrink-2 #{0.375*0.75}s both;
  }

  #services-info-copy {
    font-size: 1.1rem;
    text-align: center;
    transform: scale3d(0, 0, 0);
    transform-origin: center top;

    @media only screen and (max-width: 600px) {
      font-size: #{1.1*.66}rem;
    }

    .bullet-points {
      position: relative;
      display: inline-block;
      font-size: 1rem;
      margin: 14px 0;
      text-align: left;

      @media only screen and (max-width: 600px) {
        font-size: .66rem;
      }
  
    }

    .smallest {
      font-size: 0.8rem;
      line-height: 0;
      margin: 6px 0 10px 0;

      @media only screen and (max-width: 600px) {
        font-size: #{.8*.66}rem;
      }
  
    }
  }

  &.enlarge #services-info-copy {
    animation: services-info-copy-enlarge 1s both;
  }

  &.shrink #services-info-copy {
    animation: services-info-copy-shrink .75s both;
  }

}

@keyframes rotate-services-circle-names {
  0% {transform: rotate(-20deg);}
  100% {transform: rotate(340deg);}
}

#services-circle-names {
  position: absolute;
  top: 0;
  left: calc(50vw - 408px);
  width: 800px;
  height: 800px;
  z-index: 99999;
  opacity: .25;
  font-family: Effra;
  font-size: 30px;
  border-radius: 50%;
  z-index: 1;
  
  transform-origin: center center;
  animation: rotate-services-circle-names $services-rotation-speed 0.4s linear infinite;
  
  @media only screen and (max-width: 600px) {
    animation:  rotate-services-circle-mobile $services-rotation-speed-mobile 0.4s linear infinite; /* Make it spin faster as a couple of icons will be off screen on phones */
  }
  
  #myCircle {
    position: absolute;
    width: 800px;
    height: 800px;
    overflow: visible;
    transform-origin: center center;
    transition: 1s;
  }
}

.enlarge ~ #services-circle-names #myCircle {
  opacity: 0;
}

.shadow-circle {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 1;
  pointer-events: none;

  @media screen and (max-width: 600px) {
    width: 90px;
    height: 90px;
    opacity: 0.5;
  }
}

$spotOne: #fbd6d7;
$spotTwo: #ec008c;
$spotThree: #ff0000;

@keyframes enter-shadow-circle {
  0% {
    background: transparent;
    box-shadow: none;
  }
  100% {
    background: #fef1f3;
    box-shadow: 0 0 0 0 $spotTwo,
                0.5rem 0.5rem 30px mix(black, $spotOne, 50%);
  }
}

@keyframes exit-shadow-circle {
  0% {
    background: #fef1f3;
    box-shadow: 0 0 0 0 $spotTwo,
                0.5rem 0.5rem 30px mix(black, $spotOne, 50%);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}

.enlarge .selected .shadow-circle {
  animation: enter-shadow-circle 1s both;
}

.shrink .selected .shadow-circle {
  animation: exit-shadow-circle .25s both;
}

#qualifications-container {
  position: relative;
  width: calc(100vw - 17px);
  display: flex;
  justify-content: center;
  margin-top: 0px;
  
  @media screen and (max-width: 600px) {
    top: -250px;  
    margin-bottom: -175px;
  }

  #qualifications {
    width: 800px;
    max-width: 90%;
    min-height: 600px; /* Must be as tall as background shape below */
    font-family: Effra;
    font-size: 1.6rem;
    text-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2); 
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    @media screen and (max-width: 600px) {
      font-size: 1.3rem; 
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
      font-size: 1.2rem; 
    }

    #question {
      width: 100%;
      font-family: PoppinsMedium;
      font-size: 2rem;
      font-weight: 700;
      color: #ca2a39;
      text-align: center;

      @media screen and (max-width: 600px) {
        font-size: 1.4rem; 
      }

      @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 1.6rem; 
      }
    }

    ul {
      color: #3d3d3d;
    
      li {
        margin-bottom: 8px;
      }
    }
  }

  #qualifications-background-shape {
    position: absolute;
    width: 800px;
    max-width: 80vw;
    height: 800px;
    top: -340px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: .8;
/*
      @media screen and (min-width: 1400px) {
        left: 310px;
      }
*/

    @media screen and (max-width: 600px) {
      max-width: 80vw; 
      top: -220px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
      top: -440px;
    }

    svg {
      width: 100%;
      height: 100%;
      transform: rotate(5deg);

      @media screen and (max-width: 600px) {
        transform: rotate(5deg) scale3d(1, 1.8, 1);
      }

      @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 1000px;
      }

      polygon {
        fill: #EC8583;
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.25));
      }
    }
  }
}

.services-background-container {
  position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
  min-height: 900px;

	@media screen and (max-width: 600px) {
		height: 270vw;
	}

	@media screen and (min-width: 1400px) {
		height: 100%;
	}
}

.services-canvas-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(236, 106, 131, 0.1), rgba(236, 106, 131, 0.1) 90%, #fff 100%);

  @media screen and (min-width: 601px)  and (max-width: 1024px) {
		top: -100px;
	}

	@media screen and (max-width: 600px) {
		top: -90px;
	}
}

/* Inside ServicesBackground but HTML transform=false */
.services-foreground-container {
  position: relative;
	left: 0;
	top: 0;
	transform: rotateX(10deg) rotateY(-15deg);
  
  @media screen and (min-width: 1025px) {
    top: -50px;
  }

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    top: 0;
  }

  @media screen and (min-width: 451px) and (max-width: 600px) {
    transform: none;
    top: -75px;
    left: 10px;
  }

  @media screen and (max-width: 450px) {
    transform: none;
    top: 75px;
    left: 10px;
  }
}

.fallback-container {
  margin-top: -30vh;
}
