@use "sass:math";

.heading {
  font-family: ArticoExtraBold;
  color: #ca2a39;
}

#home-container {
  position: relative;
  top: -120px; /* Height of header */
  
  flex-direction: column;
  font-family: Effra;
  font-size: 2vw;

  margin-bottom: -60px; /* so footer overlaps */

  @media screen and (max-width: 600px) {
    margin-bottom: -170px;
  }

  @media screen and (min-width: 1440px) {
    margin-bottom: 60px;
  }

  height: 100vw;
  min-height: 100vh;
  flex: initial;
  overflow: hidden;
  z-index: 130; /* cover footer */

  &.exit-mask {
    height: 100%;
    flex: 1;
    overflow: initial;
    z-index: 1; /* allow footer to overlap */
  }
}

#mask-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

#home-container,
#mask-container {
  display: flex;
  width: 100%;

  .background {
    z-index: 100;
  }

  > div:not(.background) {
    padding-top: 120px; /* space for header */
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  .left {
    flex: none;
    width: 350px;
    padding: calc(120px + 6vw) 40px 60px 40px !important;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.15);
    z-index: 103;

    > div {
      max-width: 24vw;

      @media screen and (min-width: 1000px) {
        width: 400px;
      }

      @media screen and (max-height: 720px) {
        width: 300px;
      }
    }

    @media screen and (min-width: 1100px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 600px) {
      order: 2;
      width: 90vw;
      min-width: 288px;
      font-size: 4vw;
      padding: 1.5vw 40px 40px 40px !important;

      > div {
        max-width: initial;
      }
    }
  }

  .right {
    flex: 1;

    @media screen and (max-width: 600px) {
      flex: none;
      order: 1;
      width: 98vw;
      min-width: 288px;
      left: 0;

      > svg {
        width: 98vw;
        min-width: 288px;
      }
    }
  }
}

.foreground {
  flex: none;
  display: flex;
  padding: 0 !important;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

#initial-background .right {
  padding-top: 120px; /* height of header */
}

#street-scene {
  padding-top: 120px;
}

#street-scene,
#initial-background .right {
  position: relative;
  width: 87vw;
  max-width: 1200px;
  left: calc(10vw - 350px);
  z-index: 191;

  @media screen and (min-width: 1025px) {
    max-width: 130vh;
    min-width: 900px;
  }
  
  > div {
    width: 87vw;
    max-width: 1200px;

    @media screen and (min-width: 1025px) {
      max-width: 130vh;
      min-width: 900px;
    }

    > svg {
      width: 100%;
    }
  }

  #background-shape-container {
    position: relative;
  }
}

#townhouses-container {
  width: 100%;
  padding-top: 120px;

  #townhouses {
    clip-path:url(#SVGID_2);
  }

  #background-shape {
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.02));
  }
}


#people-container {
  width: 100%;
  padding-top: 120px;

  > svg {
    overflow: visible;
  }
}

#street-scene,
#masked-townhouses-container,
#over-mask-lion-container {
  #tree-cover {
    fill: #efefef;;
  }
  .st0{fill:#DCDCDC;}
  .st1{fill:#efefef;} /* #f1f2f2 background shape */
  
  .st3{fill:#231F20;}
  .st4{fill:#FFB483;}
  .st5{fill:none;stroke:#D1D3D4;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
  .st6{fill:#D1D3D4;}
  .st6b{fill:#939598;}
  .st7{fill:#A7A9AC;}
  .st8{fill:#FFFFFF;stroke:#EC6A83;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
  .st9{fill:none;stroke:#A7A9AC;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
  .st10{fill:#E6E7E8;}
  .st11{fill:#EC8883;}
  .st12{fill:#939598;}

  .st14{fill:#EC8583;}
  .st15{fill:none;stroke:#FFB483;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
  .st16{fill:#4C4C4C;}
  .st17{fill:#CA2939;}

  .st19{fill:#EC6A83;}
  .st20{fill:#BCBEC0;}
  .st21{fill:#A7A9AC;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;}
  .st22{fill:none;stroke:#6D6E71;stroke-width:8;stroke-linecap:round;stroke-linejoin:round;}
  .st23{fill:#A7A9AC;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10.0002;}
  .st24{fill:#CF0525;}
  .st25{fill:#FFFFFF;stroke:#CF0525;stroke-linecap:round;stroke-linejoin:round;}
  .st26{fill:none;stroke:#231F20;stroke-width:0.75;stroke-linecap:round;stroke-linejoin:round;}
  .st27{fill:#CF0525;stroke:#FB5973;stroke-width:0.5;stroke-miterlimit:10;}
  .st28{fill:#414042;}
  .st29{fill:#5A3622;}
  .st30{fill:#FB5973;}
  .st31{fill:#FB5973;stroke:#CF0525;stroke-width:0.5;stroke-miterlimit:10;}
  .st32{fill:#FFFFFF;}
  .st33{fill:none;stroke:#BCBEC0;stroke-width:10;stroke-miterlimit:10;}
  .st34{fill:none;stroke:#BCBEC0;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;}
  .st35{fill:none;stroke:#FFFFFF;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;}
  .st36{fill:none;stroke:#58595B;stroke-linecap:round;stroke-linejoin:round;}
  .st37{fill:none;stroke:#BCBEC0;stroke-width:13;stroke-miterlimit:10;}
  .st38{fill:none;stroke:#231F20;stroke-width:9;stroke-miterlimit:10;}
  .st39{fill:none;stroke:#FFFFFF;stroke-linecap:round;stroke-linejoin:round;}
  .st40{fill:none;stroke:#231F20;stroke-width:7;stroke-miterlimit:10;}
  .st41{fill:#FFFFFF;stroke:#CF0525;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}
  .st42{fill:#ECA595;}
  .st43{fill:#CF0525;stroke:#EC6A83;stroke-miterlimit:10;}
  .st44{fill:#6D6E71;}
  .st45{fill:#FF9E83;}
  .st46{display:none;}
}


@keyframes slide-down-street {
  0% {transform: translate(-42%, -30%) scale(0);}
  100% {transform: translate(-42%, -6%) scale(0.96);}
}

@keyframes slide-down-street-mobile {
  0% {transform: translate(-12%, -30%) scale(0);}
  100% {transform: translate(-12%, 0) scale(1);}
}

@keyframes slide-over-street {
  0% {
    transform: translate(-42%, -6%) scale(0.96);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes slide-over-street-mobile {
  0% {
    transform: translate(-12%, 0) scale(1);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes slide-over-mask-right-div {
  0% {
    left: 0;
  }
  100% {
    left: calc(10vw - 350px);
  }
}

@keyframes grow-lion {
  0% {
    left: 80vw;
    top: 40vw;
    width: 0;
  }
  100% {
    left: -160vw;
    top: -126vw;
    width: 470vw;
  }
}

@keyframes grow-lion-max {
  /* freeze values at 1024px for width */
  0% {
    left: #{.80 * 1024px};
    top: #{.40 * 1024px};
    width: 0;
  }
  100% {
    left: #{-1.6 * 1024px}; 
    top: #{-1.26 * 1024px}; 
    width: #{4.7 * 1024px}; 
  }
}

@keyframes shrink-lion {
  0% {
    left: -160vw;
    top: -126vw;
    width: 470vw;
  }
  100% {
    left: 0;
    top: 0;
    width: 100%;
  }
}

@keyframes shrink-lion-max {
  /* freeze values at 1024px for width */
  0% {
    left: #{-1.6 * 1024px}; 
    top: #{-1.26 * 1024px}; 
    width: #{4.7 * 1024px}; 
  }
  100% {
    left: 0;
    top: 0;
    width: 100%;
  }
}


@keyframes enter-townhouses {
  0% {opacity: 0; transform: translateX(50vw);}
  100% {opacity: 1; transform: translateX(0);}
}


@keyframes enter-trees {
  0% {opacity: 0; transform: translateY(20vw);}
  100% {opacity: 1; transform: translateY(0);}
}

@keyframes fade-out {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fade-out-mask-background {
  0% {background: #FED8DF;}
  100% {background: transparent;}
}

@keyframes background-shape-change-color {
  0% {fill: purple;} /*#FDF0F2; */
  100% {fill: aqua;} /*#efefef; */
}

@keyframes fade-out-townhouses-background {
  0% {background: #efefef;}
  100% {background: transparent;}
}

#street-container {
  #ground,
  #townhouses-container,
  #people-container {
    opacity: 0;
  }

  #ground-container,
  #townhouses-container,
  #people-container {
    @media screen and (min-width: 601px) {
      width: 87vw;
      max-width: 1200px;
    }

    @media screen and (min-width: 1025px) {
      max-width: 130vh;
      min-width: 900px;
    }
  }

  #ground {
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.1));
  }
  // transform-origin: center center;
  // transform: translate(-12%, -30%) scale(0);
}

#lion-container > div,
#over-mask-lion-container > div  {
  position: absolute;
  will-change: transform;
}


#ground-container {
  z-index: 1;

  @media screen and (max-width: 600px) {
    width: 100vw;
    min-width: 320px;
  }
}

#townhouses-container,
#masked-townhouses-container,
#over-mask-lion-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
/* 
  > svg {
   background: #efefef; /* color of background shape *//* 
  }
*/
}

#masked-townhouses-container,
#over-mask-lion-container {
  width: 100%;
  height: 0;
  padding-bottom: #{100*math.div(850, 1400)+'%'}; 
}

#masked-townhouses-container {
  background: #efefef; /* #817a7a; */
}

#over-mask-lion-container {
  z-index: 5;
}


#mask-container {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &.hide {
    opacity: 0 !important;
  }

  @media screen and (min-width: 1200px) {
    height: initial;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  padding-top: 0 !important;
  background: #FED8DF;
  z-index: 999;

	.st0{fill:#FED8DF;} /* #FED8DF */
  .st1{display: none; fill:blue;} /* #FDF0F2 */
  
  > div {
    width: 100%;
    z-index: 1;
  }
}

#mask {
  position: relative;
  overflow: hidden;
  z-index: 3;

  > svg {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}

#people-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
}

#lion-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}



#lion-container {
  display: none;
}

#initial-background {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  width: 100vw;
  min-width: 320px;
  height: 100%;
  z-index: 90;

  .full {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #FED8DF;
    z-index: 100;
  }

  #background-shape-container {
    z-index: 101;
  }
}

#home-container.play {

  &:not(.exit-mask) .background { /* need to show after mask is removed */
    background: #FED8DF;
    padding-top: 0 !important;

    > div {
      display: none;
    }
  }
  
  #mask-container > div {
    padding-top: 120px;
  }

  #mask-container > .right {

    max-width: 1200px;

    animation:  slide-down-street 1s both,
                slide-over-street 3s 1.5s forwards;

    @media screen and (max-width: 600px) {
      animation: slide-down-street-mobile 1s both,
                 slide-over-street-mobile 3s 1.5s forwards;
    }

    > div {
      position: relative;

      @media screen and (min-width: 601px) {
        width: 87vw;
        max-width: 1200px;
        animation: slide-over-mask-right-div 3s 1.5s both;
      }

      @media screen and (min-width: 1025px) {
        max-width: 130vh;
        min-width: 900px;
      }
    }

    #townhouses {
      animation: enter-townhouses 3s 1.5s both;
    }

    #right-tree {
      animation: enter-trees 1s 3s both;
    }

    #mask-container,
    #left-tall-tree {
      animation: enter-trees 1s 3.5s both;
    }

    #left-short-tree {
      animation: enter-trees 1s 4s both;
    }
  }

  #over-mask-lion-container > div {
    position: absolute;

    animation:  grow-lion 1s .1s both,
                shrink-lion 3s 2s forwards;

    @media screen and (min-width: 1025px) {
      animation:  grow-lion-max 1s .1s both,
                  shrink-lion-max 3s 2s forwards;
    }
  }

  /*
  #townhouses-container > svg {
    animation: fade-out-townhouses-background 2s .9s both; 
  }
  */

  #background-shape-container {
    animation: slide-down-street 1s both;
    .st0 {
      animation: fade-out 3s 1s both;
    }
    .st1 {
      animation: background-shape-change-color 3s 1s both;
    }
  }

  #initial-background .full {
    animation: fade-out 3s 1s both;
  }
}

#street-scene {
  pointer-events: none; /* Allow click for burger menu */
}

@keyframes enter-ground {
  0% {transform: scaleY(0);}
  100% {transform: scaleY(1);}
}

@keyframes enter-card-table {
  0% {transform: scaleY(0);}
  100% {transform: scaleY(1);}
}

@keyframes enter-wheelchair {
  0% {transform: translateX(42vw);}
  100% {transform: translateX(0);}
}

#home-container.play.exit-mask {

  /*pointer-events: none; /* Allow click for burger menu */
  
  .birds-wrapper {
    pointer-events: none; /* Allow click for burger menu */
  }

  #street-container {
    #ground,
    #townhouses-container,
    #people-container {
      opacity: 1;
    }
  }

  #ground-container {
    transform-origin: center 70%;
    animation: enter-ground 1s both;
  }

  #card-table {
    transform-origin: center 85%;
    animation: enter-card-table 1s both;  
    
    path {
      filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.1));
    }
  }

  #wheelchair {
    transform-origin: center 70%;
    animation: enter-wheelchair 2s both;
 
    path {
      filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.1));
    }
  }

  #mask-container {
    pointer-events: none; /* Allow click for burger menu */
    animation: fade-out 1s both;
  }

   /*
  @media screen and (min-width: 601px) {
    .left {
      background: #fff; /* Client doesn't want.
    }
  }
  */
}
/*
#initial-background {
  clip-path:url(#SVGID_4);
}
*/

@keyframes begin-flip {
  0% {transform: rotate3d(1, 0, .12, 0deg)}
  100% {transform: rotate3d(1, 0, .12, 90deg)}
}

@keyframes finish-flip {
  0% {transform: rotate3d(1, 0, .12, 90deg)}
  100% {transform: rotate3d(1, 0, .12, 360deg)}
}

#section-1 .heading {
  margin-bottom: 20px;
  text-transform: uppercase;
}

#section-2 {
  flex: none;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  padding: 0 !important;
  z-index: 110;

  &:not(.hide) {
    height: 145vw; /*********MUST MANUALLY ADJUST TO FIX HORIZONTAL SCROLLING PROBLEM ON IOS SAFARI - OVERFLOW X NOT WORKING ****/
    overflow: hidden;

    @media screen and (min-width: 601px) {
      top: 6vw;
      overflow: initial;
    }

    @media screen and (max-width: 600px) {
      top: 10vw;
      height: 230vw; /*********MUST MANUALLY ADJUST TO FIX HORIZONTAL SCROLLING PROBLEM ON IOS SAFARI - OVERFLOW X NOT WORKING ****/
      min-height: 720px;
    }

    @media screen and (min-width: 1400px) {
      height: initial;
      overflow: initial;
    }

    .heading {
      text-transform: uppercase;
    }
  }

  #section-2A,
  #kitchen-scene {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
/*
    @media screen and (min-width: 601px) {
      top: 3vw;
    }
*/
  }

  #section-2B {
    flex: none;
    position: relative;

    .triangle,
    #lion {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      @media screen and (min-width: 601px) {
        top: 6vw;
      }
    }

    #lion {
      pointer-events: none;

      svg path {
        filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.1));
      }
    }

    .triangle path {
      filter: drop-shadow(3px 3px 5px rgba(0,0,0,0.1));
    }

    #triangle-3 path {
      filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.20));
    }
    @keyframes enter-triangle-1 {
      0% {transform: translateZ(-500px) scale(0) rotate(180deg)}
      100% {transform: translateZ(-500px) scale(1) rotate(0deg)}
    }

    #triangle-1,
    #triangle-1b {
      position: relative;
      max-height: 2000px;

      svg {
        width: 135.4%;
      }

      &:not(.enter) svg {
        transform: scale(1) rotate(0deg);

        path {
          fill: transparent;
        }
      }

      &.enter svg {
        transform-origin: left top;
        animation: enter-triangle-1 1s forwards;
      }

    }

    #triangle-1 {
      @media screen and (min-width: 1400px) {
        display: none;
      }
    }

    div#triangle-1b { 
      position: absolute;
      top: -10vw;

      path {
        filter: none;
      }

      @media screen and (max-width: 600px) {
        display: none;
      }

      @media screen and (min-width: 1400px) {
        position: relative;
        top: 5vw;
        transform-origin: bottom left;
        transform: scale(1.1);
      }
    }

    #triangle-2-intersection {
      position: absolute;
      width: 100%;
      left: 0;
      top: 50vw;
      height: 63vw;
      /*
      background: green;
      z-index: 9999999;
      */

      @media screen and (max-width: 600px) {
        top: 37vw;
        height: 76vw;
      }

      @media screen and (min-width: 1400px) {
        top: 518px;
        height: 882px;
      }
    }

    #triangle-3-container {
       /*
      animation: flip 3s infinite; 
      */
      transform-origin: left 70vw;

      &.start-flip {
        animation: begin-flip .25s both;
      }

      &.end-flip {
        animation: finish-flip .75s both;
      }

      svg {
        @media screen and (max-width: 1400px) {
          width: 102%;
        }
      }
    }
  }

  #kitchen-scene,
  #section-2B {
    width: 100%;
    z-index: 1;

    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FED8DF;}
    .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FDF0F2;}
    .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#EC6A83;}
    .st3{fill:#CA2939;}
    .st4{font-family:'ArticoCondensedExtraBold';}
    .st5{font-size:48px;}
    .st6{fill:#FF9E83;}
    .st7{fill:#FFB483;}
    .st8{display:none;}
    .st9{fill:#EC6A83;}
    .st10{fill:#FFFFFF;}
    .st11{fill:#E6E7E8;}
    .st12{opacity:0.702;fill-rule:evenodd;clip-rule:evenodd;fill:#FFB27F;enable-background:new    ;}
    .st13{fill:#F1F2F2;}
    .st14{clip-path:url(#SVGID_4_);}
    .st15{fill:#A7A9AC;stroke:#FFFFFF;stroke-miterlimit:10;}
    .st16{fill:none;stroke:#58595B;stroke-miterlimit:10;}
    .st17{fill:#D1D3D4;stroke:#A7A9AC;stroke-miterlimit:10;}
    .st18{fill:#231F20;stroke:#FFFFFF;stroke-miterlimit:10;}
    .st19{fill:#231F20;}
    .st20{fill:#D1D3D4;}
    .st21{fill:#A7A9AC;}
    .st22{fill:#BCBEC0;}
    .st23{fill:#F7F8F8;}
    .st24{fill:#414042;}
    .st25{fill:none;stroke:#939598;stroke-linecap:round;stroke-linejoin:round;}
    .st26{fill:#808285;}
    .st27{fill:none;stroke:#E6E7E8;stroke-linecap:round;stroke-linejoin:round;}
    .st28{fill:#EC8583;}
    .st29{fill:none;stroke:#EC6A83;stroke-miterlimit:10;}
    .st30{fill:#CF0525;}
    .st31{fill:#FFFFFF;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
    .st32{fill:#ECA595;}
    .st33{fill:#FB5973;}
    .st34{fill:#FB5973;stroke:#CF0525;stroke-miterlimit:10;}
    .st35{fill:#CF0525;stroke:#EC6A83;stroke-miterlimit:10;}
    .st36{fill:#58595B;}
  }

  @keyframes swing-in-right-fwd {
    0% {
      transform: rotateY(-100deg);
      transform-origin: right;
      opacity: 0;
    }
    100% {
      transform: rotateY(0);
      transform-origin: right;
      opacity: 1;
    }
  }

  @keyframes swing-in-left-fwd {
    0% {
      transform: rotateY(100deg);
      transform-origin: left;
      opacity: 0;
    }
    100% {
      transform: rotateY(0);
      transform-origin: left;
      opacity: 1;
    }
  }

  @keyframes enter-kitchen-foreground {
    0% {opacity: 0; transform: translateY(20vw);}
    100% {opacity: 1; transform: translateY(0);}
  }

  #kitchen-scene {
    flex: initial;
  }

  #section-2A {
    opacity: 0;
  }

  #section-2A.enter {
    opacity: 1;

    #kitchen-scene svg {
      #kitchen-background {
        animation: swing-in-right-fwd 1s .5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;

        #kitchen-background-shape {
          filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.05));
        }
      }

      #kitchen-floor {
        transform-origin: center 70%;
        animation: enter-ground 0.5s 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;

        path {
          filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.1))
        }
      }

      #kitchen-foreground {
        transform-origin: center 70%;
        animation: enter-kitchen-foreground 1s .5s both;

        path {
          filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.1))
        }
        
      }
    }

    #independent-living {
      animation: swing-in-left-fwd 1s 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
      text-shadow: 5px 5px 5px rgba(0,0,0,0.15);
    }
  }

  #independent-living {
    position: absolute;
    flex: initial;
    top: 16vw;
    left: 10vw; 
    width: 25vw; 
    z-index: 2;
   
    @media screen and (min-width: 1400px) {
      left: 140px;
      width: 350px;
    }

    @media screen and (min-width: 1100px) {
      font-size: 1.5rem;
    }

    .heading {
      margin-bottom: 20px;
    }
  }

  #we-believe {
    position: absolute;
    flex: initial;
    top: 54vw;
    left: 10vw;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.15);
    z-index: 3;

    @media screen and (min-width: 601px) {
      top: 64vw;
    }

    @media screen and (min-width: 1400px) {
      top: 900px;
      left: 140px;
      font-size: 1.5rem;
    }
  }

  .slide {
    position: absolute;
    top: 71vw;
    left: 33vw;
    width: 50vw;
    font-size: 3vw;

    @media screen and (min-width: 601px) {
      top: 77vw;
    }

    @media screen and (min-width: 1400px) {
      top: 1094px;
      left: 462px;
      width: 700px;
      font-size: 2.5rem;
    }

    > div {
      backface-visibility: hidden;
      transform-origin: left 55%;
    }
    
    > .start-flip {
      animation: begin-flip .25s both;
    }

    > .end-flip {
      animation: finish-flip .75s both;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 20px;
        filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
      }
    }

    .slider-dots {
      position: absolute;
      top: 20vw;
      left: 13.5vw;
      display: flex;
      justify-content: center;
      align-items: center;

      .dot {
        flex: none;
        width: 2.4vw;
        height: 2.4vw;
        margin: 0 .4vw;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          width: 2vw;
          height: 2vw;
          border-radius: 50%;
          background: #fdf0f2;
          box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
          cursor: pointer;
        }

        &.active > div {
          width: 2.4vw;
          height: 2.4vw;
          background: #353535;
          transition: 1s;
          cursor: initial;
        }
      }

      @media screen and (min-width: 1400px) {
        top: 280px;
        left: 189px;

        .dot {
          width: 33.6px;
          height: 33.6px;
          margin: 0 5.6px;

          > div {
            width: 28px;
            height: 28px;
          }

          &.active > div {
            width: 33.6px;
            height: 33.6px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {

    #kitchen-scene {
      flex: none;
      order: 1;
      position: relative;
      transform: translateX(-20vw) scale(1.5);
    }

    #independent-living {
      flex: none;
      order: 2;
      position: relative;
      width: 100vw;
      min-width: 320px;
      left: 0;
      padding: 0 40px;
      font-size: 4vw;
    }

    #section-2A {
      position: relative;
    }

    #section-2B {
      order: 3;
      position: relative;
      top: 10vw;
      margin-top: 6vw;

      @media screen and (min-width: 601px) {
        margin-top: 6vw;
      }
    }

    #we-believe {
      position: absolute;
      top: 43vw;
      left: 28vw;
      width: 45vw;
      z-index: 3;
      font-size: 4vw;
    }

    .slide {
      left: 30vw;
      font-size: 3.5vw;

      ul {
        li {
          margin-bottom: 10px;
        }
      }
    }

    #lion {
      transform: translateZ(400px) translateY(-33vw);
    }

    @keyframes enter-triangle-1-mobile {
      0% {transform: translateZ(-500px) scale(0, 0) rotate(180deg)}
      100% {transform: translateZ(-500px) scale(1, 0.8) rotate(0deg)}
    }

    #triangle-1 {

      &.enter svg {
        animation: enter-triangle-1-mobile 1s forwards;
      }

      path {
        transform: translateY(10vw);
      }
    }

    @keyframes enter-triangle-2-mobile {
      0% {transform: translateZ(-400px) scale(0, 0)}
      100% {transform: translateZ(-400px) scale(1, 1.25)}
    }

    @keyframes enter-triangle-2-svg-mobile {
      0% {transform: translateZ(-400px) rotate(120deg)}
      100% {transform: translateZ(-400px) rotate(0deg)}
    }

    #triangle-2 {
      transform-origin: initial !important;
      transform: translateZ(-400px) scale(1, 1.25);

      svg {       
        transform: rotate(0deg);

        path {
          transform: translate(23vw, -24vw);
        }
      }
    }

    #section-2B.enter {
      #triangle-2 {
        animation: enter-triangle-2-mobile 1s both;

        svg {
          animation: enter-triangle-2-svg-mobile 1s both;
        }
      }
    }

    @keyframes enter-triangle-3-mobile {
      0% {transform: translateZ(1px) scale(0)}
      100% {transform: translateZ(1px) scale(1)}
    }

    @keyframes enter-triangle-3-svg-mobile {
      0% {transform: translateZ(1px) rotate(120deg)}
      100% {transform: translateZ(1px) rotate(0deg)}
    }



    #triangle-3-container {
      /* transform-origin: initial !important; */
      transform: translateZ(1px);

      #triangle-3 {
        transform: scale(1.4, 1);
      }

      svg {
        transform: rotate(0deg);

        path {
          transform: translate(10vw, 12.5vw);
        }
      }

      @keyframes begin-flip-mobile {
        0% {transform: translateZ(1px) rotate3d(1, 0, .12, 0deg)}
        100% {transform: translateZ(1px) rotate3d(1, 0, .12, -90deg)}
      }
      
      @keyframes finish-flip-mobile {
        0% {transform: translateZ(1px) rotate3d(1, 0, .12, -90deg)}
        100% {transform: translateZ(1px) rotate3d(1, 0, .12, -360deg)}
      }

      &.start-flip {
        animation: begin-flip-mobile .25s both !important;

        svg {
          animation: none;
        }
      }
  
      &.end-flip {
        animation: finish-flip-mobile .75s both !important;

        svg {
          animation: none;
        }
      }
    }
   
    #section-2B.enter {
      #triangle-3-container {
        animation: enter-triangle-3-mobile 1s .5s both;

        svg {
          animation: enter-triangle-3-svg-mobile 1s .5s both;
        }
      }
    } 
    
    .slide {
      position: absolute;
      top: 72vw;
      left: 28vw;
      width: 62vw;
      font-size: 4vw;
      
      .slider-dots {
        top: 26vw;
        left: 23.5vw;
      }

/*
      .fade-enter {
        opacity: 0;
        transform: translateX(-100%);
      }
      .fade-enter-active {
        opacity: 1;
        transform: translateX(0%);
      }
      .fade-exit {
        opacity: 1;
        transform: translateX(0%);
      }
      .fade-exit-active {
        opacity: 0;
        transform: translateX(100%);
      }
      .fade-enter-active,
      .fade-exit-active {
        transition: opacity 500ms, transform 500ms;
      }
*/

    }
  }
}


#section-3 {
  flex: none;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  padding: 0 !important;
  margin-top: -26vw;

  @media screen and (min-width: 601px) {
    top: 16vw;
  }
 
  @media screen and (min-width: 1400px) {
    top: 19vw;
  }

  &:not(.hide) {
    height: 110vw; /*********MUST MANUALLY ADJUST TO FIX HORIZONTAL SCROLLING PROBLEM ON IOS SAFARI - OVERFLOW X NOT WORKING ****/
    overflow: hidden;
    
    @media screen and (max-width: 600px) {
      margin-top: -20vw;
      height: 320vw;  /*********MUST MANUALLY ADJUST TO FIX HORIZONTAL SCROLLING PROBLEM ON IOS SAFARI - OVERFLOW X NOT WORKING ****/
    }

    @media screen and (min-width: 1400px) {
      height: initial;
      overflow: initial;
    }
  }

  #triangles-container {
    flex: none;
    position: relative;
    z-index: 101;
    width: 100%;

    svg {
      width: 135.4%;
    }  

    @media screen and (max-width: 600px) {
      order: 2;
      top: 5vw;
    }

    #triangle-a {
      position: relative;
    }

    #triangle-b,
    #triangle-c {
      position: absolute;
    }

    #triangle-a,
    #triangle-b,
    #triangle-c {

      left: 0;
      top: 0;
      width: 100%;
      
  
      svg {
        width: 135.4%;

        path {
          filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.15))
        }
      }  
  
    }

    #triangle-b-intersection {
      position: absolute;
      left: 0;
      top: 20vw;
      width: 100%;
      height: 30vw;
      /*
      background: red;
      z-index: 9999999999999;
      */
      @media screen and (max-width: 600px) {
        top: 40vw;
        height: 75vw;
      }

      @media screen and (min-width: 1400px) {
        top: 280px;
        height: 420px;
      }
    }

    #triangle-c-intersection {
      position: absolute;
      left: 0;
      top: 55vw;
      width: 100%;
      height: 25vw;
      /*
      background: orange;
      z-index: 9999999999999;
      */

      @media screen and (max-width: 600px) {
        top: 120vw;
        height: 75vw;
      }

      @media screen and (min-width: 1400px) {
        top: 770px;
        height: 350px;
      }
    }

    #triangle-a {
      z-index: 102;

      @media screen and (max-width: 600px) {
        transform: scale(2);
        transform-origin: left top;
        top: -50vw;
      }
    }

    #triangle-b {
      z-index: 103;

      @media screen and (max-width: 600px) {
        transform: rotate(20deg) scale(1.2);
        top: -5vw;
      }
    }

    #triangle-c {
      z-index: 104;
      transform: translateZ(1px); /* To fix glitch in iOs, going under triangle-b */

      path {
        filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2)) !important;
      }

      @media screen and (max-width: 600px) {
        left: -10vw;
        top: -10vw;
        width: 200%;
      }

      @media screen and (max-width: 320px) {
        top: -35vw;
      }

    }
  }

  #owners {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 102;

    svg {
      width: 135.4%;

      #owners-background-shape {
        filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.1))
      }
    }  

    @media screen and (max-width: 600px) {
      flex: none;
      position: relative;
      top: 15vw;
      transform: translateX(-50vw);
      width: 170vw;
      height: 94vw;
      order: 1;
    }
  }

	.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFBECB;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FED8DF;}
	.st2{fill:#E6E7E8;}
	.st3{fill:#231F20;}
	.st4{clip-path:url(#SVGID_6_);}
	.st5{fill:#FFFFFF;}
	.st6{fill:none;stroke:#C0BFBE;stroke-linecap:round;stroke-linejoin:round;}
	.st7{fill:#EC8583;}
	.st8{fill:#58595B;stroke:#210B0D;stroke-linecap:round;stroke-linejoin:round;}
	.st9{fill:#210B0D;}
	.st10{fill:none;stroke:#210B0D;stroke-linecap:round;stroke-linejoin:round;}
	.st11{fill:none;stroke:#CF0525;stroke-linecap:round;stroke-linejoin:round;}
	.st12{fill:#231F20;}
	.st13{fill:none;stroke:#231F20;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}
	.st14{fill:#FFFFFF;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
	.st15{fill:#414042;}
	.st16{fill:#6D6E71;stroke:#210B0D;stroke-linecap:round;stroke-linejoin:round;}
	.st17{fill-rule:evenodd;clip-rule:evenodd;fill:#EC6A83;}
  .st18{fill-rule:evenodd;clip-rule:evenodd;fill:#DDDCDC;}
  
	.st19{fill:#E6E7E8;stroke:#E6E7E8;stroke-width:8;stroke-miterlimit:10;}
	.st20{fill:#E6E7E8;stroke:#E6E7E8;stroke-width:5;stroke-miterlimit:10;}
	.st21{fill:#E6E7E8;stroke:#E6E7E8;stroke-width:3;stroke-miterlimit:10;}
	.st22{fill:#E6E7E8;stroke:#E6E7E8;stroke-miterlimit:10;}
  .st23{fill:#E6E7E8;stroke:#E6E7E8;stroke-width:0.75;stroke-miterlimit:10;}
  
  #copy-a {
    position: absolute;
    left: 10vw;
    top: 20vw;
    width: 30vw;
    color: darken(#231f20, 10%);
    z-index: 111;

    @media screen and (min-width: 1400px) {
      left: 140px;
      width: 400px;
      font-size: 1.6rem;
    }

    @media screen and (max-width: 600px) {
      top: 89vw;
      font-size: 4vw;
      width: 70vw;
    }

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    > div:first-of-type {
      left: 0;
      top: 0;
      text-shadow: 5px 5px 5px rgba(0,0,0,0.15);
      z-index: 2;
    }

    .quote-icon-1 {
      width: 34vw;
      left: -10vw;
      top: -14vw;
      z-index: 1;
      
      svg path {
        filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.05));
        stroke: rgba(0,0,0,.05);
        stroke-width: .5px;
        fill: lighten(#fed8df, 2%);
      }
    }

    .quote-icon-2 {
      display: none;
      transform: rotateX(180deg) rotateY(180deg);
      left: 5vw;
      top: 28vw;
      z-index: 1;

      svg path {
        stroke: rgba(0,0,0,.05);
        stroke-width: .5px;
        fill: lighten(#fed8df, 2%);
      }
    }
  }

  #copy-b {
    position: absolute;
    left: 5vw;
    top: 45vw;
    width: 132vw;
    color: darken(#231f20, 10%);
    text-shadow: 5px 5px 5px rgba(0,0,0,0.15);
    z-index: 111;

    #left-shape {
      width: 50vw;
      height: 50vw;

      -webkit-shape-outside: polygon(100% 0, 0 0, 0 65%);
      shape-outside: polygon(100% 0, 0 0, 0 65%);
      float: left;

      @media screen and (min-width: 1400px) {
        width: 700px;
        height: 700px;
      }
    }

    #right-shape {
      width: 50vw;
      height: 50vw;

      -webkit-shape-outside: polygon(0 0, 10% 100%, 10% 0%);
      shape-outside: polygon(0 0, 10% 100%, 10% 0%);
      float: right;

      @media screen and (min-width: 1400px) {
        width: 700px;
        height: 700px;
      }
    }

    
    @media screen and (min-width: 1400px) {
      left: 70px;
      top: 650px;
      width: 1848px;
      font-size: 1.6rem;
    }

    @media screen and (max-width: 600px) {
      left: 15vw;
      top: 151vw;
      width: 72vw;
      font-size: 4vw;
      text-align: right;

      #left-shape {
        display: none;
      }

      #right-shape {
        width: 20vw;
        height: 70vw;
        -webkit-shape-outside: polygon(100% 0, 100% 100%, 0 100%);
        shape-outside: polygon(100% 0, 100% 100%, 0 100%);
      }
    }
  }

  #copy-c {
    position: absolute;
    left: 11vw;
    top: 73vw;
    width: 64vw;
    color: darken(#231f20, 10%);
    text-shadow: 5px 5px 5px rgba(0,0,0,0.15);
    z-index: 111;

    @media screen and (min-width: 1400px) {
      left: 154px;
      top: 1072px;
      width: 896px;
      font-size: 1.6rem;
    }

    @media screen and (max-width: 600px) {
      left: 13vw;
      top: 231vw;
      width: 69vw;
      font-size: 4vw;

      #right-shape {
        display: none;
      }
    }

    #left-shape {
      width: 5vw;
      height: 25vw;

      clip-path: polygon(100% 0, 0 0, 0 100%);
      -webkit-shape-outside: polygon(100% 0, 0 0, 0 100%);
      shape-outside: polygon(100% 0, 0 0, 0 100%);
      float: left;

      @media screen and (min-width: 1400px) {
        width: 70px;
        height: 350px;
      }

      @media screen and (max-width: 600px) {
        width: 10vw;
        height: 60vw;
      }
    }

    #right-shape {
      width: 30vw;
      height: 25vw;
      clip-path: polygon(0% 100%, 100% 0, 100% 100%);
      -webkit-shape-outside: polygon(30% 100%, 100% 0, 100% 100%);
      shape-outside: polygon(0% 100%, 100% 0, 100% 100%);
      float: right;

      @media screen and (min-width: 1400px) {
        width: 420px;
        height: 350px;
        shape-margin: 50px;
      }
    }
  }
}

#home-container {
  #section-2A,
  #section-3 {
    .hide > div:not(.triangles-container) {
      display: none;
    }
    .hide .triangles-container svg {
      display: none;
    }
  }

  #section-2B.hide > div:not(#triangle-1) {
    display: none;
  }
  
  #section-2B.hide #triangle-1 svg {
    display: none;
  }

  #footer.hide svg {
    display: none
  }
}




@keyframes enter-triangle {
  0% {transform: scale(0) rotate(180deg)}
  100% {transform: scale(1) rotate(0deg)}
}

#section-2B {
  #triangle-2 svg,
  #triangle-3 svg,
  #we-believe,
  .slide,
  #lion svg {
    opacity: 0;
  }
}

#section-2B.enter {

  #triangle-2 svg {
    transform-origin: left top;
  }

  #we-believe {
    transform-origin: -10vw top;

    @media screen and (min-width: 1400px) {
      transform-origin: -140px top;
    }

    @media screen and (max-width: 600px) {
      transform-origin: -28vw top;
    }
  }

  #triangle-2 svg,
  #we-believe {
    opacity: 1;
    animation: enter-triangle 1s both;
  }

  #triangle-2 svg,
  #triangle-3 svg  {
    transform-origin: left top;
  }

  .slide {
    transform-origin: -33vw -12vw;

    @media screen and (min-width: 1400px) {
      transform-origin: -462px top;
    }

    @media screen and (max-width: 600px) {
      transform-origin: -30vw top;
    }

    .slide-content {
      position: absolute;
      left: 0;
      top: 0;
    }


    .fade-enter {
      opacity: 0;
    }
    
    .fade-enter-active {
      opacity: 1;
      transition: opacity .5s .5s ease-in;
    }

    .fade-exit {
      opacity: 1; 
    }
    .fade-exit-active {
      opacity: 0;
      transition: opacity .5s ease-in;
    }

  }

  #triangle-3 svg,
  .slide {
    opacity: 1;
    animation: enter-triangle 1s .4s both;
  }

  
  @keyframes swirl-in-right-bck {
    0% {
      transform: translateZ(400px) rotate(540deg) scale(3);
      transform-origin: 10% 50%;
      opacity: 0;
    }
    100% {
      transform: translateZ(400px) rotate(0) scale(1);
      transform-origin: 10% 50%;
      opacity: 1;
    }
  }

  #lion svg {
    animation: swirl-in-right-bck 1s 0.7s both;
  }
}

@keyframes enter-triangle-a {
  0% {opacity: 0; transform: translateX(-100vw)}
  100% {opacity: 1; transform: translateX(0)}
}

@keyframes enter-copy-a {
  0% {opacity: 0; transform: scale(1, 0) translateX(-100vw)}
  100% {opacity: 1; transform: scale(1, 1) translateX(0)}
}

@keyframes enter-owners {
  0% {opacity: 0; transform: scale(0)}
  100% {opacity: 1; transform: scale(1)}
}

@keyframes enter-road {
  0% {transform: translate(-160vw, -80vw)}
  100% {transform: translate(0, 0)}
}

@keyframes enter-owner {
  0% {transform: translateY(150vw)}
  100% {transform: translateY(0)}
}

@keyframes enter-triangle-b {
  0% {opacity: 0; transform: translate(-100vw, 100vw)}
  100% {opacity: 1; transform: translate(0, 0)}
}

@keyframes enter-copy-b {
  0% {opacity: 0; transform: scale(0, 1) translate(-100vw, 100vw)}
  100% {opacity: 1; transform: scale(1, 1) translate(0, 0)}
}

@keyframes enter-triangle-c {
  0% {opacity: 0; transform: rotate3d(1, 0.5, 0, -90deg)}
  100% {opacity: 1; transform: rotate3d(0, 0, 0, 0deg)}
}

@keyframes enter-copy-c {
  0% {opacity: 0; transform: rotate3d(1, 0.5, 0, -90deg)}
  100% {opacity: 1; transform: rotate3d(0, 0, 0, 0deg)}
}

#section-3 {
  #triangle-a {

    svg {
      opacity: 0;
    }

    &.enter svg {
      animation: enter-triangle-a 1s both;
    }
  }

  #copy-a {
    opacity: 0;

    &.enter {
      animation: enter-copy-a 1s both;
    }
  }

  #owners {

    svg {
      opacity: 0;
    }

    &.enter svg {
      transform-origin: 50% 25vw;
      animation: enter-owners 1s both;

      #road {
        animation: enter-road 1s 0.5s both;
      }

      #left-person {
        animation: enter-owner 1s 0.75s both;

        path {
          filter: drop-shadow(5px 5px 5px rgba(3, 3, 3, 0.2));
        }
      }

      #right-person {
        animation: enter-owner 1s 1s both;

        path {
          filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
        }
      }
    }
  }

  #triangle-b {

    svg {
      opacity: 0;
    }

    &.enter svg {
      animation: enter-triangle-b 1s both;
    }
  }

  #copy-b {
    opacity: 0;

    &.enter {
      animation: enter-copy-b 1s both;
    }
  }

  #triangle-c {

    svg {
      opacity: 0;
    }

    &.enter svg {
      transform-origin: left bottom;
      animation: enter-triangle-c 1s both;
    }
  }

  #copy-c {
    opacity: 0;

    &.enter {
      transform-origin: left bottom;
      animation: enter-copy-c 1s both;
    }
  }

}
