.privacy-container {
  flex: 1;
  width: 100vw;
  margin-bottom: 100px;

  .pink-background {
    height: 1000px !important;
  }
  
  @keyframes enter-privacy-page-heading {
    0% {transform: scale(2) translateY(-200px);}  
    100% {transform: scale(1) translateY(0);}  
  }

  .page-heading {
    position: relative;
    width: 100%;
    text-align: center;
    
    font-family: ArticoExtraBold;
    color: #ca2a39;
    font-size: 2rem;
    text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    z-index: 99;

    animation: enter-privacy-page-heading 0.8s both;

    @media screen and (max-width: 1024px) {
      margin-top: 50px;
    }
  }

  .privacy-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;

    @keyframes enter-lion-background {
      0% {transform: scale(0) translateY(100px);}  
      100% {transform: scale(1) translateY(0);}  
    }

    .lion-background {
      position: absolute;
      width: 900px;
      max-width: 80vw;
      display: flex;
      justify-content: center;
      top: 50px;
      opacity: .2;

      > div {
        flex: none;
        width: 450px; 
        height: 450px;
        background: url(../../images/avatar-lion.png) no-repeat;
        background-size: 100%;
        filter: drop-shadow(15px 15px 15px rgba(0,0,0,0.25));
        animation: enter-lion-background 0.8s .6s both;

        @media screen and (max-width: 600px) {
          width: 300px; 
          max-width: 100%;
        }
      }
    }

    .privacy-copy {
      position: relative;
      width: 900px;
      max-width: 80vw;
      margin-top: 0px;   
      margin-bottom: 50px;

      font-family: Effra;
      font-size: 1.5rem;
      text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);

      @media screen and (max-width: 600px) {
        font-size: 1.3rem;
        margin-top: 0;
      }

      .heading {
        font-family: ArticoExtraBold;
        color: #ca2a39;
      }
    }
  }
}
