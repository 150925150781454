#footer {
  flex: none;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  z-index: 999; /* Originally 120 */


  #footer-top {
    flex: none;
    width: 100%;
    z-index: 2;
    height: 7.5vw;

    @media screen and (min-width: 1024px) {
      height: 3.75vw;
    }

    #footer-top-shape {
      position: absolute;
      width: 100%;
      height: 7.5vw;
      left: 0;
      top: 0;
      overflow: hidden;

      @media screen and (min-width: 1024px) {
        transform: scaleY(.5);
        transform-origin: top center;
      }

      svg {
        width: 135.4%;

        path {
          fill: #dddcdc;
          stroke: rgba(0,0,0,.6);
          stroke-width: .5px;
        }
      }
    }
/*
    #footer-background-border {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50px;
      background:#dddcdc;

      @media screen and (min-width: 1440px) {
        height: 0;
      }
    }
*/
  }

  #footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    background: #dddcdc;
    margin-top: -1px; /* Otherwise SVG top shape will some times leave a white gap */
    z-index: 2;

    #footer-links {
      display: none;
      justify-content: center;
      width: 100%;

      ul {
        flex: none;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        margin: 0;

        li {
          flex: none;
          margin: 10px;
        }
      }
    }

    #footer-logos {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 900px;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        height: 55px; /* Originally 50px */
        margin: 0 20px;
      }

      .provider-of-choice > div:first-of-type img {
        height: 66px;
        margin: 0 10px 0 15px;
      }

      .provider-of-choice > div:last-of-type img {
        height: 66px;
        margin: 0 10px;
      }

      @media screen and (max-width: 600px) {
        flex-direction: column;

        .provider-of-choice {
          order: 2;
          margin-bottom: 15px;
        }

        > div:last-of-type {
          order: 1;
          margin-bottom: 10px;
        }

        .pha-logo {
          margin-left: 10px;
        }
      }
    }

    #emergency-situations {
      width: 100%;
      max-width: 900px;
      padding: 10px 60px 20px 40px;
      font-size: .6875rem;
      text-align: center;

      span {
        font-weight: 700;
        color: #4f1e91;
        text-transform: uppercase;
      }
    }

    #privacy-policy {
      width: 100%;
      max-width: 900px;
      padding: 0px 60px 20px 60px;
      display: flex;
      justify-content: center;
      font-size: 10px;
      font-weight: 600;

      a:hover {
        color: #ffffff;
      }
    }
  }

  .social-container {
    width: 100%;
    justify-content: center;
    margin: 10px 0 30px 0;

    @media screen and (min-width: 851px) {
      display: none;
    }
  }

  #safety-committee {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    > div:first-of-type {
      width: 40px;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: #522293;
          transition: .3s;
        }
      }
    }

    > div:last-of-type {
      font-size: .5rem;
      color: #522293;
      text-align: center;
      transition: .3s;
    }

    &:hover > div:first-of-type svg path {
      fill: #fff;
    }

    &:hover div {
      color: #fff;
      
    }
  }
}
