@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@400;700&display=swap');

@keyframes slide-in-back-center {
  0% {
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes enter-photo {
  0% {
    right: calc(-60vw - 260px);
    transform: rotateZ(90deg);
  }
  100% {
    right: 0;
    transform: rotateZ(0deg);
  }
}

@keyframes exit-photo {
  0% {
    left: 0;
    transform: rotateZ(0deg);
  }
  100% {
    left: calc(-60vw - 260px);
    transform: rotateZ(90deg);
  }
}

@keyframes exit-photo-tablet {
  0% {
    left: 0;
    transform: rotateZ(0deg);
  }
  100% {
    left: calc(-120vh - 260px);
    transform: rotateZ(90deg);
  }
}

@keyframes exit-photo-mobile {
  0% {
    left: 0;
    transform: rotateZ(0deg);
  }
  100% {
    left: calc(-80vh - 260px);
    transform: rotateZ(90deg);
  }
}


#wrap-container {
  position: absolute;
  left: 0;
  right: 0;

  @media screen and (max-width: 600px) {
    height: 300vw;
    overflow: hidden;
  }

  z-index: 1;
  perspective: 800px;

/*
  &.enter > div {
    animation: slide-in-back-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
*/

  &.enter .photo-wrap {
    animation: enter-photo .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  &.exit .photo-wrap {
    animation: exit-photo .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    
    @media screen and (min-width: 601px) and (max-width: 1024px) {
      animation: exit-photo-tablet .6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @media screen and (max-width: 600px) {
      animation: exit-photo-mobile .6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }

}





.wrap {
  position: absolute;
  top: 20vw;
  left: 15vw;
  
  width: 60vw;
  min-width: 320px; 
  height: 16.25vw;

  z-index: 1;

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    top: 20vw;
    height: 56.25vw;
  }

  @media screen and (max-width: 600px) {
    top: 90vw;
    left: 10vw;
    width: 80vw;
    height: 90vw;
  }
}

.photo {
  width: 16vw;
  min-width: 180px;
  max-width: 260px;
  height: min-content;
  /* height: 320px; */
  position: absolute;
  z-index: 1;
  /* box-shadow: 5px 5px 20px 0px #929292; /* 0 0 1px rgba(0,0,0,.01); */
  
  transition: all 0.6s;
  left: 50%;
  top: -7.75vw; /* position for center photo */

  margin-left: -8vw; /* Half of width */

  @media screen and (max-width: 1125px) {
    margin-left: -90px; /* Half of min-width */
  }

  @media screen and (min-width: 1625px) {
    margin-left: -130px; /* Half of max-width */
  }

  @media screen and (min-width: 1900px) {
    top: calc(-100px - 4vw); /* 20vw = top of .wrap */
  }
 
  @media screen and (min-width: 800px) and (max-width: 1024px) {
    width: 22vw;
    min-width: initial;
    max-width: initial;
    margin-left: -11vw;
  }

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    top: 15vw; 
  }

  @media screen and (max-width: 600px) {
    top: 25vw; 
    width: 13vw;
    min-width: 150px;
    max-width: 216px;
  }



  cursor: pointer;

  &.photo_profile {
    z-index: 2;
  }
}

.photo_center {
  z-index: 999;
}

/*
.photo_center {
  left: 50%;
  top: -7.75vw;

  @media screen and (min-width: 1900px) {
    top: calc(-100px - 7.75vw); /* 20vw = top of .wrap *//*
  }

  margin: 0;
  z-index: 999;
}
*/

.photo .side {
  width: 100%;
  height: 100%;
  background-color: #eee;
  padding: 20px;
  box-sizing: border-box;
}

.photo .side-front {
  position: relative;
  height: min-content;
  box-shadow: 5px 5px 20px 0px #929292; 
}

.photo .side-front img {
  width: 100%;
}

.photo .side-front .image img { 
  width: 100%; 
}

.photo .side-front .caption {
  padding-top: 10px;
  text-align: center;
  font-size: 1.2rem;
  white-space: pre-line;
  line-height: 1.2;
  
/*
  font-family: 'Permanent Marker', cursive;
  color: #333;
  font-weight: 400;

  font-family: 'Indie Flower', cursive;
  font-weight: 700;
  color: #000;
*/
  font-family: 'Kalam', cursive;
  color: #333;
  font-weight: 700;

  @media screen and (min-width: 601px) and (max-width: 1400px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 600px) {
    font-size: .9rem;
  }
}

.photo .side-back {
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 5px 5px 20px 0px #929292; 
}

.photo .side-back .description {
  color: #666;
  font-size: .875rem;
  line-height: 1.5em;
}

.photo-wrap {
  position: absolute;
  width: 100%;
  height: min-content;
  transform-style: preserve-3d;
  transition: all 0.6s;

  right: calc(-60vw - 260px);
  transform: rotateZ(90deg);
}

.photo-wrap .side-front {
  transform: rotateY(0deg);
}

.photo-wrap .side-back {
  transform: rotateY(180deg);
}

.photo-wrap .side {
  backface-visibility: hidden;
}

@media screen and (hover: hover) {
  .photo_front:not(.photo_center) .side-front:hover {
    background-color: #C9C9C9;
    transform: scale(1.015);
    transition: .2s;
  }
}

.photo_front .photo-wrap {
  transform: rotateY(0deg);
}

.photo_back .photo-wrap {
  transform: rotateY(180deg);
}

.nav {
  position: absolute;
  width: 60vw;
  min-width: 300px; /* 320px - margins */
  height: 30px;
  left: 15vw; // Off-center left to line up with center photo 
  top: calc(26.25vw + 230px);
  /* bottom: 20px; */

  line-height: 30px;
  text-align: center;
  z-index: 998;

  @media screen and (min-width: 1900px) {
    top: 700px;
  }

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    top: calc(63vw + 150px);
  }

  @media screen and (max-width: 600px) {
    width: 80vw;
    top: 207.5vw;
    left: 10vw;
  }
}

.profile-nav {
  position: absolute;
  width: 60vw;
  height: 130px; /* Leave room for shadow */
  min-width: 430px; /* 320px - margins */
  top: calc(26.25vw + 240px);
  left: 15vw; // Off-center left to line up with center photo 
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; /* Not true anymore: Necessary for iOs, otherwise can shift page off screen */
  z-index: 997;

  @media screen and (min-width: 1900px) {
    top: 730px;
  }

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    top: calc(70vw + 130px);
  }

  @media screen and (max-width: 600px) {
    flex-flow: row wrap;
    top: 214vw;
    width: 95vw;
    left: 2.5vw;
  }

  #arrows-container {
    width: 95vw;
    flex: none;
    display: flex;
    justify-content: center;
    padding-bottom: 20px; /* so that shadow on arrow buttons is not cut off */
  }
}

.profile-nav-slider {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 350px; /* default for 7 dots ... 7 * 50px width of dot */
  height: 90px;
  margin: 0 12px;
  z-index: 1; /* below arrows */

  @media screen and (hover: hover) {
    &:hover {
      z-index: 3; /* above arrows to allow hover enlargement of dot to overlap them */
    }
  }

  > div {
    position: absolute;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
  }

}

.nav .i {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-color: #aaa;
  text-align: center;
  border-radius: 90%;
  box-shadow: 5px 5px 20px 0px #c3c3c3;;
  transform: scale(0.5);
  transition: transform 0.5s, background-color .2s;
  cursor: pointer;

  @media screen and (hover: hover) {
    &:hover {
      background-color: #555;
    }
  }
}

.nav .i_current {
  transform: scale(0.8);
}

.nav .i_back {
  transform: rotateY(-180deg) scale(0.8);
  background-color: #555;
}

.profile-nav-dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;

  > img {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #555;
    transition: .2s;

    @media screen and (hover: hover) {
      &:hover {
        height: 90px;
        width: 90px;
        border: 3px solid #555;
        z-index: 99999;
      }  
    }
  }

  &.selected {
    > img {
      height: 45px;
      width: 45px;
      border: 3px solid #DD4B60;
      box-sizing: content-box;
    }

    @media screen and (hover: hover) {
      &:hover {
        border: 3px solid #DD4B60;
      }
    }
  }

}

.arrow-right,
.arrow-left {
  flex: none;
  position: relative;
  width: 36px;
  height: 36px;
  transform: translateZ(10px);
  padding: 7px 0;
  border-radius: 50%;
  /* #fef1f3 */
  background: #db596c url('data:image/svg+xml;utf8,<svg width="36px" height="36px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
      <path fill="%23fef1f3" d="M67.36,50v0.19c0,1.57-0.62,3.08-1.73,4.19L46.74,73.27c-2.31,2.31-6.06,2.31-8.37-0.01 \
        c-2.32-2.31-2.31-6.06,0-8.37L53.27,50l-14.9-14.89c-2.31-2.31-2.32-6.06-0.01-8.37c2.32-2.32,6.07-2.32,8.38-0.01l18.89,18.89 \
        C66.79,46.78,67.42,48.36,67.36,50z"/> \
    </svg>') no-repeat;
  background-size: 100%;
  box-shadow: 5px 5px 20px 0px #c3c3c3;
  cursor: pointer;
  transition: .2s;
  z-index: 2;

  @media screen and (hover: hover) {
    &:hover {
      background-color: #c24053;
    }
  }

  &.disabled {
    /* fill="%23eca5b0" */
    background-color: #f5cbd1;
    box-shadow: 5px 5px 15px 0px #d9d9d9;
    cursor: initial;
  }

  @media screen and (max-width: 600px) {
    margin: 0 5px;
  }
}

.arrow-left {
  transform: translateZ(10px) rotateY(180deg);
}
