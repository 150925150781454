$theme-color: #e33a3a;

.employee-resources-container {

  @keyframes enter-employee-resources-page-heading {
    0% {transform: scale(2) translate3d(-100px, 0, -1000px);}  
    100% {transform: scale(1) translate3d(0, 0, -1000px);}  
  }

  .page-heading,
  .section-heading {
    position: relative;
    font-family: ArticoExtraBold;
    color: #ca2a39;
    text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    z-index: 99;
  }

  .page-heading {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    animation: enter-employee-resources-page-heading 0.8s both;

    @media screen and (max-width: 1024px) {
      margin-top: 50px;
    }
  }

  .section-heading {
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 32px;

    @media screen and (max-width: 600px) {
      font-size: 1.2rem;
    }
  }

  $break: 1360px;
  
  .employee-resources-content {
    position: relative;
    min-height: 1000px;
    z-index: 100; /* above page-heading */

    > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      width: 100%;
      padding: 0 80px;
      margin-top: 60px;

      @media screen and (max-width: $break) {
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
      }
  
      @media screen and (max-width: 1024px) {
        padding: 0 20px;
      }
  
      .employee-resources-copy {
        position: relative;
        flex: 1;
        flex-basis: 35%;
        min-width: 300px;
        max-width: 550px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 40px;
        
        font-family: Effra;
        font-size: 1.5rem;
        text-shadow: 5px 5px 5px rgba(71, 43, 43, 0.15);
  
        transform: translateX(100px); 
        opacity: 0;
        z-index: 2;
  
        > div {
          margin-bottom: 40px;
        }

        @media screen and (min-width: #{$break+1}) {
          align-self: center;
          margin-top: -20px;
        }

        @media screen and (max-width: $break) {
          order: 2;
          max-width: 650px;
        }
  
        @media screen and (max-width: 1024px) {
          padding: 0 40px;
        }
  
        @media screen and (min-width: 451px) and (max-width: 600px) {
          padding: 0 20px;
          font-size: 4vw;
        }
  
        @media screen and (max-width: 450px) {
          padding: 0 10px;
          font-size: 4vw;
        }
  
        @keyframes enter-employee-resources-content-copy {
          0% {transform: translateX(-150px); opacity: 0;}
          100% {transform: translateX(0px); opacity: 1;}
        }
      
        @keyframes enter-employee-resources-content-copy-column {
          0% {transform: translateY(100px); opacity: 0;}
          100% {transform: translateY(0px); opacity: 1;}
        }

        &.enter {
          animation: enter-employee-resources-content-copy 1s .2s both;
  
          @media screen and (max-width: #{$break + 1}) {
            animation: enter-employee-resources-content-copy-column 1s .25s both;
          }    
        }   
  
      }
  
      .section-exam-copy {
        margin: 20px 0 40px;
      }

      .caregiver-exam-svg-container {
        position: relative;
        flex: 1;
        flex-basis: 55%;
        width: 100%;
        max-width: 960px;

        display: flex;
        justify-content: flex-start;
        z-index: 1;
        padding: 0 40px;
        margin-bottom: 40px;
        filter: drop-shadow(5px 5px 15px rgba(0,0,0,.2));
  
        perspective: 25000px;
        transform-style: preserve-3d;
  
        div {
          transform-style: preserve-3d;
        }
  /*
        @media screen and (min-width: #{$break+1}) {
          margin-top: -40px;
        }
  */

        @media screen and (max-width: $break) {
          flex: none;
          order: 1;
          max-width: 760px;
          padding: 0;
        }
  
        @media screen and (max-width: 500px) {
          width: 110%;
        }
  
        > div {
          position: relative; 
          width: 100%;
  
  
        }
  
        > div > div:not(:first-of-type) {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
        }
       
        .st0{fill:#FDF0F2;}
        .st1{fill:#FCD4E8;}
        .st2{fill:#B6D2F2;}
        .st3{fill:#7D97F4;}
        .st4{opacity:0.2;}
        .st5{opacity:0;}
        .st6{opacity:0.0909;}
        .st7{opacity:0.1818;}
        .st8{opacity:0.2727;}
        .st9{opacity:0.3636;}
        .st10{opacity:0.4545;}
        .st11{opacity:0.5455;}
        .st12{opacity:0.6364;}
        .st13{opacity:0.7273;}
        .st14{opacity:0.8182;}
        .st15{opacity:0.9091;}
        .st16{fill:url(#SVGID_1_);}
        .st17{fill:url(#SVGID_2_);}
        .st18{fill:url(#SVGID_3_);}
        .st19{fill:#FFFFFF;}
        .st20{fill:url(#SVGID_4_);}
        .st21{opacity:0.3;fill:url(#SVGID_5_);}
        .st22{fill:url(#monitor-border-1);}
        .st23{fill:url(#screen-background-1);}
        .st24{fill:#BCBEC0;}
        .st25{fill:url(#SVGID_6_);}
        .st26{fill:url(#SVGID_7_);}
        .st27{fill:url(#SVGID_8_);}
        .st28{fill:url(#SVGID_9_);}
        .st29{fill:url(#SVGID_10_);}
        .st30{fill:url(#SVGID_11_);}
        .st31{fill:url(#SVGID_12_);}
        .st32{fill:url(#SVGID_13_);}
        .st33{fill:url(#SVGID_14_);}
        .st34{fill:url(#SVGID_15_);}
        .st35{fill:url(#SVGID_16_);}
        .st36{opacity:0.3;fill:url(#SVGID_17_);}
        .st37{fill:url(#SVGID_18_);}
        .st38{fill:url(#SVGID_19_);}
        .st39{fill:#B086CC;}
        .st40{fill:url(#SVGID_20_);}
        .st41{fill:url(#SVGID_21_);}
        .st42{fill:url(#SVGID_22_);}
        .st43{fill:url(#SVGID_23_);}
        .st44{fill:url(#SVGID_24_);}
        .st45{fill:url(#SVGID_25_);}
        .st46{fill:url(#SVGID_26_);}
        .st47{fill:url(#SVGID_27_);}
        .st48{fill:#FF9085;stroke:#FF9085;stroke-miterlimit:10;}
        .st49{fill:#E57C9A;}
        .st50{fill:url(#SVGID_28_);}
        .st51{opacity:0.6;fill:#FFFFFF;}
        .st52{fill:#C4AFE0;}
        .st53{fill:#B198E2;}
        .st54{fill:#AF98D3;}
        .st55{fill:#9984C1;}
        .st56{fill:#BDD0FB;}
        .st57{fill:#B7587F;}
        .st58{fill:#3E1149;}
        .st59{fill:url(#SVGID_29_);}
        .st60{fill:#89315D;}
        .st61{fill:#F19FA4;}
        .st62{fill:#F9C6CC;}
        .st63{fill:#6F5653;}
        .st64{fill:#ED889B;}
        .st65{fill:#C9759F;}
  
        .st70{display:inline;fill:#B198E2;}
        .st71{display:inline;fill:#AF98D3;}
        .st72{display:inline;fill:url(#SVGID_41_);}
        .st73{fill:#E57C9A;}
        .st74{fill:url(#SVGID_42_);}
        .st75{opacity:0.6;fill:#FFFFFF;}
        .st76{fill:url(#SVGID_43_);}
        .st77{fill:url(#SVGID_44_);}
        .st78{fill:url(#SVGID_45_);}
        .st79{fill:url(#SVGID_46_);}
        .st80{opacity:0.6;fill:#B086CC;}
        .st81{fill:url(#SVGID_47_);}
        .st82{fill:url(#SVGID_48_);}
        .st83{fill:url(#SVGID_49_);}
        .st84{fill:url(#SVGID_50_);}
        .st85{fill:url(#SVGID_51_);}
        .st86{fill:url(#SVGID_52_);}
        .st87{fill:url(#SVGID_53_);}
        .st88{opacity:0.6;}
        .st89{fill:url(#SVGID_54_);}
        .st90{fill:#ED889B;}
        .st91{fill:#C9759F;}
        .st92{fill:#9984C1;}
        .st93{fill:#BDD0FB;}
        .st94{fill:#B7587F;}
        .st95{fill:#3E1149;}
        .st96{fill:url(#SVGID_55_);}
        .st97{fill:#89315D;}
        .st98{fill:#F19FA4;}
        .st99{fill:#F9C6CC;}
        .st100{fill:#6F5653;}

        @keyframes enter-employee-resources-background {
          0% {transform: translateZ(-1000px) rotateY(-90deg) rotateZ(45deg) scale(.5);}
          100% {transform: translateZ(-1000px) rotateY(0) rotateZ(0deg) scale(1);}
        }
    
        .background-shape-container {
          transform: translateZ(-1000px) rotateY(-90deg) rotateZ(45deg) scale(.5);
        }

        &.enter .background-shape-container {
          animation: enter-employee-resources-background 1s both;
        }

        @keyframes enter-clipboard {
          0% {transform: translate3d(20vw, -1000px, 0) scale(3);}
          100% {transform: translate3d(0, 0, 0) scale(1);}
        }
 
        .clipboard-container {
          transform: translate3d(20vw, -1000px, 0) scale(3);
        }

        &.enter .clipboard-container {
          animation: enter-clipboard 1s .2s both;
        }

        @keyframes enter-pencil {
          0% {transform: translate3d(-20vw, 100vh, 0) scale(2);}
          100% {transform: translate3d(0, 0, 0) scale(1);}
        }
        
        .pencil-container {
          transform: translate3d(-50vw, -50vh, 0) scale(0);
        }

        &.enter .pencil-container {
          animation: enter-pencil 1s .4s both;
        }

        @keyframes enter-plant {
          0% {transform: translate3d(-50vw, -50vh, 0) scale(0);}
          100% {transform: translate3d(0, 0, 0) scale(1);}
        }

        .plant-container {
          transform: translate3d(-50vw, -50vh, 0) scale(0);
        }

        &.enter .plant-container {
          animation: enter-plant 1s .2s both;
        }

        
        @keyframes enter-book {
          0% {transform: translate3d(-60vw, 20vh, 0) scale(2);}
          100% {transform: translate3d(0, 0, 0) scale(1);}
        }
    
        .book-container {
          transform: translate3d(-60vw, 20vh, 0) scale(2);
        }

        &.enter .book-container {
          animation: enter-book 1s .4s both;
        }

        @keyframes enter-laptop {
          0% {transform: translate3d(50vw, 10vh, 0) scale(3);}
          100% {transform: translate3d(0, 0, 0) scale(1);}
        }
 
        @keyframes enter-laptop-sm-screen {
          0% {transform: translate3d(75vw, 20vh, 0) scale(3);}
          100% {transform: translate3d(0, 0, 0) scale(1);}
        }

        .laptop-container {
          transform: translate3d(50vw, 10vh, 0) scale(3);

          @media screen and (max-width: $break) {
            transform: translate3d(75vw, 20vh, 0) scale(3);
          }
        }

        &.enter .laptop-container {
          animation: enter-laptop 1s .2s both;

          @media screen and (max-width: $break) {
            animation: enter-laptop-sm-screen 1s .2s both;
          }
        }

        @keyframes enter-coffee {
          0% {transform: translate3d(10vw, -100vh, 1000px) scale(.5);}
          100% {transform: translate3d(0, 0, 0) scale(1);}
        }
  
        .coffee-mug-container {
          transform: translate3d(10vw, -100vh, 1000px) scale(.5);
        }

        &.enter .coffee-mug-container {
          animation: enter-coffee 1s .4s both;
        }
      }
    }
  }



  .employee-resources-button {
    width: 350px;
    transition: 0.4s;
    text-align: center;
  
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
  
  .employee-resources-button-background {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    padding: 10px 0;
    background-color: $theme-color;
    border: 4px solid $theme-color;
    border-radius: 24px;
    font-size: 1.5rem;
    color:#efefef;
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.2);
    transition: .4s;
  
    &.submitted {
      background: #efefef;
    }
  }
}

.resources-modal-container {
  #form-modal-header {
    font-weight: 700;
  }

  #form-container {
    padding: 40px 25px;
  }

  a,
  a:focus,
  a:visited {
    color: #d43345;
    font-weight: 700;
    text-decoration: none;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  }

  a:hover {
    color: #75797C;
  }
}
