#qualifying-for-care-container {


  /* Originally wound up below phone */
    /*
  @keyframes enter-qualifying-for-care-page-header {
    0% {transform: translateY(-35vh) scale(0); opacity: 1;}
    10% {transform: translateY(-85vh) scale(1.2); opacity: 1;}
    100% {transform: transformY(0) scale(1); opacity: 1;}
  }

  @keyframes enter-qualifying-for-care-page-header-mobile {
    0% {transform: translateY(-35vh) scale(0); opacity: 1;}
    10% {transform: translateY(-90vh) scale(1.2); opacity: 1;}
    100% {transform: transformY(0) scale(1); opacity: 1;}
  }
  */

  @keyframes enter-qualifying-for-care-page-header {
    0% {transform: translateY(-35vh) scale(0.1);}
    100% {transform: transformY(0) scale(1);}
  }

  @keyframes enter-qualifying-for-care-page-header-mobile {
    0% {transform: translateY(-35vh) scale(0.1);}
    100% {transform: transformY(0) scale(1);}
  }


  .page-header {
    position: absolute;
    top: 150px;
    height: 120px;
    width: 100%;
    margin: -60px 0 60px 0;
    font-family: ArticoExtraBold;
    color: #ca2a39;
    font-size: 2rem;
    text-align: center;
    text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);

    animation: enter-qualifying-for-care-page-header 6s 2.5s both;
    
    @media screen and (min-width: 601px) and (max-width: 1200px) {
      margin-top: -5px;
    }

    @media screen and (max-width: 600px) {
      margin-top: 0;
      font-size: 1.5rem;
      animation: enter-qualifying-for-care-page-header-mobile 6s 2.5s both;
    }
  }

}
