/* ACCORDION */
.accordion-container {
  width: calc(50% - 140px);
  padding-bottom: 2em;

  @media screen and (max-width: 1280px) {
    width: 900px;
    max-width: 90%;
  }

  @keyframes enter-accordion {
    0% {opacity: 0; transform: translate(100px, 100px);}
    100% {opacity: 1; transform: translate(0, 0);}
  }

  > div {
    opacity: 0; transform: translateY(100px);
  }

  &.enter > div {
    animation: enter-accordion 1s 1.25s both;

    @media screen and (max-width: 1280px) {
      animation: enter-accordion 1s both;
    }
  }
}

.accordion {
  position: relative;
  background-color:#eee;
  border-radius: 25px 25px 0 0;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, .2);
}

.accordion-title {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 700; 
  padding: 30px 50px;
  text-decoration: none;
  line-height: 1.5;
  color: #333 !important;
  transition: transform 0.3s ease-in-out;
  
  @media screen and (max-width: 1100px) {
    pointer-events: none;
  }
   
  @media screen and (max-width: 600px) {
    font-size: 1.25rem;
  }

  div {
    pointer-events: auto; /* allow only link and pseudoelement below to be clickable so there is no problem scrolling on mobile */
  }

  @media screen and (max-width: 600px) {
    padding: 2em 1.5em 2em 1em;
  }

  &::before {
    flex: none;
    content: "";
    height: 36px;
    width: 36px;
    float: left; 
    transition: transform 0.3s ease-in-out;
    margin-right: 20px;
    background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 650 650" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
      <circle fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="%23333" stroke-width="40" stroke-miterlimit="10" cx="325" cy="325" r="298.5"/> \
      <path fill="%23333" fill-rule="evenodd" clip-rule="evenodd" d="M164.9,414.5c5.3,5.3,12.4,8.3,19.9,8.3s14.6-3,19.9-8.3l120.1-119.6l120.4,119.8c11,10.9,28.7,10.8,39.6-0.1 \
      c10.9-11,10.8-28.7-0.1-39.6l-140-140c-5.3-5.1-12.5-8-19.9-7.8c-7.3,0-14.4,2.9-19.6,8.1l-140,140 \
      C154.4,386.1,154.3,403.5,164.9,414.5L164.9,414.5z"/> \
    </svg>') no-repeat;
    background-size: 100%;
    transform: rotate(180deg);
    transform-origin: center center;
    pointer-events: auto;
  }

  &.expanded {
    /*  background-color:darken(#38cc70, 10%); */
     
    color: #111;

    &:before {
      transform: rotate(0deg);
    }
  }
}


.accordion-content {
  position: relative;
  height: auto;
  overflow: hidden; 
  margin: 0;

  /* background-color:#eee; */
  background: linear-gradient(to bottom, #fdf0f2, #fdf0f2 50%, #fff 100%);
  font-size: 1em;

  transform-origin: top center;

  z-index: 99999;
  
  &.expanded {
    opacity: 1;
    transform: scale(1.05);
    box-shadow: 5px 5px 15px #666;
    margin-bottom: 15px;
    border-radius: 20px;
    padding: 1.5em 0 1.25em 0;

    max-height: 100em;
  }

  &.collapsed {
    max-height: 0;

    opacity: 0;
    transform: scale(1) rotateX(-60deg);
    box-shadow: 0 0 0 #666;
    margin-bottom: 0;
    border-radius: 0;
  } 

  p {
    font-family: Effra;
    margin: 0 0 0.25em 0;
    padding: 0.25em 2em 0.25em 2em;
  }
}
 

@keyframes enter-accordion-content {
  0% {
    opacity: 0;
    transform: scale(1) rotateX(-60deg);
    transform-origin: 50% 0;
    box-shadow: 0 0 0 #666;
    margin-bottom: 0;
    border-radius: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1.05);
    box-shadow: 5px 5px 15px #666;
    margin-bottom: 15px;
    border-radius: 20px;
    max-height: 1200px;
  }
}

@keyframes exit-accordion-content {
  0% {
    opacity: 1;
    transform: scale(1.05);
    box-shadow: 5px 5px 15px #666;
    margin-bottom: 15px;
    border-radius: 20px;
    max-height: 1200px;
  }
  100% {
    opacity: 0;
    transform: scale(1) rotateX(-60deg);
    box-shadow: 0 0 0 #666;
    margin-bottom: 0;
    border-radius: 0;
    max-height: 0;
  }
}

@keyframes enter-accordion-content-mobile {
  0% {
    opacity: 0;
    transform: scale(1) rotateX(-60deg);
    box-shadow: 0 0 0 #666;
    margin-bottom: 0;
    border-radius: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1.05);
    box-shadow: 5px 5px 15px #666;
    margin-bottom: 15px;
    border-radius: 15px;
    max-height: 2000px;
  }
}

@keyframes exit-accordion-content-mobile {
  0% {
    opacity: 1;
    transform: scale(1.05);
    box-shadow: 5px 5px 15px #666;
    margin-bottom: 15px;
    border-radius: 15px;
    max-height: 2000px;
  }
  100% {
    opacity: 0;
    transform: scale(1) rotateX(-60deg);
    box-shadow: 0 0 0 #666;
    margin-bottom: 0;
    border-radius: 0;
    max-height: 0;
  }
}

@keyframes enter-accordion-content-p {
  0% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes exit-accordion-content-p {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(.9);
  }
}

.accordion-content.expand-content {
  animation: enter-accordion-content 0.45s normal ease-in-out both 1; 

  @media screen and (max-width: 750px) {
    animation: enter-accordion-content-mobile 0.45s normal ease-in-out both 1;
  }

  p {
    animation: enter-accordion-content-p 0.45s normal ease-in-out both 1;
  }
}

.accordion-content.collapse-content {
  animation: exit-accordion-content 0.45s alternate ease-in-out both 1;

  @media screen and (max-width: 750px) {
    animation: exit-accordion-content-mobile 0.45s normal ease-in-out both 1;
  }

  p {
    animation: exit-accordion-content-p 0.45s normal ease-in-out both 1;
  }
}



/* Ripple Effect */
.ripple {
  position: relative;
  overflow: hidden;
}

.ink {
  position: absolute;
  display: block;
  width: 500px;
  height: 500px;
  pointer-events: none;
  border-radius: 50%;
  transform: translateZ(1px) scale(0);
  background: #fff;
  opacity: 1;
}

@keyframes ripple-effect {
  0% {
    opacity: 1;
    transform: translateZ(1px) scale(0);
  }
  100% {
    opacity: 0;
    transform: translateZ(1px) scale(2.5);
  }
}

.ink {
  animation: ripple-effect 0.5s linear;
}



/* Tiles */
.accordion-title {
  width: 100%;
  outline: 0;
  border: 0;
  border-radius: 0;
  transition: all 0.15s ease-in-out;
  overflow: hidden;
  cursor: pointer;

  &:hover,
  &:active,
  &.active,
  &:focus,
  &:active:focus,
  &.active:focus {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.tile-odd {
  background-color: lighten(#CA2939, 40%); /* 308ffa */ /* 2d7cff */
}
.tile-odd:hover {
  background-color: lighten(#CA2939, 38%);
}
.tile-odd .ink { 
  background-color: lighten(#CA2939, 20%);
}


.tile-even {
  background-color: lighten(#CA2939, 32%); /* 00bfff */
}
.tile-even:hover {
  background-color: lighten(#CA2939, 27%);
}
.tile-even .ink {
  background-color: lighten(#CA2939, 12%);
}

 
dl {
  border-radius: 25px 25px 0 0;
}

dl dt:first-of-type {
  border-radius: 25px 25px 0 0;
}

/*
dl dt:last-of-type {
  border-radius: 0 0 25px 25px;
}
*/


