
// @import "./_variables.scss";

@font-face { 
	font-family: Effra; 
	src: url(./fonts/effra-std-rg.ttf);
} 

@font-face { 
	font-family: PoppinsMedium; 
	src: url(./fonts/poppins-medium.ttf);
} 

@font-face { 
	font-family: ArticoExtraBold; 
	src: url(./fonts/artico-expanded-extra-bold.otf);
} 

html {
	font-size: 16px;
	height: 100%;  /* Needed for sticky footer. */
	z-index: 0;
	margin: 0;
}

body {
	margin: 0;
	font-family: Effra, Helvetica, sans-serif;
	width: 100vw;
	min-width: 320px; /* Minimum phone width in active use (iPhone 5) */
	height: 100%;  /* Needed for sticky footer. */
	overflow-y: auto;
	overflow-x: hidden; /* Otherwise home page slider will cause intermittent horizontal scroll bar */
	z-index: 1;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
}

*, * *, * * *, * * * * {
	box-sizing: border-box;
}

a, 
a:focus, 
a:visited {
	text-decoration: none;
}

#root {
	height: 100%;  /* Needed for sticky footer. */
}

#framework {
	display: flex;
	flex-direction: column;

	min-height: 100%;  /* Needed for sticky footer. */

	> div {
		flex: none;
	}

	> div:not(:first-of-type):not(:nth-last-of-type(2)):not(:nth-last-of-type(1)) {
		flex: 1 0 auto; /* Sticky footer */
	}

	> div:nth-last-of-type(2) { /* #footer ... :nth-last-of-type(1) is #footer-nav-icons*/
		flex-shrink: 0; /* Sticky footer */
	}
}

.page,
#page { 
	position: relative;
	width: 100vw;
	min-width: 320px;
	overflow: hidden;

	-webkit-overflow-scrolling: touch;
	/* transform-origin: center calc(50% - 185px); */

	display: flex;
	flex-direction: column;


	#header {
		flex:none;
	}

	#footer {
		flex: none;
	}
}


.background {
	position: fixed;
	min-width: 320px;
	left: 0;
	top: 0;
  display: flex;
  width: 100%;
  height: 100%;


  .left {
    flex: none;
		width: 350px;
		max-width: 65%;
  }

  .right {
		flex: 1;
  	height: 100%;
  }
}

.pink-background {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vw;

	@media screen and (max-width: 600px) {
		height: 270vw;
	}

	@media screen and (min-width: 1400px) {
		height: 100%;
	}

	background: linear-gradient(to bottom, #ec6a83, #ec6a83 90%, #fff 100%); /* #fdf0f2; */
	opacity: .1;
}


.social-container {
	display: flex;
	
	> a {
		flex: none;
		width: 36px;
		height: 36px;
		margin: 0 10px;
		cursor: pointer;

		> div {
			position: relative;
			width: 100%;
			height: 100%;
		}
	}
}

@mixin facebook ($color) {
	background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 650 650" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
		<path fill="#{$color}" d="M631,325c0-169-137-306-306-306S19,156,19,325c0,152.7,111.9,279.3,258.2,302.3V413.5h-77.7V325h77.7v-67.4 \
			c0-76.7,45.7-119.1,115.6-119.1c33.5,0,68.5,6,68.5,6v75.3h-38.6c-38,0-49.9,23.6-49.9,47.8V325h84.9l-13.6,88.5h-71.3v213.8 \
			C519.1,604.3,631,477.7,631,325z"/> \
	</svg>') no-repeat;
	background-size: 100%;

	/* 
		<path id="f" class="st1" d="M444.1,413.5l13.6-88.5h-84.9v-57.4c0-24.2,11.9-47.8,49.9-47.8h38.6v-75.3c0,0-35-6-68.5-6 \
			c-69.9,0-115.6,42.4-115.6,119.1V325h-77.7v88.5h77.7v213.8c15.6,2.4,31.5,3.7,47.8,3.7s32.2-1.3,47.8-3.7V413.5H444.1z"/> \
	*/
}

.facebook-icon {
	/* facebook color: #1877F2 */
	@include facebook ('%23ca2a39');

	#footer & {
		@include facebook ('%234f1e91');

		&:hover {
			@include facebook ('%23000000');
		}
	}

	&:hover {
		@include facebook ('%236d6e70');
	}
}


@mixin instagram ($color) {
	background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 650 650" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
		<path fill="#{$color}" d="M325,19c-83.1,0-93.5,0.4-126.2,1.8c-32.6,1.5-54.8,6.7-74.3,14.2c-20.1,7.8-37.2,18.3-54.2,35.3 \
			c-17,17-27.5,34.1-35.3,54.2c-7.6,19.5-12.7,41.7-14.2,74.3C19.4,231.5,19,241.9,19,325c0,83.1,0.4,93.5,1.8,126.2 \
			c1.5,32.6,6.7,54.8,14.2,74.3c7.8,20.1,18.3,37.2,35.3,54.2c17,17,34.1,27.5,54.2,35.3c19.5,7.6,41.7,12.7,74.3,14.2 \
			c32.6,1.5,43.1,1.8,126.2,1.8c83.1,0,93.5-0.4,126.2-1.8c32.6-1.5,54.8-6.7,74.3-14.2c20.1-7.8,37.2-18.3,54.2-35.3 \
			c17-17,27.5-34.1,35.3-54.2c7.6-19.5,12.7-41.7,14.2-74.3c1.5-32.6,1.8-43.1,1.8-126.2c0-83.1-0.4-93.5-1.8-126.2 \
			c-1.5-32.6-6.7-54.8-14.2-74.3c-7.8-20.1-18.3-37.2-35.3-54.2c-17-17-34.1-27.5-54.2-35.3c-19.5-7.6-41.7-12.7-74.3-14.2 \
			C418.5,19.4,408.1,19,325,19L325,19z M325,74.1c81.7,0,91.4,0.3,123.6,1.8c29.8,1.4,46,6.3,56.8,10.5c14.3,5.6,24.5,12.2,35.2,22.9 \
			c10.7,10.7,17.3,20.9,22.9,35.2c4.2,10.8,9.2,27,10.5,56.8c1.5,32.3,1.8,41.9,1.8,123.7c0,81.7-0.3,91.4-1.8,123.6 \
			c-1.4,29.8-6.3,46-10.5,56.8c-5.6,14.3-12.2,24.5-22.9,35.2c-10.7,10.7-20.9,17.3-35.2,22.9c-10.8,4.2-27,9.2-56.8,10.5 \
			c-32.3,1.5-41.9,1.8-123.6,1.8c-81.7,0-91.4-0.3-123.7-1.8c-29.8-1.4-46-6.3-56.8-10.5c-14.3-5.6-24.5-12.2-35.2-22.9 \
			c-10.7-10.7-17.3-20.9-22.9-35.2c-4.2-10.8-9.2-27-10.5-56.8c-1.5-32.3-1.8-41.9-1.8-123.6c0-81.7,0.3-91.4,1.8-123.7 \
			c1.4-29.8,6.3-46,10.5-56.8c5.5-14.3,12.2-24.5,22.9-35.2c10.7-10.7,20.9-17.3,35.2-22.9c10.8-4.2,27-9.2,56.8-10.5 \
			C233.6,74.4,243.3,74.1,325,74.1z"/> \
		<path fill="#{$color}" d="M325,427c-56.3,0-102-45.7-102-102c0-56.3,45.7-102,102-102c56.3,0,102,45.7,102,102 \
			C427,381.3,381.3,427,325,427z M325,167.9c-86.8,0-157.1,70.4-157.1,157.1c0,86.8,70.4,157.1,157.1,157.1 \
			c86.8,0,157.1-70.4,157.1-157.1C482.1,238.2,411.8,167.9,325,167.9z"/> \
		<path fill="#{$color}"  d="M525.1,161.7c0,20.3-16.4,36.7-36.7,36.7c-20.3,0-36.7-16.4-36.7-36.7c0-20.3,16.4-36.7,36.7-36.7 \
		C508.6,124.9,525.1,141.4,525.1,161.7z"/> \
	</svg>') no-repeat;
	background-size: 100%;

	/* circle *//*
	background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 132 132" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
			<path fill="%23ca2a39" d="M66,2C30.7,2,2,30.7,2,66s28.7,64,64,64s64-28.7,64-64S101.3,2,66,2z M106,84.3c0,12-9.7,21.7-21.7,21.7H47.7 \
				c-12,0-21.7-9.7-21.7-21.7V47.7c0-12,9.7-21.7,21.7-21.7h36.7c12,0,21.7,9.7,21.7,21.7V84.3z"/> \
			<path fill="%23ca2a39" d="M84.3,32.7H47.7c-8.3,0-15,6.7-15,15v36.7c0,8.3,6.7,15,15,15h36.7c8.3,0,15-6.7,15-15V47.7C99.3,39.4,92.6,32.7,84.3,32.7z \
				M66,86c-11,0-20-9-20-20s9-20,20-20s20,9,20,20S77,86,66,86z M87.3,49.3c-2.5,0-4.6-2-4.6-4.6s2-4.6,4.6-4.6s4.6,2,4.6,4.6 \
				S89.8,49.3,87.3,49.3z"/> \
			<circle fill="%23ca2a39" cx="66" cy="66" r="13.3"/> \
		</svg>') no-repeat;
	*/
}

.instagram-icon {
	@include instagram ('%23ca2a39');

	#footer & {
		@include instagram ('%234f1e91');

		&:hover {
			@include instagram ('%23000000');
		}
	}

	&:hover {
		@include instagram ('%236d6e70');
	}
}


@mixin twitter ($color) {
	background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
			<path fill="#{$color}" d="M25,1C11.8,1,1,11.8,1,25s10.8,24,24,24s24-10.8,24-24S38.3,1,25,1z M35,19.6v0.7c0,7.5-5.3,16.1-15,16.1 \
			c-3,0-5.8-1-8.1-2.6c0.5,0.1,0.9,0.1,1.3,0.1c2.4,0,4.7-0.9,6.5-2.4c-2.3,0-4.2-1.6-4.9-3.9c0.3,0.1,0.7,0.1,1,0.1 \
			c0.5,0,1-0.1,1.4-0.2c-2.4-0.6-4.2-2.9-4.2-5.6v-0.1c0.7,0.4,1.5,0.7,2.4,0.7c-1.4-1-2.3-2.7-2.3-4.7c0-1.1,0.2-2.1,0.7-2.9 \
			c2.6,3.4,6.5,5.7,10.9,5.9c-0.1-0.4-0.1-0.9-0.1-1.3c0-3.2,2.4-5.7,5.3-5.7c1.5,0,2.8,0.7,3.8,1.8c1.3-0.3,2.4-0.8,3.4-1.4 \
			c-0.4,1.3-1.2,2.4-2.3,3.1c1-0.1,2.1-0.4,3-0.9C37.1,17.6,36.2,18.6,35,19.6z"/> \
		</svg>') no-repeat;
	background-size: 100%;

	/* 
		<path id="bird" class="st1" d="M254,480.4c144.3,0,223.2-119.6,223.2-223.2c0-3.4,0-6.7-0.2-10.1
			c15.3-11,28.6-24.9,39.2-40.7c-14.1,6.3-29.2,10.4-45.1,12.4c16.2-9.6,28.6-25.1,34.6-43.5c-15.1,9-32,15.5-49.9,19
			c-14.4-15.3-34.7-24.8-57.2-24.8c-43.3,0-78.5,35.2-78.5,78.5c0,6.1,0.8,12.1,2,17.9c-65.2-3.2-123-34.6-161.7-82
			c-6.7,11.6-10.6,25.1-10.6,39.5c0,27.2,13.9,51.3,34.9,65.3c-12.9-0.5-24.9-4-35.5-9.8v1.1c0,37.9,27.1,69.8,62.9,77
			c-6.6,1.8-13.5,2.8-20.7,2.8c-5,0-9.9-0.5-14.7-1.4c9.9,31.2,39,53.9,73.3,54.5c-26.9,21.1-60.7,33.7-97.5,33.7
			c-6.3,0-12.5-0.3-18.7-1.1C168.5,467.4,209.8,480.4,254,480.4"/>
	*/
}

.twitter-icon {
	/* Twitter color: #1DA1F2 */
	@include twitter ('%23ca2a39');

	#footer & {
		@include twitter ('%234f1e91');

		&:hover {
			@include twitter ('%23000000');
		}
	}

	&:hover {
		@include twitter ('%236d6e70');
	}
}
