@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

$theme-color: #e33a3a;

#our-team-container {
  flex: 1;
  position: relative;
  top: -120px; /* Height of header */
  /* height: 270vw; */

  ~ #footer {
    margin-top: 270vw;
  }

  @media screen and (min-width: 1901px) {
    height: 65vw; /* match #desktop-container */

    ~ #footer {
      margin-top: 65vw;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1900px) {
    height: 70vw; /* match #desktop-container */

    ~ #footer {
      margin-top: 70vw;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    height: 130vw;  /* match #desktop-container */

    ~ #footer {
      margin-top: 130vw;
    }
  }

  .pink-background {
    height: 270vw;

    @media screen and (min-width: 1901px) {
      height: 65vw; /* match #desktop-container */
    }
  
    @media screen and (min-width: 1025px) and (max-width: 1900px) {
      height: 70vw; /* match #desktop-container */
    }
  
    @media screen and (min-width: 601px) and (max-width: 1024px) {
      height: 130vw;  /* match #desktop-container */
    }
  }

  .desktop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    min-width: 320px;

    pointer-events: none;
    z-index: 2;

    @media screen and (min-width: 1025px) {
      height: 80vw;
      overflow: hidden;
    }

    @media screen and (min-width: 601px) and (max-width: 1024px) {
      height: 130vw;
      overflow: hidden;
    }

    > svg {
      width: 100vw;
      min-width: 320px;
    }
  }

  .desktop {
    filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, .1));

    .cls-3 {fill: #ff9c0c;}
    .cls-9 {fill: #ce6a73;}
    .cls-10 {fill: #f4dee0;}
    .cls-11 {fill: #9a8591;}
    .cls-12 {fill: #5d3d50;}

    .st0{fill:#FF9C0C;}
    .st1{fill:#231F20;}
    .st2{fill:#E1A6AB;}
    .st3{fill:#CF90A0;}
    .st4{fill:#F1F2F2;}
    .st5{fill:#C27387;}
    .st6{fill:#CE6A73;}
    .st7{fill:#F4DEE0;}
    .st8{fill:#9A8591;}
    .st9{fill:#5D3D50;}
    .st10{fill:#C1272D;}
    /* .st11{font-family:'Poppins-SemiBold';} */
    .st12{font-size:48px;}
    .st13{font-size:30px;}
    .st14{display:none;fill:#FFFFFF;}
    .st15{fill:#FFB3B3;}
    .st16{fill:#FFC648;}
    .st17{fill:#db596c;} /* EA7A7A */
    .st18{fill:#F7C46B;}
    .st19{fill:#EF889F;}
    .st20{fill:#C1BBF9;}
    .st21{fill:#28265E;}
    .st22{fill:#F8E9EB;}
    .st23{fill:#D57F87;}
    .st24{fill:#CB5E68;}
    .st25{fill:#EAC0C3;}
    .st26{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
    .st27{fill:#7C7C7C;}
    .st28{fill:#F4DFE1;}
    .st29{fill:#EBC3C7;}
    .st30{fill:#F6E3E5;}
    .st31{fill:#F9B9B9;} /* edadad ipad bevel */
    .st32{fill:#B7E1DD;}
    .st33{fill:#A3A3A3;}
    .st34{fill:#0F375C;}
    .st35{fill:#EEEBD5;}
    .st36{fill:#C9C9C9;}
    .st37{fill:#FFFFFF;}
    .st38{fill:#f3dada;} /* F6E4E6 */
    .st39{fill:#785C6C;}
    .st40{fill:#59384B;}
    .st41{fill:#D3CACF;}
    .st54{fill:#DD4B60;}
    .st55{font-family:'ArticoExtraBold';}
    .st56{font-size:44.6292px;}

    .st17{fill:#db596c;} /* EA7A7A */
    .st31{fill:#F9B9B9;} /* edadad ipad bevel */
    .st38{fill:#f3dada;} /* F6E4E6 */

    #notepad,
    #notepad-page-1,
    #notepad-page-2 {
      .st13{fill:#FF9C0C;}
      .st19{fill:#CE6A73;}
      .st20{fill:#E5E5E1; stroke:#999999} /* #F4DEE0; */
      .st21{fill:#9A8591;}
      .st22{fill:#5D3D50;}
      .st23{fill:#C1272D;}
      .st24{font-family:'Poppins-SemiBold';}
      .st25{font-size:48px;}
      .st26{font-size:30px;}
    }

    #our-team-2,
    #our-team-3,
    #our-team-mobile {
      display: none;
    }

    @media screen  and (min-width: 1025px) and (max-width: 1400px) {
      #our-team {
        display: none;
      }
      #our-team-2 {
        display: initial;
      }
    }

    @media screen and (min-width: 601px) and (max-width: 1024px) {
      #our-team-3 {
        display: initial;
      }
      #our-team,
      #mousepad,
      #mouse {
        display: none;
      }
    }

    @media screen and (max-width: 600px) {
      #our-team-mobile {
        display: initial;
      }
      #our-team,
      #mousepad,
      #mouse {
        display: none;
      }
    }
    
    @keyframes enter-keyboard {
      0% {transform: translate(-7vw, -20vw);}
      100% {transform: translate(0, -1.75vw);}
    }

    @keyframes enter-keyboard-2 {
      0% {transform: rotate(-14deg) translate(calc(300px - 30vw), -20vw);}
      100% {transform: rotate(-14deg) translate(calc(300px - 20vw), 10vw);}
    }
    
    @keyframes enter-keyboard-3 {
      0% {transform: rotate(-9deg) translate(300px, -20vw);}
      100% {transform: rotate(-9deg) translate(calc(700px - 45vw), 151px)}
    }
    
    @keyframes enter-keyboard-mobile {
      0% {transform: scale(2.2) rotate(-9deg) translate(-1350px, calc(550px + 10vw));}
      /* 100% {transform: scale(1.5) rotate(-9deg) translate(600px, 190px);} */
      /* On right side: scale(1.5) rotate(19deg) translate(480px, 140px) */
      /* On right side: scale(1.5) rotate(19deg) translate(680px, 140px) */
      100% {transform: scale(2.2) rotate(-6deg) translate(-960px, calc(550px - 10vw));}
    }
    
    @keyframes enter-mousepad {
      0% {transform: translate(7vw, -20vw);}
      100% {transform: translate(0, 0);}
    }

    @keyframes enter-mousepad-2 {
      0% {transform: translate(7vw, -20vw);}
      100% {transform: translate(-5vw, 0);}
    }

    @keyframes enter-mousepad-mobile {
      0% {transform: scale(2) rotate(20deg) translate(0, 500px);}
      100% {transform: scale(2) rotate(20deg) translate(-160px, 750px);} /* scale(1.5) rotate(5deg) translate(-150px, 1350px);} */
    }
    
    @keyframes enter-mouse-mobile {
      0% {transform: scale(2) rotate(20deg) translate(20px, 490px);}
      100% {transform: scale(2) rotate(20deg) translate(-180px, 740px);}
    }

    @keyframes enter-ipad {
      0% {transform: translate(-20vw, -20vw);}
      100% {transform: translate(0, 0);}
    }

    @keyframes enter-ipad-tablet {
      0% {transform: scale(1.5) translate(-20vw, 0vw);}
      100% {transform: scale(1.5) translate(0, 20vw);}
    }

    @keyframes enter-ipad-mobile {
      0% {transform: scale(2) translate(-250px, 500px);}
      100% {transform: scale(2) translate(-60px, 800px);}
    }

    @keyframes enter-coffee-mug {
      0% {transform: translate(-30vw, 20vw);}
      100% {transform: translate(0, 0);}
    }

    @keyframes enter-coffee-mug-tablet {
      0% {transform: translate(-30vw, 110vw);}
      100% {transform: translate(0, 90vw);}
    }

    @keyframes enter-coffee-mug-mobile {
      0% {transform: scale(1.5) translate(-400px, 1900px);}
      100% {transform: scale(1.5) translate(-60px, 1700px)}
    }
    
    @keyframes enter-pen-pencil {
      0% {transform: translate(30vw, -20vw);}
      100% {transform: translate(0, 0);}
    }

    @keyframes enter-pen-pencil-tablet {
      0% {transform: translate(30vw, -20vw);}
      100% {transform: translate(-5vw, 2vw);}
    }

    @keyframes enter-pen-pencil-mobile {
      0% {transform: scale(1.5) translate(-500px, -140px);}
      100% {transform: scale(1.5) translate(-700px, 190px);}
    }
    
    @keyframes enter-airpods {
      0% {transform: rotate(10deg) translate(30vw, -5vw);}
      100% {transform: rotate(10deg) translate(10vw, 7.5vw);}
    }

    @keyframes enter-airpods-2 {
      0% {transform: rotate(10deg) translate(30vw, -5vw);}
      100% {transform: rotate(10deg) translate(10vw, 12.5vw);}
    }

    @keyframes enter-airpods-tablet {
      0% {transform: rotate(10deg) translate(50vw, 47.5vw);}
      100% {transform: rotate(10deg) translate(20vw, 42.5vw);}
    }

    @keyframes enter-airpods-mobile {
      0% {transform: scale(2) rotate(10deg) translate(-350px, 1050px);}
      100% {transform: scale(2) rotate(10deg) translate(-570px, 950px);}
    }

    @keyframes enter-notepad {
      0% {transform: rotate(-2.5deg) translate(30vw, -5vw);}
      100% {transform: rotate(-2.5deg) translate(-2.5vw, 5vw);}
    }

    @keyframes enter-notepad-2 {
      0% {transform: rotate(-2.5deg) translate(30vw, -5vw);}
      100% {transform: rotate(-2.5deg) translate(-2.5vw, 7vw);}
    }

    @keyframes enter-notepad-tablet {
      0% {transform: scale(1.3) rotate(-2.5deg) translate(30px, -10vw);}
      100% {transform: scale(1.3) rotate(-2.5deg) translate(-470px, -2vw);}
    }

    @keyframes enter-notepad-mobile {
      0% {transform: scale(2) rotate(10deg) translate(-365px, -80px);}
      /* 100% {transform: scale(2.2) rotate(-2.5deg) translate(-1080px, 500px);} */
      100% {transform: scale(2) rotate(10deg) translate(-865px, -110px);}
    }
    
    &.enter {
      #keyboard {    
        animation: enter-keyboard 1s both;

        @media screen  and (min-width: 1025px) and (max-width: 1400px) {
          animation: enter-keyboard-2 1s both;
        }

        @media screen and (min-width: 601px) and (max-width: 1024px) {
          animation: enter-keyboard-3 1s both;
        }

        @media screen and (max-width: 600px) {
          animation: enter-keyboard-mobile 1s both;
        }
      }
      #mousepad,
      #mouse {    
        animation: enter-mousepad 1s both;

        @media screen and (min-width: 601px) and (max-width: 1400px) {
          animation: enter-mousepad-2 1s both;
        }
        
        @media screen and (max-width: 600px) {
          display: initial;
        }
      }
      #mousepad {
        @media screen and (max-width: 600px) {
          animation: enter-mousepad-mobile 1s both;
        }
      }
      #mouse {
        @media screen and (max-width: 600px) {
          animation: enter-mouse-mobile 1s both;
        }
      }
      #ipad {    
        animation: enter-ipad .8s both;

        @media screen and (min-width: 601px) and (max-width: 1024px) {
          animation: enter-ipad-tablet 1s both;
        }

        @media screen and (max-width: 600px) {
          animation: enter-ipad-mobile 1s both;
        }
      }
      #coffee-mug {    
        animation: enter-coffee-mug 1s both;

        @media screen and (min-width: 601px) and (max-width: 1024px) {
          animation: enter-coffee-mug-tablet 1s both;
        }

        @media screen and (max-width: 600px) {
          animation: enter-coffee-mug-mobile  1s both;
        }
      }
      #pen,
      #pencil {    
        animation: enter-pen-pencil 1s both;

        @media screen and (min-width: 601px) and (max-width: 1024px) {
          animation: enter-pen-pencil-tablet 1s both;
        }
        
        @media screen and (max-width: 600px) {
          animation: enter-pen-pencil-mobile  1s both;
        }
      }
      #airpods {    
        animation: enter-airpods 1.2s both;

        @media screen and (max-width: 1400px) {
          animation: enter-airpods-2 1s both;
        }

        @media screen and (min-width: 601px) and (max-width: 1024px) {
          animation: enter-airpods-tablet 1s both;
        }

        @media screen and (max-width: 600px) {
          animation: enter-airpods-mobile  1s both;
        }
      }

      #notepad,
      #notepad-page-1-enter-animation,
      #notepad-page-2-enter-animation {    
        animation: enter-notepad .7s both;

        @media screen and (min-width: 1025px) and (max-width: 1400px) {
          animation: enter-notepad-2 1s both;
        }

        @media screen and (min-width: 601px) and (max-width: 1024px) {
          animation: enter-notepad-tablet 1s both;
        }

        @media screen and (max-width: 600px) {
          animation: enter-notepad-mobile 1s both;
        }
      }

      
      @keyframes exit-notepad-page {
        0% {transform: rotate(0deg) translate(0, 0);}
        15% {transform: rotate(-20deg) translate(0, 0);}
        100% {transform: rotate(-20deg) translate(-20vw, 300vh);}
      }

      /*
      #notepad-page-1 path {
        fill: purple;
      }
      */
      
      #notepad-page-1,
      #notepad-page-2 {
        transform: rotate(0deg) translate(0, 0); /* needed for Mac and iOs Safari */ 
        
        &.exit {
          transform-origin: 0 0;
          transform-box: fill-box;
          animation: exit-notepad-page 1.5s both;
        }
      }

      .notepad-header {
        font-family: 'Permanent Marker', cursive !important;
        color:#C1272D;
        font-size: 3rem !important;

        &.es {
          font-size: 2.4rem !important;
        }
      }

      .notepad-content {
        font-family: 'Indie Flower', cursive !important;
        font-size: 1.8rem !important;
        line-height: 1.2 !important;
      }

      
      #notepad-text {
        position: absolute;
        right: 20px;
        top: 20vw;
        width: 260px;
        z-index: 9999;
        transform: rotate(-7.5deg);

        .notepad-content {
          color: blue;
          font-size: 1.2rem !important;
          line-height: 1.4rem !important;
        }
      }


      .domino-text-wrapper {
        position: relative;
        display: inline-block;
        padding-top: 0.2em;
        padding-right: 0.05em;
        padding-bottom: 0.1em;
        overflow: hidden;

        .letter {
          display: inline-block;
          line-height: 1em;
          transform-origin: 0 0;
        }
      }
    }

    
    &.onbottom {
      z-index: 3;
    }

    &.ontop {
      z-index: 4;
    }
  }


  .team-button {
    position: absolute;
    top: 130px;
    transition: 0.4s;
    text-align: center;
  
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }

    @media screen and (max-width: 600px) {
      top: 170px;
    }
  }
  
  .team-button-background {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 28px;
    background-color: #db596c;
    border-radius: 24px;
    font-size: 1.4rem;
    color:#efefef;
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.2);
    transition: .4s;
  
    &:hover {
      background-color: #c24053;
    }

    &.submitted {
      background: #efefef;
    }
  }
}
