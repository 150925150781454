.clouds-wrapper {
	position: absolute;
	right: 0;
  top: 0;
  width: 100vw;
  min-width: 320px;
  height: 30vw;
  padding-top: 120px !important;
  z-index: 102 !important;

  @media screen and (max-width: 600px) {
    height: 60vw;
    padding-top: 20vw !important;
  }

	overflow: hidden;
  z-index: 1;

  > div {
    position: absolute;
  }
}

@keyframes float-cloud {
  0% {right: -34vw;}
  100% {right: 100vw;}
}

@keyframes float-cloud-mobile {
  0% {right: -50vw;}
  100% {right: 100vw;}
}

.x1 {
	animation: float-cloud 35s linear infinite both;
  transform: scale(0.65);
  
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.x2 {
  top: 10vw;
	animation: float-cloud 20s 5s linear infinite both;
  transform: scale(0.3);

  @media screen and (max-width: 600px) {
    animation: float-cloud-mobile 20s linear infinite both;
    transform: scale(0.2);
  }
}

.x3 {
  top: 5vw;
	animation: float-cloud 30s 12s linear infinite both;
  transform: scale(0.5);
  
  @media screen and (max-width: 600px) {
    animation: float-cloud-mobile 30s 5s linear infinite both;
    transform: scale(0.3);
  }
}

.cloud {
	background: #fff;
	background: linear-gradient(to bottom,#fff 5%,#f1f1f1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#f1f1f1', GradientType=0);
	
	border-radius: 100px;
	box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);

	height: 120px;
	position: relative;
	width: 350px;
}

.cloud::after, .cloud::before {
	content: '';
  position: absolute;
  background: #fff;
	z-index: -1;
}

.cloud::after {
	left: 50px;
	top: -50px;
  width: 100px;
  height: 100px;
  border-radius: 100px;
}

.cloud::before {
	right: 50px;
	top: -90px;
	width: 180px;
	height: 180px;
  border-radius: 200px;
}