@use "sass:math";

// @import base
$rotationDuration: 2.5s;
//for elevation of phone:
$elevation: 40px;
$bounceDuration: $rotationDuration;
$bounceTimingFunction: ease-in-out;

@keyframes rotate-phone {
	0% {
		transform: rotateY(0);
	}
	100% {
		transform: rotateY(360deg);
	}
}

@keyframes enter-phone {
	0% {
		transform: translate3d(500px, -800px, 0) rotateZ(45deg) rotateY(-280deg);
	}
	100% {
		transform: translate3d(0, 0, 0) rotateZ(0) rotateY(-20deg);
	}
}

@keyframes enter-phone-shadow {
	0% {
		box-shadow: 0 85px 60px rgba(0,0,0,0);
	}
	100% {
		box-shadow: 0 85px 60px rgba(0,0,0,1);
	}
}

@keyframes bounce {
	0% {
		bottom: 0;
	}
	100% {
		bottom: $elevation;
	}
}

@keyframes bounce-avatars {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-40px);
	}
}

@keyframes lower-shadow {
	0% {
		margin-top: 0;
	}
	100% {
		margin-top: $elevation;
	}
}

@keyframes tilt-phone-left {
	0% {
		transform: translateY(0) rotateZ(0) rotateY(20deg);
	}
	100% {
		transform: translateY(0) rotateZ(0) rotateY(-20deg);
	}
}

@keyframes tilt-phone-right {
	0% {
		transform: translateY(0) rotateZ(0) rotateY(-20deg);
	}
	100% {
		transform: translateY(0) rotateZ(0) rotateY(20deg);
	}
}


#qualifying-for-care-container {
	.pink-background {
		height: 1000px !important;
	}
}

.phone-container {  
  position: relative;
  margin: 75px auto 170px auto; /* Originally 75px auto;  */
  width: 299px;
  height: 630px;
  /* animation: bounce $bounceDuration $bounceTimingFunction infinite alternate; */
	transform-style: preserve-3D;
  perspective: 800px;
  transform: translate3d(0, 10px, -100px); /* Originally translate3d(0, -90px, -100px); */
  overflow: visible;
	pointer-events: none;

	* {
		transform-style: preserve-3d;
	}
	


	@media screen and (max-width: 1200px) {
		transform: translate3d(0, 58px, -100px); /* Originally translate3d(0, -30px, -100px); */
	}

  *,
  * div, 
  * div div {
    position: absolute;
  }

  .phone {
    left: 148px;

		transform: translate3d(0, 0, 0) rotateZ(0) rotateY(-20deg); /* rotateY(-80deg); */
		
	/*	animation: rotate-phone 15s linear; */
		animation: enter-phone 8s both;
		
    overflow: visible; /* Required for shadow not to be cut off */

		&.tilt-left {
			animation: tilt-phone-left 1s both;
		}
	
		&.tilt-right {
			animation: tilt-phone-right 1s both;
		}

  }

  .sides {
    position: absolute;
    left: 3px;
    top: 3px;
		transform: scale3d(10, 10, 10); /* CPU optimatization ... offloads size to GPU */
		transform-origin: 0 0;

    > div {
      position: absolute;
      width: 29.1px;
      height: 60.3px; /* Save CPU power by transferring size to GPU transform scaling */ 
			background: #a20e19;
      border: 3.0px solid #a20e19;
      border-radius: 4.5px;
			transform-origin: top left;
    }

    @for $i from 1 through 48 {
      > div:nth-of-type(#{$i}) {
        transform: translateX(-14.8px) rotateY(0deg) translateZ(#{math.div(25 - $i, 10)}px);
      }
    }

		/*
		    > div {
      position: absolute;
      width: 2.91px;
      height: 6.03px; /* Save CPU power by transferring size to GPU transform scaling */ /*
			background: #a20e19;
      border: .30px solid #a20e19;
      border-radius: .45px;
			transform-origin: top left;
    }

    @for $i from 1 through 48 {
      > div:nth-of-type(#{dollarsigni}) {
        transform: translateX(-148px) rotateY(0deg) translateZ(#{25 - dollarsigni}px) scale(100);
      }
    }
		*/
  }

  .front {
    width: 297px;
    height: 630px;
    background: url(../../images/iphone/front-transparent.png) no-repeat;
    background-size: 100%;
    transform: translateX(-148px) rotateY(0deg) translateZ(26px);
  }

	.screen {
    position: absolute;
    left: 3px;
    top: 3px;
    width: 291px;
    height: 603px;
		background: #fff;
		border-radius: 45px;
		border: 1px solid#a20e19;
    transform: translateX(-148px) rotateY(0deg) translateZ(25px);
		/* backface-visibility: hidden; */
		pointer-events: auto;
  }

/*
  .front-underside {
    width: 399px;
    height: 840px;
    background: url(../../images/iphone/back.png) no-repeat;
    background-size: 100%;
    transform: translateX(-199px) rotateY(0deg) translateZ(24px);
  }
*/
  .back {
    width: 297px;
    height: 630px;
    background: url(../../images/iphone/back.png) no-repeat;
    background-size: 100%;
    transform: translateX(-148px) rotateY(180deg) translateZ(24px);
		backface-visibility: hidden;
  }

  .left {
    width: 39px;
    height: 630px;
    background: url(../../images/iphone/left.png) no-repeat;
    background-size: 100%;
    transform: translateX(-166px) rotateY(-90deg);
    border-radius: 37px;
    backface-visibility: hidden;
  }

  /*
  .left-top {
    background: #d44047;
    width: 48px;
    height: 88px;
    top: 2px;
    transform: translateX(-201px) rotateY(-90deg) rotateX(30deg);
  }

  .left-bottom {
    background: #d44047;
    width: 48px;
    height: 70px;
    transform: translateX(-201px) translateY(745px) rotateY(-90deg) rotateX(-40deg);
  }
  */
  .right {
    width: 39px;
    height: 630px;
    background: url(../../images/iphone/right.png) no-repeat;
    background-size: 100%;
    transform: translateX(127px) rotateY(90deg);
    backface-visibility: hidden;
  }
  /*
  .right-top {
    background: #d44047;
    width: 48px;
    height: 88px;
    top: 3px;
    transform: translateX(151px) rotateY(-90deg) rotateX(-30deg);
  }
  
  .right-bottom {
    background: #d44047;
    width: 48px;
    height: 70px;
    transform: translateX(147px) translateY(745px) rotateY(-90deg) rotateX(40deg);
  }
  */
  .shadow {
    width: 297px;
    height: 20px;
    transform: translateX(-149px) translateY(629px) rotateX(90deg) translateY(-60px);
    box-shadow: 0 85px 60px #000;
		animation: enter-phone-shadow 3s 5s both;
    /* animation: lower-shadow $bounceDuration $bounceTimingFunction infinite alternate; */
  }
}

#qualifying-for-care-container.macos.chrome .phone, /* Doesn't animate transform translate properly */
#qualifying-for-care-container.macos.microsoftedge .phone {
	animation: none;
}

.screen div, 
.messages {
	scroll-behavior: smooth;
}

$avatar-size: 30vw;

.avatar-lion {
	width: 80%; /* 450px; */
	height: 80%; /* 450px; */
	backface-visibility: hidden;
	transform: translateZ(2px); /* Keep in front of sides on iOs */

	@media screen and (max-width: 600px) {
		width: 78%; 
		height: 78%;
	}

	background: url(../../images/avatar-lion.png) no-repeat;
	background-size: 100%;
	filter: drop-shadow(15px 15px 15px rgba(0,0,0,0.25));
}


@keyframes enter-lion {
	0% {
		transform: translateZ(100px) scale(0.8) translateX(-150%) rotate(-75deg) rotate3d(1, 1, 1, -135deg);
	}
	100% {
		transform: translateZ(100px) scale(0.8) translateX(0) rotate(-15deg) rotate3d(1, 1, 1, 15deg);
	}
}


@keyframes enter-lion-shadow {
	0% {
		box-shadow: 0 165px 90px rgba(0,0,0,0);
	}
	100% {
		box-shadow: 0 165px 90px rgba(0,0,0,.6);
	}
}

@keyframes tilt-lion {
	0% {transform: rotateY(0);}
	100% {transform: rotateY(30deg);}
}

@keyframes tilt-lion-glow {
	0% {box-shadow: none;}
	100% {box-shadow: 0 0 9.0px 2.0px #ff5864, 3.5px 2.0px 4.0px 1.0px #fff, -3.0px -3.0px 4.0px .5px #fff;}
}

@keyframes untilt-lion {
	0% {transform: rotateY(30deg);}
	100% {transform: rotateY(0);}
}

@keyframes untilt-lion-glow {
	0% {box-shadow: 0 0 9.0px 2.0px #ff5864, 3.5px 2.0px 4.0px 1.0px #fff, -3.0px -3.0px 4.0px .5px #fff;}
	100% {box-shadow: none;}
}

.avatar-lion-container {
	position: absolute;
	left: 5%;
	top: calc(420px - #{math.div($avatar-size, 2)});
	width: $avatar-size; /* 650px; */
	height: $avatar-size; /* 650px; */
	transform-style: preserve-3d;
	perspective: 3000px;
	transform: translateZ(100px) scale(0.8) translateX(100px) rotate(-15deg) rotate3d(1, 1, 1, 15deg); 
	animation: enter-lion 10s both;

	> div {
		transform: translateY(100px);  /* Originally no translateY */
	}

	* {
		transform-style: preserve-3d;
	}

	@media screen and (max-width: 1280px) {
		left: 0;
	}

	@media screen and (max-width: 750px) {
		top: calc(675px - #{math.div($avatar-size, 2)});
	}

	> div {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&.bounce {
			/* animation: bounce-avatars $bounceDuration $bounceTimingFunction infinite alternate; */
		}
	}


}

#qualifying-for-care-container.macos.chrome .avatar-lion-container, /* Doesn't animate transform translate properly */
#qualifying-for-care-container.macos.microsoftedge .avatar-lion-container { 
	perspective: initial; 
	animation: none; /* Must override enter-lion but not override tilt-lion and untilt-lion */
}

.avatar-lion-background {
	position: absolute;
	left: 0;
	top: 0;

	transform: rotateY(30deg); /* Lion is the first texter, so start tilted */

	@media screen and (min-width: 601px) {
		.sides > div:last-of-type {
			animation: tilt-lion-glow 1s 8s both;
		}	
	}

	&.tilt {
		animation: tilt-lion 1s both;

		@media screen and (min-width: 601px) {
			.sides > div:last-of-type {
			// box-shadow: 0 0 20px #fff, 0 0 30px #ff5864, 0 0 40px #ff5864, 0 0 50px #ff5864, 0 0 60px #ff5864, 0 0 70px #ff5864, 0 0 80px #ff5864;
			// box-shadow: 0 0 90px 20px #ff5864, 35px 20px 40px 10px #fff, -30px -30px 40px 5px #fff;
			animation: tilt-lion-glow 1s both;
			}	
		}
	}

	&.untilt {
		animation: untilt-lion 1s both;

		@media screen and (min-width: 601px) {
			.sides > div:last-of-type {
				animation: untilt-lion-glow 1s both;
			}	
		}
	}

	display: flex;
	justify-content: center;
	align-items: center;
	width: $avatar-size; /* 650px; */
	height: $avatar-size; /* 650px; */
	background: #ffd1d0;
	border-radius: 50%;
	border: 20px solid #e33a3a;


	@media screen and (max-width: 600px) {
		border: 5px solid #e33a3a;
	}

	/* box-shadow: 0 85px 60px rgba(0,0,0,0.50); */
	
	.shadow {
		width: 80%;
		height: 40px;
		position: absolute;
		transform: translateY(270px);
		box-shadow: 0 165px 90px rgba(0,0,0,.6);

		animation: enter-lion-shadow 3s 6s both;

		/*
		width: 80%;
		height: 20px;
		transform: translateY(320px);
		box-shadow: 0 85px 60px #000;
		*/

		@media screen and (max-width: 600px) {
			box-shadow: 0 105px 60px rgba(0,0,0,.6);
			transform: translateY(15px);
		}
	}
}	

$shrink-factor: 5;

.avatar-lion-background,
.avatar-man-background {

	.sides {
		position: absolute;
		left: 0;
		top: 0;
		width: calc(100% / #{$shrink-factor});
		height: calc(100% / #{$shrink-factor});
		border-radius: 50%;
		transform: translateZ(-4px) scale3d($shrink-factor, $shrink-factor, $shrink-factor); /* -4px works on Safari-- Necessary for Safari to keep behind image */
		transform-origin: 0 0;

		> div {
			position: absolute;
			left: 0;
			top: 0;
			width: calc(100% + #{math.div(34, $shrink-factor)}px);
			height: calc(100% + #{math.div(34, $shrink-factor)}px);
			background: #a20e19;
			border-radius: 50%;

			@media screen and (max-width: 600px) {
				width: calc(100% + #{math.div(6, $shrink-factor)}px);
				height: calc(100% + #{math.div(6, $shrink-factor)}px);
			}
		}

		@for $i from 1 through 48 {
			> div:nth-of-type(#{$i}) {
				transform: translate3d(#{math.div(-16, $shrink-factor)}px, #{math.div(-16, $shrink-factor)}px, -#{math.div($i, $shrink-factor)}px);

				@media screen and (max-width: 600px) {
					transform: translate3d(#{math.div(-3, $shrink-factor)}px, #{math.div(-3, $shrink-factor)}px, -#{math.div($i, $shrink-factor)}px);
				}
			}
		}

		/* scaling in GPU */
		/*
				> div {
			position: absolute;
			left: -16px;
			top: -16px;
			width: calc((100% + 34px) * .01);
			height: calc((100% + 34px) * .01);
			background: #a20e19;
			border-radius: 50%;
			transform-origin: left top;

			@media screen and (max-width: 600px) {
				left: -3px;
				top: -3px;
				width: calc((100% + 6px) * .01);
				height: calc((100% + 6px) * .01);
			}
		}

		@for $i from 1 through 48 {
			> div:nth-of-type(#{dollarsigni}) {
				transform: scale(100) translateZ(#{- dollarsigni}px);
			}
		}
		*/

		> div:last-of-type {
			background: #e33a3a;
			left: 0;
			top: 0;
			width: calc(100% + #{math.div(40, $shrink-factor)}px);
			height: calc(100% + #{math.div(40, $shrink-factor)}px);
			transform: translate3d(#{math.div(-20, $shrink-factor)}px, #{math.div(-20, $shrink-factor)}px, #{math.div(-48, $shrink-factor)}px);
		}

		@media screen and (max-width: 600px) {
			@for $i from 25 through 48 {
				> div:nth-of-type(#{$i}) {
					display: none;
				}
			}

			> div:nth-of-type(24) {
				background: #e33a3a;
				width: calc(100% + #{math.div(7, $shrink-factor)}px);
				height: calc(100% + #{math.div(7, $shrink-factor)}px);
				transform: translate3d(#{math.div(-3.75, $shrink-factor)}px, #{math.div(-3.75, $shrink-factor)}px, #{math.div(-24, $shrink-factor)}px);
			}
		}
	}
}


.avatar-man {
	width: 77%; /* 500px; */
	height: 77%; /* 500px; */
	backface-visibility: hidden;
	transform: translateZ(2px); /* Keep in front of sides on iOs */

	@media screen and (max-width: 600px) {
		width: 87%; 
		height: 87%;
	}

	background: url(../../images/avatar-man.png) no-repeat;
	background-size: 100%;
	filter: drop-shadow(15px 15px 15px rgba(0,0,0,0.25));
}

@keyframes enter-man {
	0% {
		transform: translateZ(100px) scale(0.8) translateX(150%) rotate(-75deg) rotate3d(1, 1, 1, 135deg);
	}
	100% {
		transform: translateZ(100px) scale(0.8) translateX(0) rotate(15deg) rotate3d(1, 1, 1, -15deg);
	}
}


@keyframes tilt-man {
	0% {
		transform: rotateY(0);
	}
	100% {
		transform: rotateY(-30deg);
	}
}

@keyframes tilt-man-glow {
	0% {box-shadow: none;}
	100% {box-shadow: 0 0 9.0px 2.0px #f22f3d, 3.5px 2.0px 4.0px 1.0px #fff, -3.0px -3.0px 4.0px .5px #fff;}
}

@keyframes untilt-man {
	0% {
		transform: rotateY(-30deg);
	}
	100% {
		transform: rotateY(0);
	}
}

@keyframes untilt-man-glow {
	0% {box-shadow: 0 0 9.0px 2.0px #f22f3d, 3.5px 2.0px 4.0px 1.0px #fff, -3.0px -3.0px 4.0px .5px #fff;}
	100% {box-shadow: none;}
}

.avatar-man-container {
	position: absolute;
	right: 5%;
	top: calc(420px - #{math.div($avatar-size, 2)});
	width: $avatar-size; /* 650px; */
	height: $avatar-size; /* 650px; */
	transform-style: preserve-3d;
	perspective: 3000px;
	transform: translateZ(100px) scale(0.8) translateX(0) rotate(15deg) rotate3d(1, 1, 1, -15deg);
	animation: enter-man 10s 1s both;

	> div {
		transform: translateY(100px);  /* Originally no translateY */
	}

	* {
		transform-style: preserve-3d;
	}

	@media screen and (max-width: 1280px) {
		right: 0;
	}

	@media screen and (max-width: 750px) {
		top: calc(675px - #{math.div($avatar-size, 2)});
	}
	
	> div {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		
		&.bounce {
			/* animation: bounce-avatars $bounceDuration $bounceTimingFunction infinite alternate; */
		}
	}

}

#qualifying-for-care-container.macos.chrome .avatar-man-container, /* Doesn't animate transform translate properly */
#qualifying-for-care-container.macos.microsoftedge .avatar-man-container  {
	perspective: initial;
	animation: none; /* Must override enter-man but not override tilt-man and untilt-man */
}


.avatar-man-background {
	position: absolute;
	left: 0;
	top: 0;

	&.tilt {
		animation: tilt-man 1s both;

		@media screen and (min-width: 601px) {
			.sides > div:last-of-type {
				animation: tilt-man-glow 1s both;
			}	
		}
	}

	&.untilt {
		animation: untilt-man 1s both;

		@media screen and (min-width: 601px) {
			.sides > div:last-of-type {
				animation: untilt-man-glow 1s both;
			}	
		}
	}

	display: flex;
	justify-content: center;
	align-items: center;
	width: $avatar-size; /* 650px; */
	height: $avatar-size; /* 650px; */
	background: #e33a3a;
	border-radius: 50%;
	border: 20px solid #ff7f8c;

	@media screen and (max-width: 600px) {
		border: 5px solid #ff7f8c;
	}

	/* box-shadow: 0 85px 60px rgba(0,0,0,0.50); */

	.shadow {
		width: 80%;
		height: 40px;
		position: absolute;
		box-shadow: 0 165px 90px rgba(0,0,0,.6);
		transform: translateY(270px) rotate3d(1, 1, 1, 15deg) rotate(-15deg);

		@media screen and (max-width: 750px) {
			box-shadow: 0 105px 60px rgba(0,0,0,.6);
			transform: translateY(15px) rotate3d(1, 1, 1, 15deg) rotate(-15deg);
		}
	}
}


/*

body {
	color: #595441;
	margin:0px;
	width: auto;
	font: 16px 'Quicksand';
	background: -moz-linear-gradient(180deg, #000000 0%, #ababab 50%, #000000 100%) #2b2b2b;
	background: -webkit-gradient(linear, right bottom, left bottom, from(#000000), color-stop(0.5, #ababab), to(#000000)) #2b2b2b;
}

.iPhone .text {
	color: #000000;
	-moz-border-radius:12px;
	-webkit-border-radius:12px;
	border-radius:12px;
	border: 1px solid rgba(0, 0, 0, 0.49);
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);
	text-shadow: 0 1px 1px rgba(255, 255, 255, 0.6);
	opacity: 1;
	max-width: 180px;
	width: auto;
	text-align: left;
	height: auto;
	padding-right: 10px;
	padding-left: 10px;
	margin-bottom: 10px;
	margin-left: 10px;
	margin-right: 10px;
}

.iPhone  .text .reflect{
	z-index: 10;
	width: 100%;
	height: 12px;
	-moz-border-radius:12px;
	-webkit-border-radius:12px;
	border-radius:12px;
	margin-right: auto;
	margin-left: auto;
	opacity: 1;
	background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.76) 100%) rgba(255, 255, 255, 0);
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.25)), to(rgba(255, 255, 255, 0.76))) rgba(255, 255, 255, 0);
	clear: both;
	float: right;
}

.iPhone  .text p {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	margin-top: 0;
	margin-bottom: 0;
	line-height: 16px;
	float: left;
	max-width: 180px;
	position: relative;
	top: -7px;
}

.iPhone .text.sent {
	float: right;
	background: -moz-linear-gradient(90deg, #b6e74d 0%, #8fd13e 25.6%, #8acf43 79.9%, #4ab947 100%) #8fd13e;
	background: -webkit-gradient(linear, left bottom, left top, from(#b6e74d), color-stop(0.256, #8fd13e), color-stop(0.799, #8acf43), to(#4ab947)) #8fd13e;
	margin-left: 140px;
}

.iPhone .text.receive{
	float: left;
	word-wrap: break-word;
	background-image: -webkit-gradient(linear, left bottom, left top, from(#e1e1e1), to(#e0e0e0));
	background-image: -moz-linear-gradient(90deg, #e1e1e1 0%, #e0e0e0 100%);
	margin-right: 116px;
}

.iPhone
{
	width: 320px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	border-radius: 3px;
	margin: auto;
	border-style: solid;
	border-width: 1px;
	font: 14px 'Lucida Grande', 'Lucida Sans Unicode', Helvetica, Arial, Verdana, sans-serif;
}

.conversation {
	background-color: #dbe2ed;-moz-box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.49), 1px 1px 2px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.49), 1px 1px 2px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.49), 1px 1px 2px rgba(0, 0, 0, 0.3);
	padding-top: 10px;
	overflow: scroll;
}

.live .conversation{
	height: 200px;
	margin: 0;
}

.gen .conversation{
	height: 360px;
	margin: 0;
}


.iPhone .time {
	margin: 0 auto;
	float:left;
	width:100%
}

.iPhone .time p{
	margin-top: 0;
	text-align: center;
	font-size: 12px;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.96);
	color: #7c899a;
}

.iPhone .text img {
	max-width: 180px;
}
.iPhone a{
	color: #0171f2;
	text-decoration: underline;
}
.iPhone div.title {
	border-top: 1px solid #ffffff;
	border-bottom: 1px solid #000000;
	padding: 7px 0;
	height: 29px;
	margin: 0;
	overflow: visible;
	background: -moz-linear-gradient(90deg, rgb(109, 131, 161) 0%, rgb(126, 147, 175) 47.6%, rgb(140, 158, 181) 50.3%, rgb(180, 191, 206) 100%) #6e84a2;
	background: -webkit-gradient(linear, left bottom, left top, from(rgb(109, 131, 161)), color-stop(0.476, rgb(126, 147, 175)), color-stop(0.503, rgb(140, 158, 181)), to(rgb(180, 191, 206))) #6e84a2;
	color: #ffffff;
}
.iPhone div.title input[type=submit] {
	padding: 0 10px;
	color: #ffffff;
	text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.5);
	font: 12px 'Lucida Grande', 'Lucida Sans Unicode', Helvetica, Arial, Verdana, sans-serif;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius: 6px;
	height: 29px;
	-moz-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.28), 0 1px 0 rgba(255, 255, 255, 0.12);
	-webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.28), 0 1px 0 rgba(255, 255, 255, 0.12);
	box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.28), 0 1px 0 rgba(255, 255, 255, 0.12);
	border-top-style: none;
	color: #ffffff;
	background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.13) 46.9%, rgba(255, 255, 255, 0.22) 51.9%, rgba(255, 255, 255, 0.34) 100%) #486a98;
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0)), color-stop(0.469, rgba(255, 255, 255, 0.13)), color-stop(0.519, rgba(255, 255, 255, 0.22)), to(rgba(255, 255, 255, 0.34))) #486a98;
	border: 1px solid rgba(0, 0, 0, 0.39);
	min-width: 72px;
}
.iPhone div.title input[type=submit]:hover {
	background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.13) 46.9%, rgba(255, 255, 255, 0.22) 51.9%, rgba(255, 255, 255, 0.34) 100%) #3c587f;
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0)), color-stop(0.469, rgba(255, 255, 255, 0.13)), color-stop(0.519, rgba(255, 255, 255, 0.22)), to(rgba(255, 255, 255, 0.34))) #3c587f;
}
.iPhone div.title input[type=submit]:active {
	color: #bebebe;
}
.iPhone .title .contact_name {
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.53);
	font-size: 17px;
	padding-right: 7px;
	padding-left: 7px;
	margin-top: 13px;
	top: 4px;
	position: relative;
}
.iPhone .edit{
	-moz-border-radius:12px;
	-webkit-border-radius:12px;
	border-radius:12px;
	float: right;
	margin-right: 5px;
	margin-left: 0;
}
.iPhone .back {
	-moz-border-radius-bottomleft:120px;
	-webkit-border-bottom-left-radius:120px;
	-moz-border-radius-topleft:120px;
	-webkit-border-top-left-radius:120px;
	float: left;
	margin-left: 5px;
	margin-right: 0;
}
.message {
	background: -moz-linear-gradient(90deg, rgb(197, 199, 203) 0%, rgb(250, 251, 251) 100%) #dddddd;
	background: -webkit-gradient(linear, left bottom, left top, from(rgb(197, 199, 203)), to(rgb(250, 251, 251))) #dddddd;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	height: 25px;
	margin: 0;
	padding: 6px 0;
}
.message input[type=text] {
	-moz-border-radius:12px;
	-webkit-border-radius:12px;
	border-radius:12px;
	width: 200px;
	height: 25px;
	margin: auto auto auto 10px;
	font: 14px 'Lucida Grande', 'Lucida Sans Unicode', Helvetica, Arial, Verdana, sans-serif;
	padding: 0 8px;
	-moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.71), inset -1px 2px 6px rgba(0, 0, 0, 0.41);
	-webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.71), inset -1px 2px 6px rgba(0, 0, 0, 0.41);
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.71), inset -1px 2px 6px rgba(0, 0, 0, 0.41);
	border-top-width: 1px;
	border: 1px solid rgba(0, 0, 0, 0.59);
	word-wrap: break-word;
	white-space: pre-wrap;
}
.message input[type=submit]
{
	-moz-border-radius:12px;
	-webkit-border-radius:12px;
	border-radius:12px;
	border-width: 1px;
	width: 66px;
	height: 25px;
	margin: auto 10px;
	font: 14px 'Lucida Grande', 'Lucida Sans Unicode', Helvetica, Arial, Verdana, sans-serif;
	-moz-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.41), 0 1px 1px rgba(255, 255, 255, 0.38);
	-webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.41), 0 1px 1px rgba(255, 255, 255, 0.38);
	box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.41), 0 1px 1px rgba(255, 255, 255, 0.38);
	border-top-style: none;
	padding: 2px 8px 0;
	color: #ffffff;
	text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.54);
	float: right;
	background: -moz-linear-gradient(90deg, #479d18 0%, #59a21e 45.7%, #6eaf35 49.4%, #abd06f 100%) #57a01e;
	background: -webkit-gradient(linear, left bottom, left top, from(#479d18), color-stop(0.457, #59a21e), color-stop(0.494, #6eaf35), to(#abd06f)) #57a01e;
	border-color: rgba(0, 0, 0, 0.28);
}

.message input[type=submit]:hover{
	background: -moz-linear-gradient(90deg, #316f10 0%, #346011 45.7%, #477122 49.4%, #6c912f 100%) #57a01e;
	background: -webkit-gradient(linear, left bottom, left top, from(#316f10), color-stop(0.457, #346011), color-stop(0.494, #477122), to(#6c912f)) #57a01e;
}

.message input[type=submit]:active {
	color: #b7b7b7;
}

*/





$send-background: #3e84f5;
$send-color: white;
$receive-background: #e9e9ea;
$receive-color: black;
$page-background: white;
$system-color: #8a8a8d;
$sending-time: .2s;
$receiving-time: .4s;

@font-face { 
	font-family: SanFrancisco; 
	src: url(../../fonts/SF-Pro-Display-Regular.otf);
} 


.phone-container .screen {
	display: flex;
	flex-direction: column;
	padding-bottom: 50px;
	font-family: "SanFrancisco", "Helvetica Neue", Helvetica, sans-serif;
	font-size: 16px;
	font-weight: normal;


	.header {
		position: static;
		flex: none;
		display: flex;
		justify-content: center;
		margin: 8px 11px 0 11px;
		height: 88px;
		border-radius: 36px 36px 0 0;
		background: url(../../images/iphone/header.jpg) no-repeat;
		background-size: 100%;

		.contact-picture {
			position: absolute;
			top: 34px;
			left: 50%;
			transform: translateX(-50%);
			width: 36px;
			height: 36px;
			background: #ffd1d0;
			border-radius: 50%;

			> div {
				position: absolute;
				left: 0;
				top: 0;
				width: 95%;
				height: 95%;
				background: url(../../images/avatar-lion.png) no-repeat;
				background-size: 100%;
			}
		}
		
		.contact-name {
			position: relative;
			top: 70px;
			font-size: 12px;
			color: #b3b7bc;
		}
	}

	.icons-input-field {
		position: static;
		flex: none;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		width: 100%;
		margin: 0 0 3px 0;
		padding: 4px 11px 0 11px;
		background: #fff;
		z-index: 100; /* must be above zIndex value set in renderThumbVertical */
		transition: padding .4s;

		&.sending {
			background: transparent;

			.input {
				opacity: 0;
			}
		}

		&.keyboard-hidden {
			padding-bottom: 30px;
		}
		
		.icons {
			position: static;
			align-self: flex-end;
			width: 60px;
			height: 20px;
			background: url(../../images/iphone/camera-apps.jpg) no-repeat;
			background-size: 100%;
			margin: 0 10px 3px 10px;
		}

		.input-field {
			position: static;
			flex: 1;
			display: flex;
			flex-flow: row nowrap;
			border: 1px solid black;
			border-radius: 15px;
			padding: 4px 3px 4px 10px;
			margin-right: 10px;
			font-size: 16px;

			.input {
				position: static;
				flex: 1;
				margin-right: 4px;
			}

			.send-button {
				position: static;
				flex: none;
				width: 20px;
				height: 20px;
				background: url(../../images/iphone/send-button.png) no-repeat;
				background-size: 100%;

				&.inactive {
					opacity: 0.5;
				}
			}
		}
	}
	
	@keyframes enter-keyboard {
		0% {height: 66px;}
		100% {height: 232px;}
	}

	@keyframes exit-keyboard {
		0% {height: 232px;}
		100% {height: 66px;}
	}

	.keyboard-slider {
		bottom: 10px;
		width: 100%;
		display: flex;
		flex-direction: column;
		background: #fff;
		border-radius: 0 0 45px 45px;
		overflow: hidden;
		height: initial; /* when typing... must be dynamic as text input box grows in height with every additional line */

		&.init {
			height: 66px;
		}

		&.show {
			animation: enter-keyboard 0.4s;
		}

		&.hide {
			animation: exit-keyboard 0.4s both;
		}

		.keyboard {	
			position: static;
			flex: none;
			width: 100%;
			height: 192px;
			margin: 0;
			z-index: 101; /* Must be above .icons-input-field */

			> div {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				height: 100%;
				padding: 3px 0 5px 0;
				background:#d6d9de;
				border-top: 1px solid #ced2d7;
				border-radius: 0 0 30px 30px;

				.row {
					position: relative;
					display: flex;
					flex-flow: row nowrap;
					justify-content: space-around;
					margin-bottom: 6px;

					&.row-2.shorten {
						> div:nth-of-type(10) {
							display: none;
						}
					}

					&.row-3.shorten {
						> div:nth-of-type(2),
						> div:nth-of-type(3),
						> div:nth-of-type(4),
						> div:nth-of-type(5),
						> div:nth-of-type(6) {
							width: 34px;
						}

						> div:nth-of-type(7),
						> div:nth-of-type(8) {
							display: none;
						}
					}
					> div {
						position: initial;
						flex: none;
						width: 23px;
						height: 28px;
						background: #fff;
						border-radius: 4px;
						margin-right: 3.5px;
						padding-top: 3px;
						display: flex;
						justify-content: center;
						border: 1px solid #d5d8de;
						box-shadow: 0 1px #aaadb2;
						transition: all 0s;

						&:last-of-type {
							margin-right: 0;
						}

						> div {
							position: static;
							display: flex;
							justify-content: center;
							transition: all 0s;
							
							> div {
								transition: all 0s;
							}
						}
					}

					.pressed {
						position: relative;
						bottom: 44px;
						height: 68px;
						width: 42px;
						display: flex;
						justify-content: center;
						font-size: 28px;
						padding-top: 3px;
						z-index: 1;

						&:not(.wide) {
							background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 170 275" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
								<path fill="%23fff" stroke="%23a4a7ab" stroke-miterlimit="10" d="M167.71,27.2v93.09c0,2.63-0.72,5.2-1.98,7.57c-0.01,0.01-0.01,0.02-0.02,0.04 \
								c-4.42,8.28-12.81,13.28-20.15,18.95c-9.47,7.31-13.74,16.22-13.75,27.12c-0.04,27.14-0.12,54.29-0.03,81.43 \
								c0.03,9.58-7.23,16.79-14.37,16.77c-21.5-0.06-43.01,0.14-64.51-0.14c-5.61-0.07-10.17-4.16-12.31-7.93 \
								c-1.62-2.87-1.83-6.33-1.89-9.51c-0.47-28.61-0.55-57.22-1.21-85.82c-0.2-8.57-5.46-15.14-12.8-20.64 \
								c-5.94-4.45-11.84-9.2-16.52-14.67c-5.18-6.06-5.92-13.7-5.88-21.31c0.14-27.59-0.16-55.19,0.37-82.77 \
								c0.19-9.55,3.13-16.56,13.05-22.51c6-3.6,12.5-3.79,18-3.85c31.84-0.34,63.68-0.21,95.52-0.13c5.44,0.01,11.43-0.26,16.48,0.39 \
								c7,0.9,14,3.6,18,9.9C166.71,16.78,167.71,23.08,167.71,27.2z"/> \
							</svg>') no-repeat;
							background-size: 100%;
							filter: drop-shadow(0 1px #6b6c6f);
						}

						&.wide::before {
							content: '';
							position: absolute;
							bottom: -1px;
							width: 64px;
							height: 68px;
							background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 208 275" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
								<path fill="%23fff" stroke="%23a4a7ab" stroke-miterlimit="10" d="M205.21,27.2v93.09c0,2.63-0.72,5.2-1.98,7.57c-0.01,0.01-0.01,0.02-0.02,0.04 \
								c-4.42,8.28-12.81,13.28-20.15,18.95c-9.47,7.31-13.74,16.22-13.75,27.12c-0.04,27.14-0.53,54.29-0.42,81.43 \
								c0.04,9.58-10.12,16.79-20.12,16.77c-30.11-0.06-60.22,0.14-90.32-0.14c-7.86-0.07-14.24-4.16-17.23-7.93 \
								c-2.27-2.87-2.57-6.33-2.64-9.51c-0.66-28.61,0.07-57.22-0.6-85.82c-0.2-8.57-5.46-15.14-12.8-20.64 \
								c-5.94-4.45-11.84-9.2-16.52-14.67c-5.18-6.06-5.92-13.7-5.88-21.31c0.14-27.59-0.16-55.19,0.37-82.77 \
								c0.19-9.55,3.13-16.56,13.05-22.51c6-3.6,12.5-3.79,18-3.85c31.84-0.34,100.68-0.21,132.52-0.13c5.44,0.01,11.43-0.26,16.48,0.39 \
								c7,0.9,14,3.6,18,9.9C204.21,16.78,205.21,23.08,205.21,27.2z"/> \
							</svg>') no-repeat;
							background-size: 64px 100%;
							filter: drop-shadow(0 1px #6b6c6f);
							z-index: -1;
						}
					}

					.dark {
						background: #b7bdc8;
						border: 1px solid #b3bac5;
						box-shadow: 0 1px #a8acb1;
						padding-top: 0;
					}

					.shift {
						width: 30px;
						margin-right: 10px;

						> div {
							width: 20px;
							height: 20px;
							background: url(../../images/iphone/shift-key-light.jpg) no-repeat;
							background-size: 100%;
							margin-top: 3px;
						}

						&.dark > div {
							background: url(../../images/iphone/shift-key-dark.jpg) no-repeat;
							background-size: 100%;
						}
					}

					.special {
						width: 30px;
						padding-top: 6px;
						margin-right: 10px;
						font-size: 10px;
					}

					.delete {
						width: 30px;
						margin-left: 6px;

						> div {
							width: 24px;
							height: 20px;
							background: url(../../images/iphone/delete-key.jpg) no-repeat;
							background-size: 100%;
							margin-top: 3px;
						}

					}

					.switch {
						width: 64px;
						font-size: 12px;

						> div {
							margin-top: 7px;
						}
					}

					.space {
						width: 130px;
						font-size: 14px;
						padding-top: 0;

						> div {
							margin-top: 5px;
						}
					}

					.return {
						width: 64px;
						font-size: 14px;

						> div {
							margin-top: 5px;
						}
					}

				}

				.bottom {
					position: initial;
					width: 100%;
					padding-bottom: 19.33%;
					background: url(../../images/iphone/ios-keyboard-bottom-3.jpg) no-repeat;
					background-size: 100%;
					border-radius: 0 0 40px 40px
				}
			}

			&.uppercase .row > div:not(.space):not(.switch):not(.return) {
				text-transform: uppercase;
				padding-top: 3px;
				font-size: 18px;
			}
		}

	}

	.messages {
		position: relative;
		flex: none;
		width: 100%;
		height: 294px;
		padding: 0 22px 0 22px;
		display: flex;
		flex-direction: column;
		z-index: 1;
		pointer-events: auto;
		transition: height .4s;

		&.tall {
			height: 455px; /* Must match height set inline for <Scrollbars /> (note that there is 50px padding at bottom of .screen) */
		}
/*
		&::-webkit-scrollbar {
			width: 0;
		}
	*/

		> div:not(.pre-send):not(.typing) {
			flex: none;
			position: relative;

			> div {
				position: relative;
				max-width: 190px;
				word-wrap: break-word;
				margin: 6px 0;
				line-height: 1.2;
				padding: 10px 20px;
				border-radius: 25px;
				
				&:before, &:after {
					content: "";
					position: absolute;
					bottom: 0;
					height: 25px;
				}

				> div {
					position: static;
				}
			}
		}


		.pre-send {
			position: static;
			flex: none;
			transition: flex-grow $sending-time;

			/* margin-top: auto; */
			
			> div {
				position: static;

				> div {
					position: static;
					margin-left: auto;
					margin-right: 25px;
					width: 140px;
				}
			}
		}

		@keyframes enter-send {
			0% {flex: 0;}
			100% {flex: 1;}
		}
		
		@keyframes enter-send-child {
			0% {
				background: #fff; 
				color: #000
			}
			100% {
				background: $send-background; 
				color: $send-color
			}
		}

		.send {
			position: static;
			align-self: flex-end;
			pointer-events: none;
			animation: enter-send $sending-time both;

			> div {
				color: $send-color; 
				background: $send-background;
				animation: enter-send-child $sending-time both;
				
				&:before {
					right: -7px;
					width: 20px;
					background-color: $send-background;
					border-bottom-left-radius: 16px 14px;
					animation: enter-send-child $sending-time both;
				}

				&:after {
					right: -16px;
					width: 16px;
					background-color: $page-background;
					border-bottom-left-radius: 10px;
				}
			}
		}
		
		.receive {
			position: static;
			align-self: flex-start;
			pointer-events: none;
			transition: flex-grow #{$sending-time + 1.6}; /* If only $sending-time... .send will rise too fast */

			> div {
				background: $receive-background;
				color: $receive-color;
				
				&::before {
					left: -7px;
					width: 20px;
					background-color: $receive-background;
					border-bottom-right-radius: 16px 14px;
					z-index: -1;
				}

				&::after {
					left: -16px;
					width: 16px;
					background-color: $page-background;
					border-bottom-right-radius: 10px;
					z-index: 1;
				}
			}
		}


		@keyframes enter-scale {
			0% {transform: scale(0)}
			100% {transform: scale(1)}
		}

		.receive > div {
			transform-origin: left bottom;
			animation: enter-scale $receiving-time both;
		}
/*
		@keyframes enter-rise {
			0% {opacity: 1}
			100% {opacity: 1}
		}

		.send > div {
			// transition: all 60s;
			
			transform-origin: right bottom;
			// animation: enter-rise $sending-time both;
		
		}
	*/
	}

	.home-bar {
		position: absolute;
		top: 583px;
		left: 50%;
		transform: translateX(-50%);
		height: 4px;
		width: 103px;
		border-radius: 2px;
		background: #000;
		z-index: 999999;
	}
}


@keyframes blink {
  50% {opacity: 1;}
}

@keyframes bulge {
  50% {transform: scale(1.05);}
}

.typing {
	position: static !important;
	flex: 1;
	margin-bottom: 20px;

	transform-origin: left bottom;
	animation: enter-scale 0.4s both;

	> div {
		position: static !important;
		background-color: #E6E7ED;
		will-change: transform;
		width: 76px;
		border-radius: 50px;
		padding: 14px;
		display: table;
		margin: 0 11px;
		animation: 2s bulge infinite ease-out;

		&::before,
		&::after {
			content: '';
			position: absolute;
			bottom: -2px;
			left: -2px;
			height: 20px;
			width: 20px;
			border-radius: 50%;
			background-color: #E6E7ED;
		}

		&::after {
			height: 10px;
			width: 10px;
			left: -10px;
			bottom: -10px;
		}

		span {
			position: static;
			height: 12px;
			width: 12px;
			float: left;
			margin: 0 2px;
			background-color: #9E9EA1;
			display: block;
			border-radius: 50%;
			opacity: 0.4;

			@for $i from 1 through 3 {
				&:nth-of-type(#{$i}) {
					animation: 1s blink infinite ($i * .3333s);
				}
			}
		}
	}	
}

.not-typing {
	min-height: 54px; /* Otherwise when typing disappears, screen scrolls down */
}
