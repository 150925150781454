.not-found-container {
  flex: 1;
  width: 100vw;
  margin-bottom: 100px;

  .pink-background {
    height: 1000px !important;
  }
  
  @keyframes enter-error-page-heading {
    0% {transform: scale(2) translateY(-200px);}  
    100% {transform: scale(1) translateY(0);}  
  }

  .page-heading {
    position: relative;
    width: 100%;
    margin-top: -100px;
    text-align: center;
    
    font-family: ArticoExtraBold;
    color: #f9b9b9;
    font-size: 24rem;
    text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    animation: enter-error-page-heading 0.8s both;
    z-index: 99;

    
    @media screen and (min-width: 801px) and (max-width: 1024px) {
      margin-top: -110px;
    }

    @media screen and (min-width: 601px) and (max-width: 800px) {
      margin-top: -140px;
    }

    @media screen and (max-width: 600px) {
      margin-top: -180px;
    }

    span {
      font-size: 28rem;
      position: relative;
      top: 25px;
    }


    > div {
      position: relative;
      width: 300vw;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (min-width: 801px) and (max-width: 1024px) {
        transform: translateX(-50%) scale(.8);
      }

      @media screen and (min-width: 601px) and (max-width: 800px) {
        transform: translateX(-50%) scale(.6);
      }

      @media screen and (max-width: 600px) {
        transform: translateX(-50%) scale(.35);
      }
    }
  }

  .not-found-content {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: -20px;
    text-align: center;

    @media screen and (min-width: 801px) and (max-width: 1024px) {
      margin-top: -80px;
    }

    @media screen and (min-width: 601px) and (max-width: 800px) {
      margin-top: -120px;
    }

    @media screen and (max-width: 600px) {
      margin-top: -180px;
    }

    .copy-form-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }


    .not-found-copy {
      position: relative;
      width: 625px;
      max-width: 80vw;
      margin-top: 0px;   
      margin-bottom: 50px;

      color: #CA2939;
      font-family: Effra;
      font-size: 3rem;

      text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);

      @media screen and (min-width: 801px) and (max-width: 1024px) {
        font-size: 2.6rem;
      }
  
      @media screen and (min-width: 601px) and (max-width: 800px) {
        font-size: 2.4rem;
      }
  
      @media screen and (max-width: 600px) {
        font-size: 1.6rem;
        margin-top: 0;
      }

      .heading {
        font-family: ArticoExtraBold;
        color: #ca2a39;
      }

    /*
      @keyframes enter-employment-triangle {
        0% {transform: rotate(50deg) scale(.4, .8) translate(-150vw, -150vw)}
        100% {transform: rotate(50deg) scale(.4, .8) translate(0, 0)}
      }

      @keyframes enter-employment-triangle-mobile {
        0% {transform: rotate(15deg) scale(.4, .8) translate(-250vw, -250vw)}
        100% {transform: rotate(15deg) scale(.4, .8) translate(0, 0)}
      }


      .employment-triangle {
        position: absolute;
        width: 1200px;
        height: 800px;
        left: -150px;
        top: -80px;


        /* 
        left: -400px;
        top: -280px;
        transform: rotate(25deg); 
        */
/*
        transform: rotate(50deg) scale(.4, .8);
        transform-origin: center 0;
        animation: enter-employment-triangle 1.5s .75s both;
        z-index: -1;

        @media screen and (min-width: 1500px) {
          top: -110px;
        }

        @media screen and (max-width: 600px) {
          left: calc(20vw - 468px);
          top: -130px;
          transform: rotate(15deg) scale(.4, .8);
          animation: enter-employment-triangle-mobile 1.5s .75s both;
        }


        svg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          polygon {
            fill: #ec8583;
          }
        }
      }
      */
    }

  }

  .error-lion-head {
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px; /* 450px; */
    height: 300px; /* 450px; */
    background: url(../../images/avatar-lion.png) no-repeat;
    background-size: 100%;
    filter: drop-shadow(15px 15px 15px rgba(0,0,0,0.25));
  }  
}
