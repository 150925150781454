.employment-container {
  flex: 1;
  width: 100vw;
  margin-bottom: 100px;

  .pink-background {
    height: 1000px !important;
  }
  
  @keyframes enter-employment-page-heading {
    0% {transform: scale(2) translateY(-200px);}  
    100% {transform: scale(1) translateY(0);}  
  }

  .page-heading {
    position: relative;
    width: 100%;
    text-align: center;
    
    font-family: ArticoExtraBold;
    color: #ca2a39;
    font-size: 2rem;
    text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    z-index: 99;

    animation: enter-employment-page-heading 0.8s both;

    @media screen and (max-width: 1024px) {
      margin-top: 50px;
    }
  }



  .employment-content {
    position: relative;
    width: 100vw;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;

    .copy-form-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .employment-svg-container {
      position: relative;
      width: 800px;
      max-width: 100vw;
      perspective: 3000px;
      transform-style: preserve-3d;
      
      .shelves-container {
        position: relative;
        width: 100%;

        #shelves .st1 {
          * {
            transform: translateX(-600px);
          }
          .shelf {
            transform: initial;
          }
        }

        @keyframes enter-shelves {
          0% {transform: translateX(-600px);}
          100% {transform: translateX(0);}
        }
      }

      &.enter .shelves-container #shelves .st1 {
        * {
          animation: enter-shelves 1.5s both;
        }

        .shelf {
          animation: none;
        }
      }

      @keyframes enter-patient {
        0% {transform: translateX(40vw);}
        100% {transform: translateX(0);}
      }

      .patient-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        transform: translateX(40vw);
      }

      &.enter .patient-container {
        animation: enter-patient 2s both;
      }

      @keyframes enter-caregiver {
        0% {transform: scale(1.5) translateY(150px); opacity: 1}
        100% {transform: scale(1) translateY(0); opacity: 1}
      }

      .caregiver-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        transform: scale(1.5) translateY(150px); 
        opacity: 1;
      }

      &.enter .caregiver-container {
        animation: enter-caregiver 1.5s .2s both;
      }


      .st0{fill:#F1F2F2;}
      .st1{clip-path:url(#SVGID_2_);}
      .st2{fill:#FFFFFF;}
      .st3{fill:none;stroke:#231F20;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
      .st4{fill:#BCBEC0;}
      .st5{fill:#CF0525;}
      .st6{fill:none;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
      .st7{fill:#939598;}
      .st8{fill:#EC8583;}
      .st9{fill:#D1D3D4;}
      .st10{fill:#939598;stroke:#939598;stroke-linecap:round;stroke-linejoin:round;}
      .st11{fill:#D1D3D4;stroke:#D1D3D4;stroke-linecap:round;stroke-linejoin:round;}
      .st12{fill:#231F20;}
      .st13{fill:none;stroke:#BCBEC0;stroke-width:10;stroke-miterlimit:10;}
      .st14{fill:none;stroke:#BCBEC0;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;}
      .st15{fill:none;stroke:#FFFFFF;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;}
      .st16{fill:#CA2939;}
      .st17{fill:none;stroke:#BCBEC0;stroke-linecap:round;stroke-linejoin:round;}
      .st18{fill:none;stroke:#BCBEC0;stroke-width:13;stroke-miterlimit:10;}
      .st19{fill:none;stroke:#231F20;stroke-width:9;stroke-miterlimit:10;}
      .st20{fill:none;stroke:#FFFFFF;stroke-linecap:round;stroke-linejoin:round;}
      .st21{fill:none;stroke:#231F20;stroke-width:7;stroke-miterlimit:10;}
      .st22{fill:#808285;}
      .st23{fill:#FFFFFF;stroke:#CF0525;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
      .st24{fill:#ECA595;}
      .st25{fill:#6D6E71;}
      .st26{fill:#414042;}
      .st27{fill:#EC6A83;stroke:#000000;stroke-width:3;stroke-miterlimit:10;}
      .st28{fill:#EC6A83;}
      .st29{fill:#FD0525;}
      .st30{fill:#9E0525;}
      .st31{fill:none;stroke:#EC6A83;stroke-width:0.75;stroke-linecap:round;stroke-linejoin:round;}
    }

    .employment-copy {
      position: relative;
      width: 625px;
      max-width: 80vw;
      margin-top: 0px;   
      margin-bottom: 50px;

      font-family: Effra;
      font-size: 1.5rem;
      text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);

      @media screen and (max-width: 600px) {
        font-size: 1.3rem;
        margin-top: 0;
      }

      .heading {
        font-family: ArticoExtraBold;
        color: #ca2a39;
      }

    /*
      @keyframes enter-employment-triangle {
        0% {transform: rotate(50deg) scale(.4, .8) translate(-150vw, -150vw)}
        100% {transform: rotate(50deg) scale(.4, .8) translate(0, 0)}
      }

      @keyframes enter-employment-triangle-mobile {
        0% {transform: rotate(15deg) scale(.4, .8) translate(-250vw, -250vw)}
        100% {transform: rotate(15deg) scale(.4, .8) translate(0, 0)}
      }


      .employment-triangle {
        position: absolute;
        width: 1200px;
        height: 800px;
        left: -150px;
        top: -80px;


        /* 
        left: -400px;
        top: -280px;
        transform: rotate(25deg); 
        */
/*
        transform: rotate(50deg) scale(.4, .8);
        transform-origin: center 0;
        animation: enter-employment-triangle 1.5s .75s both;
        z-index: -1;

        @media screen and (min-width: 1500px) {
          top: -110px;
        }

        @media screen and (max-width: 600px) {
          left: calc(20vw - 468px);
          top: -130px;
          transform: rotate(15deg) scale(.4, .8);
          animation: enter-employment-triangle-mobile 1.5s .75s both;
        }


        svg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          polygon {
            fill: #ec8583;
          }
        }
      }
      */
    }

    .form-container {
      width: 700px;
      max-width: 90vw;

      .form-input-desc {
        color: darken(#e33a3a, 10%);
      }

      .email-consent-row,
      .phone-consent-row {
        margin-bottom: -10px;
      }
      
      #success-message {
        font-size: 1.4rem;
      
        > div:first-of-type {
          font-size: 1.6rem;
          font-weight: 700;
          margin-bottom: 30px;
        }
      }
    }
    
    .apply-button-container {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;

      @keyframes enter-apply-button {
        0% {transform: translateY(200px);}
        100% {transform: translateX(0);}
      }

      animation: enter-apply-button 1.5s both;

      > div {
        position: relative;
        flex: none;
      }
    }
  }
}
