.placeDiv {
  z-index: 9999;
  position: absolute;
}

.map-container {
  position: relative;
}

.placecard {
  
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    max-width: 330px;
    width: 100%;
    background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box;
    border-radius: 2px 2px 2px 2px;
    font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
    margin: 10px;
    padding: 9px 4px 9px 11px;
    overflow: hidden;
  }
  
  &__left {
    text-align: left;
  }
  
  &__right {
    text-align: center;
    margin-right: 6px;
  }
  
  &__business-name {
    cursor: default;
    height: 19px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
    perspective-origin: 100px 9.5px;
    transform-origin: 100px 9.5px;
    font: normal normal 500 normal 14px / normal Roboto, Arial;
    overflow: hidden;
    margin: 0;
  }
  
  &__info {
    color: rgb(91, 91, 91);
    cursor: default;
    height: 32px;
    width: 200px;
    column-rule-color: rgb(91, 91, 91);
    perspective-origin: 100px 16px;
    transform-origin: 100px 16px;
    border: 0px none rgb(91, 91, 91);
    font: normal normal normal normal 12px / normal Roboto, Arial;
    margin: 6px 0px 0px;
    outline: rgb(91, 91, 91) none 0px;
  }
  
  &__direction-icon {
    background: rgba(0, 0, 0, 0) url("https://maps.gstatic.com/mapfiles/embed/images/entity11.png") repeat scroll 0px 0px / 70px 210px padding-box border-box;
    height: 22px;
    width: 22px;
    margin-right: auto;
    margin-left: auto;
  }
  
  &__direction-link {
    color: rgb(58, 132, 223);
    display: block;
    /* height: 43px; */
    text-decoration: none;
    width: 54.7344px;
  }
  
  &__view-large {
    display: block;
    margin-top: 10px;
    color: rgb(58, 132, 223);
    text-decoration: none;
  }
}



.loader {
  padding: 20px 24px;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #689760;
  color: #689760;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #689760;
  color: #689760;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #689760;
  color: #689760;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #689760;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
