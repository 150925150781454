
#resources-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @mixin svg-triangle-1 ($color) {
    background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 840 600" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
        <polyline fill="%23#{$color}" points="13.93,311.12 744.92,594.7 777.47,4.76 "/> \
    </svg>') no-repeat;
    background-size: 100%;
  }

  @mixin svg-triangle-2 ($color) {
    background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 840 600" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"> \
        <polygon fill="%23#{$color}" points="11.25,21.56 829.22,6.38 596.46,593.25"/> \
    </svg>') no-repeat;
    background-size: 100%;
  }

  @keyframes enter-page-heading {
    0% {transform: translateY(-30vh) scale(2);}  
    100% {transform: translateY(0) scale(1);}  
  }

  .page-header {
    width: 100%;
    max-width: 90vw;
    font-family: ArticoExtraBold;
    color: #ca2a39;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 60px;
    text-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);

    animation: enter-page-heading 0.8s both;

    @media screen and (max-width: 600px) {
      margin-top: 40px;
    }
  }

  svg {
    width: 0;
    height: 0;
  }

  @keyframes hover-county {
    0% {transform: translate(0, 0);}
    100% {transform: translate(5px, -20px);} /* scale and clip-path causes flickering in Chrome */
  }

  @keyframes unhover-county {
    0% {transform: translate(5px, -20px);}
    100% {transform: translate(0, 0);}
  }

  .counties-container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0 80px;
    margin-right: -40px;

    @media screen and (max-width: 600px) {
      padding: 0 20px;
    }

    @media screen and (min-width: 601px) and (max-width: 1024px) {
      padding: 0 30px;
    }

    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      padding: 0 40px;
    }

    .county {
      position: relative;
      flex: 1 1 25vw;
      min-width: 25vw;
      margin: 0 40px 200px 0;
      filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.3));
      pointer-events: none;
      transition: 0.4s;
      
      @media screen and (max-width: 900px) {
        flex: 1 1 95vw;
        min-width: 90vw;
        margin: 0 40px 35vw 0;
      }

      @media screen and (min-width: 901px) and (max-width: 1440px) {
        flex: 1 1 40vw;
        min-width: 40vw;
      }

      @keyframes hover-filter {
        0% {
          filter: drop-shadow(2.5px 2.5px 0px #666) 
                  drop-shadow(2.5px -2.5px 0px #666) 
                  drop-shadow(-2.5px 2.5px 0px #666) 
                  drop-shadow(-2.5px -2.5px 0px #666)
                  drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.3));
        }
        100% { 
          filter: drop-shadow(2.5px 2.5px 0px #333) 
                  drop-shadow(2.5px -2.5px 0px #333) 
                  drop-shadow(-2.5px 2.5px 0px #333) 
                  drop-shadow(-2.5px -2.5px 0px #333)
                  drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.3));
        }
      }


      > .clipped-image {
        position: relative;
        width: 100%;
        filter: drop-shadow(2.5px 2.5px 0px #666) 
                drop-shadow(2.5px -2.5px 0px #666) 
                drop-shadow(-2.5px 2.5px 0px #666) 
                drop-shadow(-2.5px -2.5px 0px #666)
                drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.3));
        transition: 0.4s;
        transform: translateZ(1px); /* Mac and iOs Safari requires to render clip-path correctly */
        will-change: opacity;
        z-index: 2;
        
        > div {
          position: relative;
          width: 100%;
          padding-bottom: #{65.67% * 1};
          background-repeat: no-repeat;
          background-size: cover;
          pointer-events: auto;
          cursor: pointer;
        }

        > div > div {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-size: inherit;
          background-position: inherit;
          background-repeat: no-repeat;
          opacity: 1;
          transition: 0.6s;
        }
      } 

      &:hover {
        /* transform: translateZ(100px) !important; */

        .clipped-image {
          animation: hover-filter 0.4s both;

          filter: drop-shadow(2.5px 2.5px 0px #333) 
                  drop-shadow(2.5px -2.5px 0px #333) 
                  drop-shadow(-2.5px 2.5px 0px #333) 
                  drop-shadow(-2.5px -2.5px 0px #333)
                  drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.3));
        }
      }

      &:hover .clipped-image > div > div {
        &:not(.safari) {
          opacity: 0; /* In Safari, this causes the filter drop shadow to disappear and reappear */
        }
        &.safari {
          background-image: url('../../images/spacer.gif') !important; /* This transition doesn't work in Firefox */
        }
      }

      .triangle {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        padding-bottom: 71.43%; /* 600/840 */   
        background-color: #EC6A83;
        transform: translateZ(1px); /* Mac and iOs Safari requires to render clip-path correctly */

        transition: 0.4s;

        pointer-events: auto;
        cursor: pointer;
        z-index: 1;

        > div {
          position: absolute;
        }

        .county-name {
          position: absolute;
          top: 12%;
          left: 50%;
          transform: translateX(-50%);
          color: #333;
          font-size: 1.8vw;
          font-weight: 700;
          transition: 0.4s;

          @media screen and (max-width: 900px) {
            font-size: 5vw;
          }
    
          @media screen and (min-width: 901px) and (max-width: 1440px) {
            font-size: 2.7vw;
          }
        }
      }

      &:hover .triangle {
        background-color: darken(#EC6A83, 7.5%); /* #ca2a39; */
        
        .county-name {
          color: #111;
        }
      }
    }

        
    @keyframes enter-county {
      0% {
        transform: translateY(50vh);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .county {
      opacity: 0;
    }

    .county.entered {
      opacity: 1;
    }

    .county.include {
      display: block;
    }

    .county.exclude {
      display: none;
    }

    .county.enter {
      animation: enter-county .8s both;
    }

    .county.entered.hover {
      animation: hover-county .4s both;
    }

    .county.entered.unhover {
      animation: unhover-county .4s both;
    }
    
    .shape-1 {
      .clipped-image {
        width: 80%;
        
        > div {
          -webkit-clip-path: url(#shape-1-clippath);
          clip-path: url(#shape-1-clippath); 
          /*circle(50% at 50% 50%) */
        }
      }

      .triangle {
        /* @include svg-triangle-1('000'); *//* EC6A83 */
        -webkit-clip-path: url(#triangle-1-clippath);
        clip-path: url(#triangle-1-clippath);
        right: 0;
        top: 9.5%;
        padding-bottom: 75%;
        /* filter: invert(51%) sepia(61%) saturate(608%) hue-rotate(301deg) brightness(99%) contrast(87%);  *//* EC6A83 */

        .county-name {
          left: 68%;
          top: 60%;
        }
      }
    }

    .shape-2 {
      .clipped-image {
        left: -2%;
        width: 60%;
        
        > div {
          -webkit-clip-path: circle(50% at 50% 50%);
          clip-path: circle(50% at 50% 50%);
          width: 101%;
          padding-bottom: 101%; /*Chrome cuts border off at 100% during transition */
        }
      }

      .triangle {
        /* @include svg-triangle-2('000'); */
        -webkit-clip-path: url(#triangle-2-clippath);
        clip-path: url(#triangle-2-clippath);
        right: -4%;
        top: 9.5%; 
        
        .county-name {
          left: 72.5%;
          top: 12%;
        }
      }
    }

    .shape-3 {
      .clipped-image {
        width: 75%;
        
        > div {
          -webkit-clip-path: url(#shape-3-clippath);
          clip-path: url(#shape-3-clippath); 
          right: 0;
          top: -8%;
          padding-bottom: 75%;
        }
      }

      .triangle {
        -webkit-clip-path: url(#triangle-3-clippath);
        clip-path: url(#triangle-3-clippath);
        right: -3%;
        top: 15%;
        
        .county-name {
          left: 44%;
          top: 74%;
        }
      }
    }

    .shape-4 {
      .clipped-image {
        width: 90%;
       
        > div {
          -webkit-clip-path: url(#shape-4-clippath);
          clip-path: url(#shape-4-clippath); 
          left: -2%;
          top: -5%;
        }
      }

      .triangle {
        -webkit-clip-path: url(#triangle-4-clippath);
        clip-path: url(#triangle-4-clippath);
        right: 8%;
        top: 30%;

        .county-name {
          left: 69%;
          top: 58%;
        }
      }
    }

    .shape-5 {
      .clipped-image {
        width: 83%;
       
        > div {
          -webkit-clip-path: url(#shape-5-clippath);
          clip-path: url(#shape-5-clippath); 
          padding-bottom: 75%;
        }
      }

      .triangle {
        -webkit-clip-path: url(#triangle-5-clippath);
        clip-path: url(#triangle-5-clippath);
        right: 0;
        top: 25%;

        .county-name {
          left: 28%;
          top: 60%;
        }
      }
    }

    .shape-6 {
      .clipped-image {
        width: 85%;
        right: -10%;
       
        > div {
          -webkit-clip-path: url(#shape-6-clippath);
          clip-path: url(#shape-6-clippath); 
        }
      }

      .triangle {
        -webkit-clip-path: url(#triangle-6-clippath);
        clip-path: url(#triangle-6-clippath);
        right: 18%;
        top: 0%;

        .county-name {
          left: 29%;
          top: 45%;
        }
      }
    }

    .shape-7 {
      .clipped-image {
        width: 85%;
       
        > div {
          -webkit-clip-path: url(#shape-7-clippath);
          clip-path: url(#shape-7-clippath); 
          padding-bottom: 80%;
        }
      }

      .triangle {
        -webkit-clip-path: url(#triangle-7-clippath);
        clip-path: url(#triangle-7-clippath);
        right: 5%;
        top: 25%;

        .county-name {
          left: 32.5%;
          top: 54%;
        }
      }
    }

    .shape-8 {
      .clipped-image {
        width: 60%;
        right: -30%;
       
        > div {
          -webkit-clip-path: circle(50% at 50% 50%);
          clip-path: circle(50% at 50% 50%);
          width: 101%;
          padding-bottom: 101%; /*Chrome cuts border off at 100% during transition */
        }
      }

      .triangle {
        -webkit-clip-path: url(#triangle-8-clippath);
        clip-path: url(#triangle-8-clippath);
        right: 15%;
        top: 9.5%;

        .county-name {
          left: 27%;
          top: 13%;
        }
      }
    }

    .shape-9 {
      .clipped-image {
        width: 90%;

        > div {
          -webkit-clip-path: url(#shape-9-clippath);
          clip-path: url(#shape-9-clippath);
        }
      }

      .triangle {
        -webkit-clip-path: url(#triangle-9-clippath);
        clip-path: url(#triangle-9-clippath);
        width: 110%;
        right: -5%;
        top: 15%;

        .county-name {
          left: 56.5%;
          top: 60%;
        }
      }
    }

    .shape-10 {
      .clipped-image {
        width: 90%;

        > div {
          -webkit-clip-path: url(#shape-10-clippath);
          clip-path: url(#shape-10-clippath);
        }
      }

      .triangle {
        -webkit-clip-path: url(#triangle-10-clippath);
        clip-path: url(#triangle-10-clippath);
        right: 5%;
        top: 20%;

        .county-name {
          left: 69.5%;
          top: 58%;
        }
      }
    }

    .shape-11 {
      .clipped-image {
        width: 80%;

        > div {
          -webkit-clip-path: url(#shape-11-clippath);
          clip-path: url(#shape-11-clippath);
        }
      }

      .triangle {
        -webkit-clip-path: url(#triangle-11-clippath);
        clip-path: url(#triangle-11-clippath);
        right: -15%;
        top: -15%;

        .county-name {
          left: 56.5%;
          top: 15%;
        }
      }
    }

    .shape-12 {
      .clipped-image {
        width: 82.5%;

        > div {
          -webkit-clip-path: url(#shape-12-clippath);
          clip-path: url(#shape-12-clippath);
        }
      }

      .triangle {
        -webkit-clip-path: url(#triangle-12-clippath);
        clip-path: url(#triangle-12-clippath);
        width: 90%;
        right: 17.5%;
        top: 18%;

        .county-name {
          left: 66.8%;
          top: 54%;
        }
      }
    }

    $enter-triangle-duration: 0.8s;
    $enter-triangle-delay: 0.1s;

    @keyframes enter-shape-1-triangle {
      0% {transform: translate(-100vw, -100vw);}
      100% {transform: translate(0, 0);}
    }

    .shape-1.enter,
    .shape-1.entered {
      .triangle {
        animation: enter-shape-1-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }

    @keyframes enter-shape-2-triangle {
      0% {transform: translate(100vw, 100vw);}
      100% {transform: translate(0, 0);}
    }

    .shape-2.enter,
    .shape-2.entered {
      .triangle {
        animation: enter-shape-2-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }

    @keyframes enter-shape-3-triangle {
      0% {transform: translate(0, 100vw);}
      100% {transform: translate(0, 0);}
    }

    .shape-3.enter,
    .shape-3.entered {
      .triangle {
        animation: enter-shape-3-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }

    @keyframes enter-shape-4-triangle {
      0% {transform: translate(-100vw, -100vw);}
      100% {transform: translate(0, 0);}
    }

    .shape-4.enter,
    .shape-4.entered {
      .triangle {
        animation: enter-shape-4-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }

    @keyframes enter-shape-5-triangle {
      0% {transform: translate(0, 50vh);}
      100% {transform: translate(0, 0);}
    }

    .shape-5.enter,
    .shape-5.entered {
      .triangle {
        animation: enter-shape-5-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }

    @keyframes enter-shape-6-triangle {
      0% {transform: translate(-100vw, 0);}
      100% {transform: translate(0, 0);}
    }

    .shape-6.enter,
    .shape-6.entered {
      .triangle {
        animation: enter-shape-6-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }

    @keyframes enter-shape-7-triangle {
      0% {transform: translate(-100vw, 100vw);}
      100% {transform: translate(0, 0);}
    }

    .shape-7.enter,
    .shape-7.entered {
      .triangle {
        animation: enter-shape-7-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }
    
    @keyframes enter-shape-8-triangle {
      0% {transform: translate(-100vw, 0);}
      100% {transform: translate(0, 0);}
    }

    .shape-8.enter,
    .shape-8.entered {
      .triangle {
        animation: enter-shape-8-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }
    
    @keyframes enter-shape-9-triangle {
      0% {transform: translate(0, 100vh);}
      100% {transform: translate(0, 0);}
    }

    .shape-9.enter,
    .shape-9.entered {
      .triangle {
        animation: enter-shape-9-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }
         
    @keyframes enter-shape-10-triangle {
      0% {transform: translate(0, 100vh);}
      100% {transform: translate(0, 0);}
    }

    .shape-10.enter,
    .shape-10.entered {
      .triangle {
        animation: enter-shape-10-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }

    @keyframes enter-shape-11-triangle {
      0% {transform: translate(-100vw, 100vw);}
      100% {transform: translate(0, 0);}
    }

    .shape-11.enter,
    .shape-11.entered {
      .triangle {
        animation: enter-shape-11-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }

    @keyframes enter-shape-12-triangle {
      0% {transform: translate(0, 100vh);}
      100% {transform: translate(0, 0);}
    }

    .shape-12.enter,
    .shape-12.entered {
      .triangle {
        animation: enter-shape-12-triangle $enter-triangle-duration $enter-triangle-delay both;
      }
    }



    .county-2 .clipped-image > div {
      background-position: 70% 50%;
    }

    .county-4 .clipped-image > div {
      background-size: 125%;
    }

    .county-7 .clipped-image > div {
      background-size: 153%;
    }

    .county-8 .clipped-image > div {
      background-position: 70% 50%;
    }

    .county-9 .clipped-image > div {
      background-size: 110%;
      background-position: 30% 95%;
    }

    .county-12 .clipped-image > div {
      background-size: 120%;
      background-position: right 20%;
    }
     
    .county-13 .clipped-image > div {
      background-size: 140%;
      background-position: right top;
    }

    .county-14 .clipped-image > div {
      background-size: 185%;
      background-position: 70% 50%;
    }

    .county-15 .clipped-image > div {
      background-position: center 40%;
    }

    .county-16 .clipped-image > div {
      background-size: 100%;
    }

    .county-19 .clipped-image > div {
      background-position: top right;
    }

    .county-20 .clipped-image > div {
      background-size: 200%;
    }

    .county-21 .clipped-image > div {
      background-position: 10% center;
    }

    .county-22 .clipped-image > div {
      background-size: 165%;
      background-position: left top;
    }

    .county-23 .clipped-image > div {
      background-size: 115%;
      background-position: 60% 20%;
    }
   
    .county-24 .clipped-image > div {
      background-position: left 60%;
    }
    
    .county-25 .clipped-image > div {
      background-size: 125%;
      background-position: left bottom;
    }

    .county-26 .clipped-image > div {
      background-position: 68% center;
    }
    
    .county-28 .clipped-image > div {
      background-size: 115%;
      background-position: right center;
    }

    .county-28 .clipped-image > div {
      background-position: left center;
    }

    .county-30 .clipped-image > div {
      background-size: 105%;
      background-position: right 60%;
    }

    .county-31 {

      .clipped-image > div {
        background-position: 5% center;
      }

      .triangle {
        right: 8%;
        top: 30%;

        .county-name {
          left: 33%;
          top: 53%;
        }
      }
    }

    .county-32 .clipped-image > div {
      background-size: 190%;
      background-position: 70% bottom;
    }
    
    .county-34 .clipped-image > div {
      background-position: center;
    }

    .county-35 .triangle {
      width: 110%;
      right: -20%;
      top: -17.5%;

      .county-name {
        left: 54.5%;
        top: 10%;
      }
    }

    .county-36 .clipped-image > div {
      background-size: 109%;
      background-position: center 70%;
    }
    
    .county-37 .clipped-image > div {
      background-size: 140%;
      background-position: right center;
    }

    .county-38 .clipped-image > div {
      background-size: 110%;
      background-position: right top;
    }

    .county-39 .clipped-image > div {
      background-size: 200%;
      background-position: 75% center;
    }
   
    .county-40 .clipped-image > div {
      background-size: 120%;
      background-position: 5% 80%;
    }

    .county-41 .clipped-image > div {
      background-position: left 84%;
    }   

    .county-42 .clipped-image > div {
      background-size: 130%;
      background-position: 86% bottom;
    }

    .county-43 .clipped-image > div {
      background-size: 120%;
      background-position: center 40%;
    }

    .county-44 .clipped-image > div {
      background-size: 160%;
      background-position: 70% top;
    }

    .county-45 .clipped-image > div {
      background-size: 105%;
      background-position: center top;
    }

    .county-46 {
      .clipped-image > div {
        background-size: 130%;
        background-position: 80% bottom;
      }

      .triangle {
        top: 25%;

        .county-name {
          top: 59%;
        }
      }
    }

    .county-47 .clipped-image > div {
      background-position: 60% 65%;
    }
    
    .county-48 .triangle {
      width: 95%;
      right: 10.5%;

      .county-name {
        left: 65%;
      }
    }

    .county-49 {

      .clipped-image > div {
        background-position: center 20%;
      }

      .triangle {
        top: 25%;

        .county-name {
          left: 60%;
          top: 55%;
        }
      }
    }

    .county-50 .clipped-image > div {
      background-size: 195%;
      background-position: 70% 50%;
      /*
      // Army Heritage
      background-size: 135%;
      background-position: 90% center;
      */
    }

    .county-54 {

      .clipped-image > div {
        background-size: 105%;
        background-position: center bottom;
        /*
        // the-pinnacle-2.jpg
        background-size: 135%;
        background-position: left bottom;
        */
      }

      .triangle {
        right: 22%;
  
        .county-name {
          left: 31%;
        }
      }
    }
    
    .county-55 {

      .clipped-image > div {
        background-size: 120%;
        background-position: left bottom;
      }

      .triangle {
        top: 27%;
  
        .county-name {
          top: 53%;
        }
      }
    }
    
    .county-56 {

      .clipped-image > div {
        background-size: 160%;
        background-position: 42% 80%;
      }

      .triangle {
        right: 20%;
  
        .county-name {
          left: 29%;
        }
      }
    }
      
    .county-57 .clipped-image > div {
      background-position: center 60%;
    }
    
    .county-58 {

      .clipped-image > div {
        background-position: right center;
      }

      .triangle {
        right: 0;
        top: 25%;

        .county-name {
          left: 68%;
          top: 59%;
        }
      }
    }

    .county-59 .clipped-image > div {
      background-size: 130%;
      background-position: center 40%;
    }

    .county-61 .triangle {
      right: 5%;
      top: 12%;

      .county-name {
        left: 68%;
        top: 61%;
      }
    }

    .county-62 .clipped-image > div {
      background-size: 165%;
      background-position: left bottom;
    }

    .county-63 .clipped-image > div {
      background-position: 10% center;
    }

    .county-64 .clipped-image > div {
      background-position: 65% center;
    }

    .county-65 {

      .clipped-image > div {
        left: -5%;
        background-position: 33% center;
      }

      .triangle {
        width: 130%;
        right: -10%;
        top: 35%;

        .county-name {
          left: 32%;
          top: 60%;
        }
      }
    }

    .county-66 {

      .clipped-image > div {
        background-position: center center;
      }

      .triangle {
        right: 23%;

        .county-name {
          left: 32%;
          top: 45%;
        }
      }
    }

    .county-67 .clipped-image > div {
      background-size: 120%;
      background-position: left center;
    }
  }


  
  .search-resources-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    perspective: 3000px;
    transform-style: preserve-3d;

    animation: enter-page-heading 0.8s both;

    .search-resources-container {
      width: 432px;
      max-width: 85vw;
      display: flex;
      flex-flow: row nowrap;
      background-color: #ca2a39;
      padding: 15px 20px;
      border-style: outset;
      border-width: 2px;
      border-radius: 50px;
      box-shadow: 5px 5px 15px rgba(0,0,0,0.9);
       

      .search-field {
        width: 80%;
        background-color: #fff;
        border: 3px solid #c6c6c6;
        border-style: inset;
        outline: none;
        padding: 10px 20px;
        border-radius: 50px;
        font-size: 1.4rem;
        color: #333;      

        @media screen and (max-width: 600px) {
          font-size: 1.1rem;
        }
      }
      
      .search-icon {
        width: 60px;
        height: 60px;
        margin: 4px 0 0 20px;
        background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 125"> \
          <path fill="%23fff" d="M71.5,12.3c-16.3-16.3-42.9-16.3-59.2,0c-16.3,16.3-16.3,42.9,0,59.2c14.6,14.6,37.3,16.2,53.6,4.7l21.8,21.8 c2.8,2.8,7.4,2.8,10.2,0s2.8-7.4,0-10.2L76.1,65.9C87.6,49.6,86.1,26.9,71.5,12.3z M62.1,62.1c-11.2,11.2-29.3,11.2-40.4,0  c-11.2-11.2-11.2-29.3,0-40.4s29.3-11.2,40.4,0C73.2,32.8,73.2,50.9,62.1,62.1z"/> \
        </svg>') no-repeat;
        filter: drop-shadow(5px 5px 10px rgba(0,0,0,0.4));
      }
    }
  }


}
